<div class="content col-md-9 col-lg-12 pt-3">
  <h2>Checkout your order</h2>

  <div class="row">
    <div class="col-md-7">
      <div class="row pt-5">
        <div class="col-md-12">
          <span class="service-title">General Information</span>
          <hr />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Order Number</span>
        </div>
        <div class="col-md-7">
          <span>{{ response.order_id }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Delivery Service</span>
        </div>
        <div class="col-md-7">
          <span>(Single / Domestic / international) Delivery</span>
          <span> by </span>
          <span>Bike / Car / Foot / Lorry</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Trip</span>
        </div>
        <div class="col-md-7">
          <span>One Way / Return Trip</span>
        </div>
      </div>
      <!-- If Delivery Service == Single Delivery, show this -->
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Delivery Type</span>
        </div>
        <div class="col-md-7">
          <span>(Instant / Same Day) Delivery</span>
        </div>
      </div>
      <!-- If Delivery Service == Domestic Delivery, show this -->
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Delivery Option</span>
        </div>
        <div class="col-md-7">
          <span>(Courier / Cargo Services) / (Air Freight (To East Malaysia))</span>
        </div>
      </div>

      <div class="row pt-5">
        <div class="col-md-7">
          <span class="service-title">Pick Up Information</span>
          <hr />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Booking Schedule Date</span>
        </div>
        <div class="col-md-7">
          <span>{{ response.pickup_date }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Sender Name</span>
        </div>
        <div class="col-md-7">
          <span>{{ response.pickup_contact_name }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Sender Mobile Number</span>
        </div>
        <div class="col-md-7">
          <span>{{ response.pickup_contact_number }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Pick Up Point</span>
        </div>
        <div class="col-md-7">
          <span>{{ response.pickup_address }}</span>
        </div>
      </div>

      <div class="row pt-5">
        <div class="col-md-7">
          <span class="service-title">Delivery Information</span>
          <hr />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Recipient Name</span>
        </div>
        <div class="col-md-7">
          <span>{{ response.consignee_name }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Recipient Mobile Number</span>
        </div>
        <div class="col-md-7">
          <span>{{ response.consignee_number }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Delivery Point</span>
        </div>
        <div class="col-md-7">
          <span>{{ response.consignee_address }}</span>
        </div>
      </div>

      <div class="row pt-5">
        <div class="col-md-7">
          <span class="service-title">Stock Information</span>
          <hr />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Total Weight (KG)</span>
        </div>
        <div class="col-md-7">
          <span>{{ response.weight_recon_records[0].weight }} KG</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Parcel Type</span>
        </div>
        <div class="col-md-7">
          <span>Parcel</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-5">
          <span class="font-weight-bold">Instruction Notes</span>
        </div>
        <div class="col-md-7">
          <span>Handle with care!</span>
        </div>
      </div>

      <div class="row pt-5">
        <div class="col-md-12">
          <span class="service-title">Payment</span>
          <hr />
        </div>
      </div>
      <form class="" action="index.html" method="post">
        <div class="row mb-2">
          <div class="col-md-5">
            <span class="font-weight-bold">Payment method</span>
          </div>
          <div class="col-md-7">
            <div class="">
              <label for="">
                <input type="radio" name="" value=""> Credit Card
              </label>
            </div>
            <div class="">
              <label for="">
                <input type="radio" name="" value=""> Debit Card
              </label>
            </div>
            <div class="">
              <label for="">
                <input type="radio" name="" value=""> FPX
              </label>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-12">
            <input type="text" class="form-control" name="" value="" placeholder="Name on Card" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <input type="text" class="form-control" name="" value="" placeholder="Card Number" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <input type="text" class="form-control" name="" value="" placeholder="Expiration Date" />
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" name="" value="" placeholder="CVV" />
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <button type="button" class="btn btn-primary btn-block" name="button">Checkout</button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-5 calculator-box">
      <div class="row">
        <div class="col-md-8 order-md-2 mb-3 pt-5">
              <h4 class="d-flex justify-content-between align-items-center mb-3">
                  <span class="text-muted">Your cart</span>
                  <span class="badge badge-secondary badge-pill">3</span>
              </h4>
              <ul class="list-group sticky-top">
                  <li class="list-group-item d-flex justify-content-between lh-condensed">
                      <div>
                          <h6 class="my-0">{{ response.items[0].item_desc }}</h6>
                          <small class="text-muted">{{ response.items[0].item_category }}</small>
                      </div>
                      <span class="text-muted">{{ response.items[0].item_price_currency }} {{ response.items[0].item_price_value }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between lh-condensed">
                      <div>
                          <h6 class="my-0">Second product</h6>
                          <small class="text-muted">Brief description</small>
                      </div>
                      <span class="text-muted">$8</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between lh-condensed">
                      <div>
                          <h6 class="my-0">Third item</h6>
                          <small class="text-muted">Brief description</small>
                      </div>
                      <span class="text-muted">$5</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between bg-light">
                      <div class="text-success">
                          <h6 class="my-0">Promo code</h6>
                          <small>EXAMPLECODE</small>
                      </div>
                      <span class="text-success">-$5</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between">
                      <span>Total (USD)</span>
                      <strong>$20</strong>
                  </li>
              </ul>
          </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <form class="card p-2">
              <div class="input-group">
                  <input type="text" class="form-control" placeholder="Promo code">
                  <div class="input-group-append">
                      <button type="submit" class="btn btn-secondary">Redeem</button>
                  </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>


</div>
