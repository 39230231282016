<div class="modal fade" id="prohibitedItemsModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header justify-content-start" style="background-color: #0089CF;color: white;">
        <button type="button" class="btn btn-default" style="color: white;" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Domestic - Prohibited Items</h4>
      </div>
      <div class="modal-body" style=" padding-left:25px; padding-right:25px; padding-bottom:20px;">
        <div style="padding:15px; background-color:#e7d99a; margin-bottom:20px;">At Arriival , the following items (or
          any item similar to the description or content) are strictly prohibited by any of the courier company. Any
          shipment containing such items may be subjected to their order being cancelled without notice. Items that fall
          under these list are prohibited. Arriival and courier companies will not be responsible for any loss or damage
          of the prohibited item(s). No compensation for loss or damage will be given. If you have any questions or
          enquiries about this matter, feel free to contact us.
        </div>
        <div class="row">
          <div class="col-md-6">
            <ul>
              <li class="emptybottom"> Aerosol</li>
              <li class="emptybottom"> Alcohol</li>
              <li class="emptybottom"> Animals of any form (alive or dead)</li>
              <li class="emptybottom"> Animal antiquities</li>
              <li class="emptybottom"> Articles of unusual value</li>
              <li class="emptybottom"> Batteries / lithium-ion batteries</li>
              <li class="emptybottom"> Biological samples</li>
              <li class="emptybottom"> Currency / money / cheque / coin / cash / gold / silver bullions</li>
              <li class="emptybottom"> Chainsaw</li>
              <li class="emptybottom"> Counterfeit / pirated goods and materials</li>
              <li class="emptybottom"> Cyanides or precipitates</li>
              <li class="emptybottom"> Driving licence / birth cert / identification card / passport / debit card /
                credit card</li>
              <li class="emptybottom"> Drugs (including prescription) / narcotics / tobacco</li>
              <li class="emptybottom"> Dry ice</li>
              <li class="emptybottom"> Engines</li>
              <li class="emptybottom"> Electronic appliances</li>
              <li class="emptybottom"> Electronic devices / products</li>
              <li class="emptybottom"> Firecrackers</li>
              <li class="emptybottom"> Fire extinguisher</li>
              <li class="emptybottom">Food (except Transprompt Freight)</li>
              <li class="emptybottom">Furs</li>
            </ul>
          </div>
          <div class="col-md-6">
            <ul>
              <li class="emptybottom">Hazardous goods / toxic powder</li>
              <li class="emptybottom">Jewellery</li>
              <li class="emptybottom">Knives</li>
              <li class="emptybottom">Lighter</li>
              <li class="emptybottom">Liquid</li>
              <li class="emptybottom">Lottery tickets</li>
              <li class="emptybottom">Milk powder</li>
              <li class="emptybottom">Mobile phone / telephone SIM & prepaid cards</li>
              <li class="emptybottom">Oil</li>
              <li class="emptybottom">Paint</li>
              <li class="emptybottom">Perishable goods</li>
              <li class="emptybottom">Powerbank</li>
              <li class="emptybottom">Plants (Except for Zepto, ZoomIT and GoLog Flower Delivery) / seeds</li>
              <li class="emptybottom">Seat belt tensioner</li>
              <li class="emptybottom">Sexual explicit content</li>
              <li class="emptybottom">Sinks</li>
              <li class="emptybottom">Stamps (unless franked)</li>
              <li class="emptybottom">Steering wheel</li>
              <li class="emptybottom">Toilet fittings</li>
              <li class="emptybottom">Laptop</li>
              <li class="emptybottom">Waste</li>
              <li class="emptybottom">War savings</li>
              <li class="emptybottom">Weapons (real or fake)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="favouriteModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Create New Favourite</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <form class="" action="index.html" method="post">
          <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Name</label>
            </div>
            <div class="col-md-9">
              <input type="text" id="name" #name class="form-control" name="address_name"
                [(ngModel)]="address_name" />
            </div>
          </div>

          <div class="form-group">
            <label>Enter address</label>
            <input type="text" class="form-control" name="displayAddress" [(ngModel)]="displayAddress"
              (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off"
              autocapitalize="off" spellcheck="off" type="text" #search>
          </div>

          <div class="form-group row">
            <div class="col">
              <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                  (dragEnd)="markerDragEnd($event, 'add')"></agm-marker>
              </agm-map>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Mobile</label>
            </div>
            <div class="col-md-9">
              <input type="text" id="mobile" #mobile class="form-control" name="address_mobile"
                [(ngModel)]="address_mobile" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Area Code</label>
            </div>
            <div class="col-md-9">
              <input type="text" id="areaCode" #areaCode class="form-control" name="address_area_code"
                [(ngModel)]="address_area_code" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Address Door</label>
            </div>
            <div class="col-md-9">
              <input type="text" id="addressDoor" #addressDoor class="form-control" name="address_door"
                [(ngModel)]="address_door" />
            </div>
          </div>
        </form>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addNewAddress()" data-dismiss="modal">Add</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="noCompensationItemsModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header justify-content-start" style="background-color: #0089CF;color: white;">
        <button type="button" class="btn btn-default" style="color: white;" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Domestic - No Compensation Items</h4>
      </div>
      <div class="modal-body">
        <div style="padding:15px; background-color:#e7d99a; margin-bottom:20px;">The following items (or any item
          similar in description or content) can only be carried on a no compensation basis on any service. Any person
          sending such an item does so at their own risk:</div>
        <div class="row">
          <div class="col-md-4">
            <ul>
              <li class="emptybottom">Amber</li>
              <li class="emptybottom">Antique</li>
              <li class="emptybottom">Beswick Figure</li>
              <li class="emptybottom">Bottles</li>
              <li class="emptybottom">Bulbs</li>
              <li class="emptybottom">Camera</li>
              <li class="emptybottom">Canvas Print</li>
              <li class="emptybottom">Car Panel</li>
              <li class="emptybottom">Ceramics</li>
              <li class="emptybottom">Chandelier</li>
              <li class="emptybottom">Chocolate</li>
              <li class="emptybottom">Clock</li>
              <li class="emptybottom">Computer Monitor</li>
              <li class="emptybottom">Concrete</li>
              <li class="emptybottom">Confectionary</li>
              <li class="emptybottom">Crockery</li>
              <li class="emptybottom">Crystal</li>
              <li class="emptybottom">Delicate Items</li>
              <li class="emptybottom">Detergent</li>
              <li class="emptybottom">Diamonds</li>
              <li class="emptybottom">Documents</li>
              <li class="emptybottom">Fiberglass</li>
              <li class="emptybottom">Figurine</li>
              <li class="emptybottom">Fish Tank</li>
              <li class="emptybottom">Fishing Rods</li>
              <li class="emptybottom">Fragile Items (All)</li>
              <li class="emptybottom">Framed Painting</li>
            </ul>
          </div>
          <div class="col-md-4">
            <ul>
              <li class="emptybottom">Framed Photograph</li>
              <li class="emptybottom">Gems Or Gemstones</li>
              <li class="emptybottom">Glass</li>
              <li class="emptybottom">Glassware</li>
              <li class="emptybottom">Gold</li>
              <li class="emptybottom">Hamper</li>
              <li class="emptybottom">Headlight</li>
              <li class="emptybottom">ewellery</li>
              <li class="emptybottom">Lamp</li>
              <li class="emptybottom">Lampshades</li>
              <li class="emptybottom">Lenses</li>
              <li class="emptybottom">Lighting</li>
              <li class="emptybottom">Marble</li>
              <li class="emptybottom">Microscope</li>
              <li class="emptybottom">Microwave</li>
              <li class="emptybottom">Mirror</li>
              <li class="emptybottom">Mobile Phone (Any Kind)</li>
              <li class="emptybottom">Monitor (Including Imacs)</li>
              <li class="emptybottom">Ornament</li>
              <li class="emptybottom">Over Head Projector</li>
              <li class="emptybottom">Painting</li>
              <li class="emptybottom">Pewter Figures</li>
              <li class="emptybottom">Picture</li>
              <li class="emptybottom">Picture Frames</li>
              <li class="emptybottom">Plates</li>
              <li class="emptybottom">Porcelain</li>
            </ul>
          </div>
          <div class="col-md-4">
            <ul>
              <li class="emptybottom">Poster</li>
              <li class="emptybottom">Pottery</li>
              <li class="emptybottom">Precious Metals</li>
              <li class="emptybottom">Projector</li>
              <li class="emptybottom">Real Fur</li>
              <li class="emptybottom">Resin</li>
              <li class="emptybottom">Scanner</li>
              <li class="emptybottom">Sewing/Knitting Machine</li>
              <li class="emptybottom">Shower Screen</li>
              <li class="emptybottom">Silver</li>
              <li class="emptybottom">SIM Cards</li>
              <li class="emptybottom">Spectacles</li>
              <li class="emptybottom">Stoneware</li>
              <li class="emptybottom">Supplement</li>
              <li class="emptybottom">Tail Lights</li>
              <li class="emptybottom">Telescope</li>
              <li class="emptybottom">Television</li>
              <li class="emptybottom">Tickets</li>
              <li class="emptybottom">Tiles</li>
              <li class="emptybottom">Torches</li>
              <li class="emptybottom">Velux</li>
              <li class="emptybottom">Vermiculite Board</li>
              <li class="emptybottom">Watch</li>
              <li class="emptybottom">Window</li>
              <li class="emptybottom">Window Frame</li>
              <li class="emptybottom">Any Form Of Magnet</li>
              <li class="emptybottom">Cosmetic & beauty products</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="myFavouriteModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Favourite Address</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <button type="button" data-toggle="modal" data-target="#favouriteModal" (click)="clearModal()"
          class="btn btn-sm btn-success" name="button"><i class="fa fa-plus"></i> Create New</button>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Address</th>
              <th scope="col">Area Code</th>
              <th scope="col">Phone</th>
              <th scope="col">Latitude</th>
              <th scope="col">Longtitude</th>
            </tr>
          </thead>
          <tbody *ngFor="let addr of my_address; let i = index;">
            <tr>
              <th scope="row"><input id="{{ addr.addressName }}" type="radio" value="" name="rdAddress"
                  (change)="changeAddress(addr)"></th>
              <td>{{ addr.addressName }}</td>
              <td>{{ addr.addressAddress }}</td>
              <td>{{ addr.addressAreaCode }}</td>
              <td>{{ addr.addressPhone }}</td>
              <td>{{ addr.addressLat }}</td>
              <td>{{ addr.addressLng }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="chooseAddress()">Choose</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="content col-md-9 col-lg-12 pt-3">
  <form class="mb-5 pb-5" action="index.html" method="post">
    <div class="row">
      <div class="col-md-8">
        <!-- Current Page Title -->
        <div class="row">
          <div class="col-md-12">
            <h2>Domestic Delivery</h2>
          </div>
        </div>

        <!-- Current Page Sub-title -->
        <div class="row">
          <div class="col-md-12">
            <p>Enter your order details and let us know if you have any special arrangements needed.</p>
          </div>
        </div>

        <!-- Step 1 -->
        <div class="row">
          <div class="col-md-12">
            <span class="mr-3 step-span">Step 1</span>
            <span class="text-muted">Select your delivery Option</span>
          </div>
        </div>
        <hr />

        <!-- Service Selector Button -->
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="btn-group w-100 d-flex vehicle-flex">
              <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateCourier}"
                (click)="activateCourierButton()" name="button">
                <div class="d-flex h-100 flex-row justify-content-center align-items-center">
                  <img src="assets/icons/icon-domestic-courier-blue.png" *ngIf="activateCourier == false" width="50"
                    alt="" />
                  <img src="assets/icons/icon-domestic-courier-white.png" *ngIf="activateCourier == true" width="50"
                    alt="" />
                  <span class="card-title">Courier Service</span>
                </div>
              </button>
              <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateCargo}"
                (click)="activateCargoButton()" name="button">
                <div class="d-flex h-100 flex-row justify-content-center align-items-center">
                  <img src="assets/icons/icon-domestic-cargo-blue.png" *ngIf="activateCargo == false" width="50"
                    alt="" />
                  <img src="assets/icons/icon-domestic-cargo-white.png" *ngIf="activateCargo == true" width="50"
                    alt="" />
                  <span class="card-title">Cargo Service</span>
                </div>
              </button>
              <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateAirFreight}"
                (click)="activateAirFreightButton()" name="button">
                <div class="d-flex h-100 flex-row justify-content-center align-items-center">
                  <img src="assets/icons/new/icons-air-malaysial-blue2.png" *ngIf="activateAirFreight == false" width="50"
                    alt="" />
                  <img src="assets/icons/new/icons-air-malaysial-white2.png" *ngIf="activateAirFreight == true" width="50"
                    alt="" />
                  <div class="d-flex flex-column">
                    <span class="card-title">Air / Sea Freight</span>
                    <span class="card-small-label">(East M'sia <i class="fas fa-arrows-alt-h"></i> West M'sia)</span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="arrow-up"
              [ngClass]="{'ml-15-percent': activateCourier, 'ml-50-percent': activateCargo, 'ml-82-percent': activateAirFreight}">
            </div>
            <div class="alert text-light bg-green">
              <p *ngIf="activateCourier">Delivery Document & Light Parcel/Goods to West Malaysia during 2-4 Working day.
              </p>
              <p *ngIf="activateCargo">Heavy Goods service,it freight by Van & Lorry.Coverage West Malaysia and delivery
                during 2-5 Working day.</p>
              <p *ngIf="activateAirFreight">East Malaysia Delivery. Coverage Sabah &amp; Sarawak. 5-7 Working day
                delivery
                to east Malaysia.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <p>
              <span class="mr-3 step-span">Step 2</span>
              <span class="text-muted">Tell us where to go</span>
            </p>
          </div>
          <div class="col-md-7 text-right pr-3">
            <button type="button" class="btn btn-custom-hover btn-info" name="button" (click)="submitBulkOrder()">Submit Bulk Orders</button>
          </div>
        </div>
        <hr />

        <div *ngIf="!activateAirFreight">
          <!-- Pick Up Point -->
          <i class="fas fa-dot-circle point-circle pick-up"></i>
          <div class="delivery-form-decoration">
            <div class="row">
              <div class="col-md-5">
                <h3>Pick Up Point</h3>
              </div>
              <div class="col-md-7">
                <div class="form-group row">
                  <!-- style="display: none;" -->
                  <div class="col-md-12">
                    <div class="btn-group w-100" role="group">
                      <!-- Show only Air Freight Selected -->
                      <!-- <button *ngIf="activateAirFreight" type="button" class="btn btn-outline-primary"
                        [ngClass]="{'active' : westToEast}" (click)="toggleWestToEastButton()" name="button">West to East</button>
                      <button *ngIf="activateAirFreight" type="button" class="btn btn-outline-primary"
                        [ngClass]="{'active' : !westToEast}" (click)="toggleWestToEastButton()" name="button">East to
                        West</button> -->

                      <!-- Show Only Cargo Service Selected -->
                      <button *ngIf="activateCargo" type="button" class="btn btn-outline-primary"
                        [ngClass]="{'active' : doorToDoor}" (click)="toggleDoorToDoorButton()" name="button">Door to
                        Door Pick
                        Up</button>
                      <button *ngIf="activateCargo" type="button" class="btn btn-outline-primary"
                        [ngClass]="{'active' : !doorToDoor}" (click)="toggleDoorToDoorButton()" name="button">Drop Off
                        at Nearest
                        SP</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-5">
                <label class="label-control" for="">Pick up address <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-7 input-group">
                <input type="text" class="form-control" name="sendAddress" [(ngModel)]="sendAddress" value=""
                  placeholder="Address Details" />
                <div class="input-group-append">
                  <button data-toggle="modal" data-target="#myFavouriteModal"
                    class="btn btn-outline-secondary form-control-button" type="button"
                    (click)="setAddress('pick_up', '')">
                    <i class="fa fa-heart text-danger"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-5">
                <label class="label-control" for="">Postcode <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control" name="sendPostcode" [(ngModel)]="sendPostcode" value="" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-5">
                <label class="label-control" for="">Sender Mobile Number <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-7">
                <!-- <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                  </div>
                  <input type="text" class="form-control" name="sendPhone" [(ngModel)]="sendPhone" value="" />
                </div> -->
                <div class="input-prefix">
    							<span class="prefix text-muted">601</span>
                    <input type="text" class="form-control input-field mb-3" name="" value="" [(ngModel)]="sendPhone" />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-5">
                <label class="label-control" for="">Sender Name</label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control" name="sendName" [(ngModel)]="sendName" value="" />
              </div>
            </div>
          </div>
          <!-- End of Pick Up Point -->
        </div>

        <!-- Air-Freight Section -->
        <div *ngIf="activateAirFreight">
          <div class="row">
            <div class="col-md-12 pr-3">
              <!-- Pick Up Point -->
              <i class="fas fa-dot-circle point-circle pick-up"></i>
              <div class="delivery-form-decoration">
                <div class="row">
                  <div class="col-md-5">
                    <h3>Pick Up Point</h3>
                  </div>
                  <div class="col-md-7 mb-3">
                    <div class="btn-group w-100" role="group">
                      <button *ngIf="activateAirFreight" type="button" class="btn btn-outline-primary"
                        [ngClass]="{'active' : airOrSea}" (click)="toggleAirOrSeaButton()" name="button">
                        <img *ngIf="airOrSea" src="/assets/icons/new/icons-air-white.png" width="25" />
                        <img *ngIf="!airOrSea" src="/assets/icons/new/icons-air-blue.png" width="25" />
                        Air Freight</button>
                      <button *ngIf="activateAirFreight" type="button" class="btn btn-outline-primary"
                        [ngClass]="{'active' : !airOrSea}" (click)="toggleAirOrSeaButton()" name="button">
                        <img *ngIf="!airOrSea" src="/assets/icons/new/icons-ship-white.png" width="25" />
                        <img *ngIf="airOrSea" src="/assets/icons/new/icons-ship-blue.png" width="25" />
                        Sea Freight</button>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Pick up state <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 input-group">
                    <select class="form-control" name="pickupState">
                      <option value="">Kuala Lumpur</option>
                      <option value="">Johor</option>
                      <option value="">Selangor</option>
                      <option value="">Sabah</option>
                      <option value="">Sarawak</option>
                      <option value="">Kedah</option>
                      <option value="">Kelantan</option>
                      <option value="">Pahang</option>
                      <option value="">Negeri Sembilan</option>
                      <option value="">Perak</option>
                      <option value="">Perlis</option>
                      <option value="">Terrenganu</option>
                      <option value="">Melaka</option>
                      <option value="">Penang</option>
                      <!-- <option *ngFor="let state of states">{{ state.name }}</option> -->
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Pick up address <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 input-group">
                    <input type="text" class="form-control" name="pickupAddress" [(ngModel)]="sendAddress" value=""
                      placeholder="Street / Postcode / Landmark" />
                    <div class="input-group-append">
                      <button data-toggle="modal" data-target="#myFavouriteModal"
                        class="btn btn-outline-secondary form-control-button" type="button"
                        (click)="setAddress('pick_up', '')">
                        <i class="fa fa-heart text-danger"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Postcode <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7">
                    <input type="text" class="form-control" name="pickupPostal" [(ngModel)]="sendPostcode"
                      placeholder="Postal" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Sender Mobile Number <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7">
                    <!-- <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                      </div>
                      <input type="text" class="form-control" name="sendPhone" [(ngModel)]="sendPhone" placeholder="Sender Mobile Number" />
                    </div> -->
                    <div class="input-prefix">
        							<span class="prefix text-muted">601</span>
                        <input type="text" class="form-control input-field mb-3" name="" value="" [(ngModel)]="sendPhone" />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Sender Name</label>
                  </div>
                  <div class="col-md-7">
                    <input type="text" class="form-control" name="sendName" [(ngModel)]="sendName"
                      placeholder="Sender Name" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 pr-4">
              <!-- Delivery Point -->
              <i class="fas fa-dot-circle point-circle deliver"></i>
              <div class="delivery-form-decoration">
                <h3>Delivery Point</h3>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Delivery state <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 input-group">
                    <select class="form-control" name="consigneeState">
                      <option value="">Kuala Lumpur</option>
                      <option value="">Johor</option>
                      <option value="">Selangor</option>
                      <option value="">Sabah</option>
                      <option value="">Sarawak</option>
                      <option value="">Kedah</option>
                      <option value="">Kelantan</option>
                      <option value="">Pahang</option>
                      <option value="">Negeri Sembilan</option>
                      <option value="">Perak</option>
                      <option value="">Perlis</option>
                      <option value="">Terrenganu</option>
                      <option value="">Melaka</option>
                      <option value="">Penang</option>
                      <!-- <option *ngFor="let destinationState of destinationStates">{{ destinationState.name }}</option> -->
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Delivery address <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 input-group">
                    <input type="text" class="form-control" name="consigneeAddress" value=""
                      placeholder="Street / Postcode / Landmark" />
                    <div class="input-group-append">
                      <button data-toggle="modal" data-target="#myFavouriteModal"
                        class="btn btn-outline-secondary form-control-button" type="button"
                        (click)="setAddress('delivery', '')">
                        <i class="fa fa-heart text-danger"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Postcode <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7">
                    <input type="text" class="form-control" name="consigneePostal" value="" placeholder="Postcode" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Recipient Mobile Number <span
                        class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7">
                      <!-- <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                        </div>
                        <input type="text" class="form-control" name="consigneeNumber" placeholder="Recipient Mobile Number" />
                      </div> -->
                      <div class="input-prefix">
          							<span class="prefix text-muted">601</span>
                          <input type="text" class="form-control input-field mb-3" name="consigneeNumber" value="" />
                      </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5">
                    <label class="label-control" for="">Recipient Name</label>
                  </div>
                  <div class="col-md-7">
                    <input type="text" class="form-control" name="consigneeName" value=""
                      placeholder="Recipient Name" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <!-- Stock Information -->
              <div class="delivery-stock-decoration">
                <div class="form-group row pr-2">
                  <div class="col-md-5">
                    <label for="">Package Details <span class="text-danger">*</span></label>
                    <img src="/assets/img/box.jpg" width="200" alt="" />
                  </div>
                  <div class="col-md-7">
                    <div class="row">
                      <div class="col-md-6">
                        <label for="">Length</label>
                        <input type="text" class="form-control" name="orderLength" placeholder="cm" />
                      </div>
                      <div class="col-md-6">
                        <label for="">Width</label>
                        <input type="text" class="form-control" name="orderWidth" placeholder="cm" />
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <label for="">Height</label>
                        <input type="text" class="form-control" name="orderHeight" placeholder="cm" />
                      </div>
                      <div class="col-md-6">
                        <label for="">Weight</label>
                        <input type="text" class="form-control" name="orderWeight" placeholder="kg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-5">
                  <label class="label-control" for="">Parcel Type <span class="text-danger">*</span></label>
                </div>
                <div class="col-md-7">
                  <div class="row">
                    <div class="col-md-6">
                      <select class="form-control" name="itemCategory">
                        <option value="Electronics">Electronics</option>
                        <option value="Fashion Apparel">Fashion &amp; Apparel</option>
                        <option value="Lifestyle Products">Lifestyle &amp; Products</option>
                        <option value="" selected>Other..</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <input type="text" class="form-control" name="itemCategory"
                        placeholder="Specific your category" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-5">
                  <label class="label-control" for="">Item Price Value (RM) <span class="text-danger">*</span></label>
                </div>
                <div [ngClass]="{'col-md-3': !airOrSea, 'col-md-7': airOrSea}">
                  <input type="text" class="form-control" name="itemPriceValue" placeholder="Price" />
                </div>
                <div class="col-md-4" *ngIf="!airOrSea">
                  <div>
                    <label for="">
                      <input type="checkbox" name="" value="" />Buy Additional Insurance Coverage
                    </label>
                  </div>
                  <div>
                    <span class="text-muted font-italic">Note: RM200 Standard Insurance coverage lost item.</span>
                    <span class="text-muted font-italic">Your parcels still can purchase additional insurance for
                      maximum protection.</span>
                  </div>
                  <div>

                  </div>
                </div>
              </div>
              <!-- <div class="form-group row">
                <div class="col-md-5">
                  <label class="label-control" for="">Description</label>
                </div>
                <div class="col-md-7">
                  <textarea name="itemDesc" class="form-control" rows="8" cols="80"></textarea>
                </div>
              </div> -->
            </div>

            <!-- Air-Freight add Item Section -->
            <!-- <div *ngIf="fieldArray.length > 0">
              <div *ngFor="let item of fieldArray; index as i">
                <div class="col-md-12">
                  <hr />
                </div>

                <div class="row">
                  <div class="col-md-8">
                    <div id="divItemArray{{i}}">
                      <div class="delivery-stock-decoration">
                        <div class="form-group row">
                          <div class="col-md-4">
                            <label class="label-control" for="">Parcel Type</label>
                          </div>
                          <div class="col-md-6">
                            <select class="form-control" name="{{item.category}}" [(ngModel)]="item.category">
                              <option value="Electronics">Electronics</option>
                              <option value="Fashion Apparel">Fashion &amp; Apparel</option>
                              <option value="Lifestyle Products">Lifestyle &amp; Products</option>
                              <option value="" selected>Other..</option>
                            </select>
                          </div>
                          <div class="offset-md-4 col-md-6">
                            <input type="text" class="form-control" name="{{item.category}}" [(ngModel)]="item.category" placeholder="Specific your category" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-md-4">
                            <label class="label-control" for="">Price</label>
                          </div>
                          <div class="col-md-6">
                            <input type="text" class="form-control" name="{{item.priceValue}}" [(ngModel)]="item.priceValue" placeholder="Price" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-md-4">
                            <label class="label-control" for="">Description</label>
                          </div>
                          <div class="col-md-6">
                            <textarea name="{{item.desc}}" class="form-control" rows="8" cols="80" [(ngModel)]="item.desc"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-right">
                    <button type="button" class="btn btn-sm btn-default text-danger" name="button" (click)="removeItem(i)">
                      <i class="fa fa-times"></i> Remove Item
                    </button>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="col-md-12">
              <hr />
            </div>

            <div class="col-md-6">
              <button type="button" class="btn btn-sm btn-primary" name="button">
                <i class="fa fa-plus"></i> Add Item
              </button>
            </div>
            <div class="col-md-6 text-right">
              <button type="button" class="btn btn-sm btn-default text-danger" name="button">
                <i class="fa fa-times"></i> Remove Item
              </button>
            </div>
          </div>
          <span class="d-block text-danger mb-3">(*) indicate Mandatory field</span>
        </div>
        <!-- </form> -->
      </div>
      <div class="col-md-4 position-relative">
        <div class="h-100">
          <div class="cart-outer-box p-3"
            style="border: 1px solid var(--primary-color);border-radius: 5px;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);z-index: 1;background-color: white;position: sticky;top: 75px;">
            <div class="cart-inner-box">
              <div class="cart-header mb-3">
                <span class="text-uppercase font-weight-bold text-primary" style="font-size: 20px;">your order:</span>
              </div>
              <div class="cart-body">
                <div class="cart-body-header d-flex justify-content-between align-items-end">
                  <span style="font-size: 16px;">Price Details</span>
                  <i *ngIf="!isCollapsed" style="font-size: 16px;" (click)="isCollapsed = true"
                    class="fa fa-times text-danger"></i>
                  <i *ngIf="isCollapsed" style="font-size: 16px;" (click)="isCollapsed = false" class="fas fa-bars"></i>
                </div>
                <div *ngIf="isCollapsed" class="row py-2">
                  <div class="col-md-8">
                    <!-- <span>{{ cartItems[0].items.length }} Parcels Delivery Charges</span> -->
                    <span>Parcels Delivery Charges</span>
                  </div>
                  <div class="col-md-4 text-right">
                    <!-- <span>RM {{ cartItems[0].total }}</span> -->
                    <span>RM {{ totalPriceToPay }}</span>
                  </div>
                </div>
                <div *ngIf="!isCollapsed" style="font-size: 12px;">
                  <!-- [ngClass]="{'collapse-animate': isCollapsed, 'cart-body-content': !isCollapsed}" -->
                  <!-- <div class="row py-2" *ngFor="let item of cartItems[0].items"> -->
                  <div class="row py-2" *ngFor="let item of cartDetails">
                    <div class="col-md-4">
                      <!-- <span>{{ item.qty }} x {{ item.name }}</span> -->
                      <span>1 x parcel</span>
                    </div>
                    <div class="col-md-4">
                      <!-- <span>(RM{{ item.price }} x {{ item.qty }})</span> -->
                      <span>(RM{{ item }} x 1)</span>
                    </div>
                    <div class="col-md-4 text-right">
                      <!-- <span>RM {{ item.sub_total }}</span> -->
                      <span>RM {{ item }}</span>
                    </div>
                  </div>
                </div>
                <div class="cart-body-footer mb-3">
                  <div class="row mx-0">
                    <div class="col-md-12 text-right" style="border-top: 1px solid black;border-bottom: double black;">
                      <!-- <span class="font-weight-bold" style="font-size: 16px;">Total: </span><span class="font-weight-bold" style="font-size: 20px;">RM {{ cartItems[0].total }}</span> -->
                      <span class="font-weight-bold" style="font-size: 16px;">Total: </span><span
                        class="font-weight-bold" style="font-size: 20px;">RM {{ totalPriceToPay }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-right">
                      <span style="font-weight: 600;" class="mr-2 text-primary">Corporate Price RM{{ totalPriceToPay }}</span><i
                        class="far fa-question-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-footer">
                <div class="mb-2">
                  <span *ngIf="!activateCouponField">Click <a (click)="showCouponField()"
                      href="javascript:void(0);">here</a> to enter discount coupon</span>
                  <div class="d-flex flex-row nowrap" style="align-items:flex-center;align-content:flex-center;"
                    *ngIf="activateCouponField">
                    <div class="pr-2" style="height: 28px;">
                      <label style="font-size: 14px;" for="">Coupon code</label>
                    </div>
                    <input class="mr-2" type="text" name="" value="" style="width: 40%;height: 28px;" />
                    <button type="button" class="btn btn-sm btn-secondary mr-2" name="button"
                      style="height: 28px;padding-top: 0;">Apply</button>
                    <div (click)="hideCouponField()" style="height: 28px;"><i class="fa fa-times text-danger"></i></div>
                  </div>
                </div>
                <div class="mb-2">
                  <button type="button" class="btn btn-primary btn-block" name="button" (click)="submitOrder()">
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="flex-fill">Buy Credit & Proceed with RM{{ totalPriceToPay }}</span>
                      <i class="fa fa-long-arrow-right"></i>
                    </div>
                  </button>
                </div>
                <div>
                  <button type="button" class="btn btn-secondary btn-block" name="button" (click)="submitOrder()">
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="flex-fill">Proceed Instant pay as RM{{ totalPriceToPay }}</span>
                      <i class="fa fa-long-arrow-right"></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="!activateAirFreight">
      <!-- Delivery Point -->
      <div class="row">
        <div class="col-md-12">
          <i class="fas fa-dot-circle point-circle deliver"></i>
          <div class="delivery-form-decoration">
            <h3>Delivery Point
              <small *ngIf="activateAirFreight"> (Within Malaysia)</small>
              <small *ngIf="!activateAirFreight" class="text-muted"> (Within Peninsular Malaysia)</small>
            </h3>
            <div class="">
              <!-- table-responsive -->
              <table class="table table-sm" style="width: 100%;table-layout: fixed;">
                <thead>
                  <tr>
                    <!-- <th class="nowrap" style="min-width: 300px;">Delivery Address</th>
                  <th class="nowrap" style="min-width: 100px;">Postcode</th>
                  <th class="nowrap" style="min-width: 200px;">Recipient Name</th>
                  <th class="nowrap" style="min-width: 200px;">Mobile No.</th>
                  <th class="nowrap" style="min-width: 250px;">Parcel Size(cm)</th>
                  <th class="nowrap" style="min-width: 100px;" *ngIf="activateCargo">Qty Cartons</th>
                  <th class="nowrap" style="min-width: 50px;">Weight(KG)/item(cm)</th>
                  <th class="nowrap" style="min-width: 150px;">Price</th>
                  <th class="nowrap" class="nowrap"></th> -->

                    <th style="width: 19%;">Delivery Address<span class="text-danger">*</span></th>
                    <th style="width: 8%;">Postcode<span class="text-danger">*</span></th>
                    <th style="width: 11%;">Recipient Name</th>
                    <th style="width: 13%;">Mobile No.<span class="text-danger">*</span></th>
                    <th style="width: 20.75%;">Parcel Size(cm)<span class="text-danger">*</span></th>
                    <th style="width: 8.75%;" *ngIf="activateCargo">Qty Cartons<span class="text-danger">*</span></th>
                    <th style="width: 8.75%;">Weight(KG)/ item(cm)<span class="text-danger">*</span></th>
                    <th style="width: 6.75%;">Price<span class="text-danger">*</span></th>
                    <th style="width: 4%;" class="nowrap"></th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of items; let i = index">
                  <tr>
                    <td>
                      <div class="input-group">
                        <input type="text" class="form-control" name="field1{{i}}" [(ngModel)]="item.field1"
                          placeholder="Address Details" />
                        <div class="input-group-append">
                          <button data-toggle="modal" data-target="#myFavouriteModal"
                            class="btn btn-outline-secondary form-control-button" type="button"
                            (click)="setAddress('delivery', i)">
                            <i class="fa fa-heart text-danger"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input type="text" class="form-control" name="field2{{i}}" [(ngModel)]="item.field2" />
                    </td>
                    <td>
                      <input type="text" class="form-control" name="field3{{i}}" [(ngModel)]="item.field3" />
                    </td>
                    <td>
                      <!-- <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                        </div>
                        <input type="text" class="form-control" name="field4{{i}}" [(ngModel)]="item.field4" />
                      </div> -->
                      <div class="input-prefix">
          							<span class="prefix text-muted">601</span>
                          <input type="text" class="form-control input-field mb-3" name="" value="" [(ngModel)]="item.field4" />
                      </div>
                    </td>
                    <td>
                      <select class="form-control" name="field5{{i}}" [(ngModel)]="item.field5"
                        (ngModelChange)="checkOrderPrice(i)">
                        <option *ngFor="let p of parcelSize" value="{{ p.value }}">{{ p.size }}</option>
                      </select>
                    </td>
                    <td *ngIf="activateCargo">
                      <input type="number" class="form-control" name="field8{{i}}" [(ngModel)]="item.field8"
                        (keydown)="checkOrderPrice(i)" min="1" value="" />
                    </td>
                    <td>
                      <div *ngIf="activateCourier">
                        <input type="number" class="form-control" name="field6{{i}}" [(ngModel)]="item.field6" max="35"
                          value="" (ngModelChange)="checkOrderPrice(i)" placeholder="Max 35kg" />
                      </div>
                      <div *ngIf="activateCargo">
                        <select class="form-control" name="field6{{i}}" [(ngModel)]="item.field6"
                          (ngModelChange)="checkOrderPrice(i)">
                          <option *ngFor="let w of weight" value="{{ w.value }}">{{ w.size }}</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <input type="text" class="form-control" name="field7{{i}}" [(ngModel)]="item.field7" disabled />
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger btn-sm" name="button"
                        (click)="deleteDeliveryPoint(i)">
                        X
                      </button>
                    </td>
                  </tr>
                  <!-- <tr>
                  <td>
                    <div class="input-group">
                      <input type="text" class="form-control" name="" value="" placeholder="Address Details" />
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary form-control-button" type="button">
                          <i class="fa fa-heart text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input type="text" class="form-control" name="" value="" placeholder="Postcode" />
                  </td>
                  <td>
                    <input type="text" class="form-control" name="" value="" placeholder="Recipient Name" />
                  </td>
                  <td>
                    <input type="text" class="form-control" name="" value="" placeholder="Mobile Number" />
                  </td>
                  <td>
                    <select class="form-control">
                      <option *ngFor="let p of parcelSize">{{ p.size }}</option>
                    </select>
                  </td>
                  <td *ngIf="activateCargo">
                    <select class="form-control" name="">
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                      <option value="">4</option>
                      <option value="">5</option>
                      <option value="">6</option>
                      <option value="">7</option>
                      <option value="">8</option>
                      <option value="">9</option>
                      <option value="">10</option>
                    </select>
                  </td>
                  <td>
                    <select class="form-control">
                      <option *ngFor="let w of weight">{{ w.size }}</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" class="form-control" name="" value="" disabled />
                  </td>
                  <td><button type="button" class="btn btn-danger" name="button"><i class="fa fa-times"></i></button></td>
                </tr> -->
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <span class="d-block text-danger">(*) indicate Mandatory field</span>
              <button type="button" class="btn btn-primary" name="button" (click)="addNewDeliveryPoint()">
                <i class="fa fa-plus"></i>
                <span> Add Item</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Stock Information -->
      <!-- <div class="delivery-stock-decoration">
      <div class="form-group row">
        <div class="col-md-3">
          <label class="label-control" for="">Parcel Type</label>
        </div>
        <div class="col-md-5">
          <select class="form-control" name="itemCategory">
            <option value="Electronics">Electronics</option>
            <option value="Fashion Apparel">Fashion &amp; Apparel</option>
            <option value="Lifestyle Products">Lifestyle &amp; Products</option>
            <option value="" selected>Other..</option>
          </select>
        </div>
        <div class="offset-md-3 col-md-5">
          <input type="text" class="form-control" name="itemCategory" placeholder="Specific your category" />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-3">
          <label class="label-control" for="">Price</label>
        </div>
        <div class="col-md-5">
          <input type="text" class="form-control" name="itemPriceValue" placeholder="Price" />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-3">
          <label class="label-control" for="">Description</label>
        </div>
        <div class="col-md-5">
          <textarea name="itemDesc" class="form-control" rows="8" cols="80"></textarea>
        </div>
      </div>

      <div class="col-md-12">
        <hr />
      </div>

      <div class="row">
        <div class="col-md-6">
          <button type="button" class="btn btn-sm btn-primary" name="button">
            <i class="fa fa-plus"></i> Add Item
          </button>
        </div>
        <div class="col-md-6 text-right">
          <button type="button" class="btn btn-sm btn-default text-danger" name="button">
            <i class="fa fa-times"></i> Remove Item
          </button>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-4">
          <label class="label-control" for="">Instruction Notes</label><br />
          <textarea name="name" class="form-control" rows="8" cols="80"></textarea>
          <div class="mt-3">
            <span class="font-italic">Information</span>
          </div>
          <div>
            <a href="javascript:void(0)" data-target="#prohibitedItemsModal" data-toggle="modal"><i
                class="far fa-question-circle"></i> Prohibited Items</a>
          </div>
          <div>
            <a href="javascript:void(0)" data-target="#noCompensationItemsModal" data-toggle="modal"><i
                class="far fa-question-circle"></i> No Compensation items</a>
          </div>
        </div>
      </div>

          <div class="row mb-5">
            <div class="col-md-5 offset-md-7 calculator-box">
              <div class="row">
                <div class="col-md-12 order-md-2 mb-3">
                  <h4 class="d-flex justify-content-between align-items-center mb-3">
                    <span class="text-muted">Your cart</span>
                  </h4>
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between">
                      <span>Total (MYR)</span>
                      <strong>RM {{ totalPriceToPay }}</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
    </div> -->
    </div>

    <!-- T&C -->
    <div *ngIf="activateAirFreight">
      <div class="row">
        <div class="col-md-4">
          <label class="label-control" for="">Notes to Your Driver</label>
          <textarea name="name" class="form-control" rows="4" cols="80" placeholder="e.g. Call me before reach to avoid waiting time. Thank you"></textarea>
        </div>
        <div class="col-md-4">
          <div>
            <label class="label-control" for="">Order Value Declaration</label>
          </div>
          <div>
            <label for="agreement">
              <input type="checkbox" id="agreement" name="" value="" /> I confirmed that the declared order value
              given in this form is correct and accurate.
            </label>
            <ng-template #tipContent>This information will be used to generate custom declaration for duties and taxes
              purposes.</ng-template>
            <a style="border-bottom: 1px dotted;" [ngbTooltip]="tipContent" placement="right"
              href="javascript:void(0);">Why is this needed?</a>
          </div>
        </div>
      </div>
      <!-- <div class="form-group row">
      <div class="col-md-12 text-left">
        <button type="button" data-target="#domesticSubmitOrderModal" data-toggle="modal" class="btn btn-primary btn-round mr-3" name="button">Submit Order</button>
      </div>
    </div> -->
    </div>

    <div *ngIf="!activateAirFreight" class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-5">
            <div class="mt-2">
              <span class="font-italic">Information</span>
            </div>
            <div>
              <a href="javascript:void(0)" data-target="#prohibitedItemsModal" data-toggle="modal"><i
                  class="far fa-question-circle"></i> Prohibited Items</a>
            </div>
            <div>
              <a href="javascript:void(0)" data-target="#noCompensationItemsModal" data-toggle="modal"><i
                  class="far fa-question-circle"></i> No Compensation items</a>
            </div>
          </div>
          <div class="col-md-7 pl-4">
            <label class="label-control" for="">Notes to Your Driver</label>
            <textarea name="name" class="form-control" rows="4" cols="80" placeholder="e.g. Call me before reach to avoid waiting time. Thank you"></textarea>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>

<div class="modal fade" id="domesticSubmitOrderModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body" style=" padding-left:25px; padding-right:25px; padding-bottom:20px;">
        <div *ngIf="activateCourier">
          <img src="assets/img/3popimg-parcel-courier.jpg" alt="parcel-courier" />
        </div>
        <div *ngIf="activateCargo">
          <img src="assets/img/3popimg-parcel-cargo.jpg" alt="parcel-cargo" />
        </div>
        <div *ngIf="activateAirFreight">
          <img src="assets/img/3popimg-parcel-air-freght.jpg" alt="parcel-air-freight" />
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary mr-2" name="button">Cancel</button>
        <button type="button" class="btn btn-primary mr-2" [routerLink]="['/main/order/list/']" name="button">Manage order</button>
      </div>
    </div>
  </div>
</div>
