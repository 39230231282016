import { Component, NgModule, OnInit } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, retry, first } from "rxjs/operators";
import { throwError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";








@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public loginName: string = "";
  public password: string = "";
  public showLogin:Boolean = true;
  public showReset:Boolean = false;
  public showVerification:Boolean = false;
  public showChangePassword:Boolean = false;

  constructor(public _http: HttpClient,private routeInfo:ActivatedRoute, private router: Router) {}




  ngOnInit(): void {}

  showLoginOnTap(): void {
    this.showLogin = true;
    this.showReset = false;
    this.showVerification = false;
    this.showChangePassword = false;
  }
  showResetOnTap(): void {
    this.showLogin = false;
    this.showReset = true;
    this.showVerification = false;
    this.showChangePassword = false;
  }
  showVerificationOnTap():void {
    this.showVerification = true;
    this.showLogin = false;
    this.showReset = false;
    this.showChangePassword = false;
  }
  showChangePasswordOnTap():void {
    this.showChangePassword = true;
    this.showVerification = false;
    this.showLogin = false;
    this.showReset = false;
  }

  login() {
  this.router.navigate(['/main/']);
//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/login/simpleLogin?telephone=${this.loginName}&password=${this.password}`,
//        ""
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        console.log(respObj);
//        if (respObj["code"] == 200) {
//          localStorage.setItem('uuid', respObj["data"]["user"]["userId"]);
//          localStorage.setItem('token', respObj["data"]["token"]);
//          localStorage.setItem('username', respObj["data"]["user"]["userLoginName"]);
//          localStorage.setItem('usertel', respObj["data"]["user"]["userTelphone"]);
//          this.router.navigate(['/main/']);
//
//
//          //$location.url('/foo2?name=bunny2&age=12#myhash2');
//          //alert(respObj["data"]["user"]["userId"]);
//          // proceed to register
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }
}
