<main role="main content" class="col-md-12 col-lg-12">
  <div class="row mt-3">
    <div class="col-md-2 align-self-center">
      <h3>Dashboard</h3>
    </div>
    <!-- Status Card -->
    <div class="col-md-2">
      <div class="card status-card text-center">
        <div class="card-body">
          <p>Credits Available</p>
          <p class="card-text">
            <sup class="status-card-sup-value">RM</sup>
            <span class="font-weight-bold status-card-value">0.00</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card status-card text-center">
        <div class="card-body">
          <p>Arriival Points</p>
          <p class="card-text">
            <span class="font-weight-bold status-card-value">2</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card status-card text-center">
        <div class="card-body">
          <p>Pending Delivery</p>
          <p class="card-text">
            <span class="font-weight-bold status-card-value">{{ orderPendingNumbers }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card status-card text-center">
        <div class="card-body">
          <p>In Delivering</p>
          <p class="card-text">
            <span class="font-weight-bold status-card-value">{{ inDeliveryOrderList }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card status-card text-center">
        <div class="card-body">
          <p>Delivery Completed</p>
          <p class="card-text">
            <span class="font-weight-bold status-card-value">{{ completedDeliveryOrderList }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <hr />

  <!-- Carousel -->
  <div id="carouselControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="assets/img/arriival-banner-free-printer.png" alt="First slide">
      </div>
      <!-- <div class="carousel-item">
        <img class="d-block w-100" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png" alt="Third slide">
      </div> -->
    </div>
    <a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

  <div class="row mb-5 mt-3">
    <div class="col-md-4">
      <a [routerLink]="['/main/delivery/single/']">
        <div class="card service-card">
          <div class="card-body service-card-body">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <img src="assets/icons/icon-instant-delivery-blue.png" width="90" alt="" />
              <span class="service-card-label mt-2">Instant Delivery</span>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="col-md-4">
      <a [routerLink]="['/main/delivery/domestic/']">
        <div class="card service-card">
          <div class="card-body service-card-body">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <img src="assets/icons/icon-domestic-delivery-blue.png" width="90" alt="" />
              <span class="service-card-label mt-2">Domestic Delivery</span>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="col-md-4">
      <a [routerLink]="['/main/delivery/international/']">
        <div class="card service-card">
          <div class="card-body service-card-body">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <img src="assets/icons/icons-air-international-blue.png" width="90" alt="" />
              <span class="service-card-label mt-2">International Delivery</span>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-all-tab" data-toggle="tab" href="#nav-all" role="tab"
            aria-controls="nav-all" aria-selected="true">All</a>
          <a class="nav-item nav-link" id="nav-pd-tab" data-toggle="tab" href="#nav-pd" role="tab"
            aria-controls="nav-pd" aria-selected="false">Pending Delivery</a>
          <a class="nav-item nav-link" id="nav-id-tab" data-toggle="tab" href="#nav-id" role="tab"
            aria-controls="nav-id" aria-selected="false">In Deliverying</a>
          <a class="nav-item nav-link" id="nav-dc-tab" data-toggle="tab" href="#nav-dc" role="tab"
            aria-controls="nav-dc" aria-selected="false">Delivery Completed</a>
          <a class="nav-item nav-link" id="nav-fd-tab" data-toggle="tab" href="#nav-fd" role="tab"
            aria-controls="nav-fd" aria-selected="false">Fail Delivery</a>
          <a class="nav-item nav-link" id="nav-oc-tab" data-toggle="tab" href="#nav-oc" role="tab"
            aria-controls="nav-oc" aria-selected="false">Order Cancelled</a>
        </div>
      </nav>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">
          <div class="row">
            <div class="col-md-12 table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr class="bg-primary text-light">
                    <th class="nowrap">*</th>
                    <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                    <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                    <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                    <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                    <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                    <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                    <th *ngIf="orderStatus" class="nowrap">Status</th>
                    <th class="nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="orderList.length == 0; else displayOrderList">
                    <td colspan="9" style="text-align: center;">No data.</td>
                  </tr>

                  <ng-template #displayOrderList>
                    <tr *ngFor="let data of orderList">
                      <td>{{ data.orderId }}</td>
                      <td><input type="checkbox" name="" value="" /></td>
                      <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                      <td *ngIf="trackingOrderNo">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderNo }}</span>
                          <span>{{ data.orderReceiverTel }}</span>
                        </div>
                      </td>
                      <td *ngIf="senderNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                          <span>{{ data.orderSendAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="receiverNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                          <span>{{ data.orderReceiveAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                      <td *ngIf="orderStatus">
                        <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                          class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                      </td>
                      <td class="nowrap">
                        <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                        <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                          name="button">View & Track</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                          name="button">Print</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                          name="button">Cancel</button>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colspan="9">
                        <div class="text-center">
                          <button type="button" class="btn btn-sm btn-info" name="button">Print Waybill</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-pd" role="tabpanel" aria-labelledby="nav-pd-tab">
          <div class="row">
            <div class="col-md-12 table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr class="bg-primary text-light">
                    <th class="nowrap">*</th>
                    <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                    <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                    <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                    <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                    <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                    <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                    <th *ngIf="orderStatus" class="nowrap">Status</th>
                    <th class="nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="orderList.length == 0; else displayOrderList">
                    <td colspan="9" style="text-align: center;">No data.</td>
                  </tr>

                  <ng-template #displayOrderList>
                    <tr *ngFor="let data of pendingOrderList">
                      <td>{{ data.orderId }}</td>
                      <td><input type="checkbox" name="" value="" /></td>
                      <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                      <td *ngIf="trackingOrderNo">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderNo }}</span>
                          <span>{{ data.orderReceiverTel }}</span>
                        </div>
                      </td>
                      <td *ngIf="senderNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                          <span>{{ data.orderSendAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="receiverNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                          <span>{{ data.orderReceiveAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                      <td *ngIf="orderStatus">
                        <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                          class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                      </td>
                      <td class="nowrap">
                        <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                        <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                          name="button">View & Track</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                          name="button">Print</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                          name="button">Cancel</button>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colspan="9">
                        <div class="text-center">
                          <button type="button" class="btn btn-sm btn-info" name="button">Print Waybill</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-id" role="tabpanel" aria-labelledby="nav-id-tab">
          <div class="row">
            <div class="col-md-12 table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr class="bg-primary text-light">
                    <th class="nowrap">*</th>
                    <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                    <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                    <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                    <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                    <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                    <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                    <th *ngIf="orderStatus" class="nowrap">Status</th>
                    <th class="nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="orderList.length == 0; else displayOrderList">
                    <td colspan="9" style="text-align: center;">No data.</td>
                  </tr>

                  <ng-template #displayOrderList>
                    <tr *ngFor="let data of inDeliveryOrderList">
                      <td>{{ data.orderId }}</td>
                      <td><input type="checkbox" name="" value="" /></td>
                      <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                      <td *ngIf="trackingOrderNo">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderNo }}</span>
                          <span>{{ data.orderReceiverTel }}</span>
                        </div>
                      </td>
                      <td *ngIf="senderNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                          <span>{{ data.orderSendAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="receiverNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                          <span>{{ data.orderReceiveAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                      <td *ngIf="orderStatus">
                        <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                          class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                      </td>
                      <td class="nowrap">
                        <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                        <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                          name="button">View & Track</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                          name="button">Print</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                          name="button">Cancel</button>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colspan="9">
                        <div class="text-center">
                          <button type="button" class="btn btn-sm btn-info" name="button">Print Waybill</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-dc" role="tabpanel" aria-labelledby="nav-dc-tab">
          <div class="row">
            <div class="col-md-12 table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr class="bg-primary text-light">
                    <th class="nowrap">*</th>
                    <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                    <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                    <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                    <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                    <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                    <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                    <th *ngIf="orderStatus" class="nowrap">Status</th>
                    <th class="nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="orderList.length == 0; else displayOrderList">
                    <td colspan="9" style="text-align: center;">No data.</td>
                  </tr>

                  <ng-template #displayOrderList>
                    <tr *ngFor="let data of completedDeliveryOrderList">
                      <td>{{ data.orderId }}</td>
                      <td><input type="checkbox" name="" value="" /></td>
                      <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                      <td *ngIf="trackingOrderNo">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderNo }}</span>
                          <span>{{ data.orderReceiverTel }}</span>
                        </div>
                      </td>
                      <td *ngIf="senderNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                          <span>{{ data.orderSendAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="receiverNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                          <span>{{ data.orderReceiveAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                      <td *ngIf="orderStatus">
                        <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                          class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                      </td>
                      <td class="nowrap">
                        <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                        <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                          name="button">View & Track</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                          name="button">Print</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                          name="button">Cancel</button>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colspan="9">
                        <div class="text-center">
                          <button type="button" class="btn btn-sm btn-info" name="button">Print Waybill</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-fd" role="tabpanel" aria-labelledby="nav-fd-tab">
          <div class="row">
            <div class="col-md-12 table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr class="bg-primary text-light">
                    <th class="nowrap">*</th>
                    <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                    <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                    <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                    <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                    <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                    <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                    <th *ngIf="orderStatus" class="nowrap">Status</th>
                    <th class="nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="orderList.length == 0; else displayOrderList">
                    <td colspan="9" style="text-align: center;">No data.</td>
                  </tr>

                  <ng-template #displayOrderList>
                    <tr *ngFor="let data of failedDeliveryOrderList">
                      <td>{{ data.orderId }}</td>
                      <td><input type="checkbox" name="" value="" /></td>
                      <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                      <td *ngIf="trackingOrderNo">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderNo }}</span>
                          <span>{{ data.orderReceiverTel }}</span>
                        </div>
                      </td>
                      <td *ngIf="senderNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                          <span>{{ data.orderSendAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="receiverNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                          <span>{{ data.orderReceiveAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                      <td *ngIf="orderStatus">
                        <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                          class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                      </td>
                      <td class="nowrap">
                        <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                        <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                          name="button">View & Track</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                          name="button">Print</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                          name="button">Cancel</button>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colspan="9">
                        <div class="text-center">
                          <button type="button" class="btn btn-sm btn-info" name="button">Print Waybill</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-oc" role="tabpanel" aria-labelledby="nav-oc-tab">
          <div class="row">
            <div class="col-md-12 table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr class="bg-primary text-light">
                    <th class="nowrap">*</th>
                    <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                    <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                    <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                    <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                    <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                    <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                    <th *ngIf="orderStatus" class="nowrap">Status</th>
                    <th class="nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="orderList.length == 0; else displayOrderList">
                    <td colspan="9" style="text-align: center;">No data.</td>
                  </tr>

                  <ng-template #displayOrderList>
                    <tr *ngFor="let data of cancelledOrderList">
                      <td>{{ data.orderId }}</td>
                      <td><input type="checkbox" name="" value="" /></td>
                      <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                      <td *ngIf="trackingOrderNo">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderNo }}</span>
                          <span>{{ data.orderReceiverTel }}</span>
                        </div>
                      </td>
                      <td *ngIf="senderNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                          <span>{{ data.orderSendAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="receiverNameAddress">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                          <span>{{ data.orderReceiveAddr }}</span>
                        </div>
                      </td>
                      <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                      <td *ngIf="orderStatus">
                        <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                          class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                      </td>
                      <td class="nowrap">
                        <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                        <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                          name="button">View & Track</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                          name="button">Print</button>&nbsp;
                        <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                          name="button">Cancel</button>&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colspan="9">
                        <div class="text-center">
                          <button type="button" class="btn btn-sm btn-info" name="button">Print Waybill</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                          <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<div class="modal fade" id="paymentPasswordModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header justify-content-start" style="background-color: #0089CF;color: white;">
        <button type="button" class="btn btn-default" style="color: white;" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">
          <span>Set a Payment Password</span>
        </h4>
      </div>
      <div class="modal-body" style=" padding-left:25px; padding-right:25px; padding-bottom:20px;">
        <form>
          <div class="form-group">
            <label for="paymentPasswordInput">Old Payment Password</label>
            <input type="password" class="form-control" id="paymentPasswordInput" name="oldPaymentPass"
              [(ngModel)]="oldPaymentPass" placeholder="Old Payment Password">
          </div>
          <div class="form-group">
            <label for="paymentPasswordInput">New Payment Password</label>
            <input type="password" class="form-control" id="paymentPasswordInput" name="newPaymentPass"
              [(ngModel)]="newPaymentPass" placeholder="New Payment Password">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-round mr-2" name="button"
          data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary btn-round mr-2" (click)="submitPaymentPassword()"
          name="button">Submit</button>
      </div>
    </div>
  </div>
</div>