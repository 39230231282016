<div class="content col-md-9 col-lg-12 pt-3">
  <div class="row">
    <div class="col-md-11 offset-md-1">
      <div class="row pt-4">
        <div class="col-md-12">
          <h2>Earn Arriival Point Reward</h2>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12">
              <span style="font-size: 18px;font-weight: 600;">What is Arriival Point</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Arriival Point let you earn cash back, or you can convert to Cash and purchase from or enjoy your purchase in our Arriival Online Store.
            </div>
          </div>
        </div>
        <div class="col-md-5 offset-md-1">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <span style="font-size: 18px;font-weight: 600;">How to earn?</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  Introduce new users through your unique referral link, and you can an unlimited get up to 4% from every order as an introduction fee with Arriival Point
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12 text-center">
      <img src="/assets/img/guide-referral-reward3.png" style="width: 100%;object-fit: contain;" alt="" />
    </div>
  </div>
</div>
