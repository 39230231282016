<div class="content pt-3">

  <div class="row">
    <div class="col-md-6">
      <h2>Top Up Credit Invoice</h2>
      <span>Your top up credit history with us</span>
      <!-- <div class="d-flex pl-2">
        <span style="width: 125px;" class="invoice-to-info invoice-to-label">Company Name</span>
        <span class="mr-1 invoice-to-info double-column">:</span>
        <span class="text-uppercase invoice-to-info">sun ceramic sdn bhd</span>
      </div>
      <div class="d-flex pl-2">
        <span style="width: 125px;" class="invoice-to-info invoice-to-label">Register ID HP</span>
        <span class="mr-1 invoice-to-info double-column">:</span>
        <span class="invoice-to-info">60123380984</span>
      </div>
      <div class="d-flex pl-2">
        <span style="width: 125px;" class="invoice-to-info invoice-to-label">Date</span>
        <span class="mr-1 invoice-to-info double-column">:</span>
        <span class="invoice-to-info font-weight-bold">01/02/2020 ~ 29/02/2020</span>
      </div>
      <div class="d-flex pl-2">
        <span style="width: 125px;" class="invoice-to-info invoice-to-label">Total entries</span>
        <span class="mr-1 invoice-to-info double-column">:</span>
        <span class="invoice-to-info">6</span>
      </div> -->
    </div>
    <div class="col-md-6 text-right pt-2"><!--#noprint-->
      <!-- <button type="button" class="btn btn-primary btn-sm" style="padding: 5px 25px;border-radius: 20px;" (click)="generatePDF()">Export as PDF</button> -->
      <h4 class="mt-4 font-weight-bold">Total Top up credit: RM 465.20</h4>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <div class="d-flex flex-row align-items-start">
        <!-- <div class="d-flex flex-row mr-3" style="width: 30%;">
          <select class="form-control mr-1" name="" placeholder="o">
            <option value="" disabled selected hidden>Operation Type</option>
            <option value=""></option>
          </select>
          <input type="text" class="form-control ml-1" placeholder="Order Number" name="" value="" />
        </div> -->

        <div class="d-flex flex-row mr-2" style="max-width: 45%;">
          <div class="d-flex flex-row mr-1">
            <label class="mr-1" style="white-space: nowrap;line-height: 2.5" for="">Date Start</label>
            <input type="date" name="" class="form-control" value="" style="width: 166px;" />
          </div>
          <div class="d-flex flex-row ml-1">
            <label class="mr-1" style="white-space: nowrap;line-height: 2.5;" for="">Date End</label>
            <input type="date" name="" class="form-control" value="" style="width: 166px;" />
          </div>
        </div>

        <button type="button" class="btn btn-primary btn-sm" style="padding: 8px 20px;border-radius: 20px;" name="button">Search</button>

        <div class="d-flex flex-row flex-fill ml-4 justify-content-end">
          <span style="line-height: 2.5;">show</span>
          <select class="form-control mx-1" name="" style="width: 5vw;">
            <option value="">10</option>
          </select>
          <span style="line-height: 2.5;">entries</span>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row mt-2">
    <div class="col-md-12">
      <div class="d-flex flex-row py-3 px-2 bg-statement">
        <div class="flex-fill column-width">
          <span class="t-title">Transaction Date/Time</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Order No</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Operation Types</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Detail</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Balance Before</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Operating Amount</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Balance After</span>
        </div>
      </div>

      <div *ngFor="let statement of statementData[0].statements | slice: (page-1) * pageSize : page * pageSize; let i = index" class="d-flex flex-row mini-font statement-border-bottom py-3 px-2">
        <div class="flex-fill column-width">
          <span>{{ statement.orderTime }}</span>
        </div>
        <div class="flex-fill column-width">
          <span>{{ statement.documentNo }}</span>
        </div>
        <div class="flex-fill column-width">
          <span>{{ statement.type }}</span>
        </div>
        <div class="flex-fill column-width">
          <span>{{ statement.type }}</span>
        </div>
        <div class="d-flex flex-fill column-width">
          <span class="pr-2">{{ statementData[0].currency }}</span>
          <span>{{ statement.debit }}</span>
        </div>
        <div class="d-flex flex-fill column-width" [ngClass]="(statement.credit > 0) ? 'text-success' : 'text-danger'">
          <span class="pr-2">{{ statementData[0].currency }}</span>
          <span>{{ statement.credit }}</span>
        </div>
        <div class="d-flex flex-fill column-width">
          <span class="pr-2">{{ statementData[0].currency }}</span>
          <span>{{ statement.balance }}</span>
        </div>
      </div>
    </div>
  </div> -->

  <div class="row mt-2 py-3 px-2 bg-invoice">
    <div class="col-md-2">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Transaction Date/Time</span>
    </div>
    <div class="col-md-2">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Invoice Number</span>
    </div>
    <div class="col-md-4">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Description</span>
    </div>
    <div class="col-md-2 text-right">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Top Up Amount</span>
    </div>
    <div class="col-md-2 text-right">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Action</span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div *ngFor="let invoice of invoiceData[0].invoices | slice: (page-1) * pageSize : page * pageSize; let i = index" class="row py-2 px-2 invoice-border-bottom">
        <div class="col-md-2">
          <span>{{ invoice.dateTime }}</span>
        </div>
        <div class="col-md-2">
          <span>{{ invoice.invoiceNo }}</span>
        </div>
        <div class="col-md-4">
          <span>{{ invoice.description }}</span>
        </div>
        <div class="col-md-2 text-right">
          <span>{{ invoiceData[0].currency }} {{ invoice.amount }}</span>
        </div>
        <div class="col-md-2 text-right" #noprint>
          <!-- <span>500.00</span> -->
          <button type="button" class="btn btn-primary btn-sm" (click)="generatePDF()" name="button">Download Invoice</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-6 text-left">
      <span class="font-weight-bold">Showing 1 to 6 of 6 entries</span>
    </div>
    <div class="col-md-6 d-flex flex-row justify-content-end">
      <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="invoiceData[0].invoices.length"></ngb-pagination>
    </div>
  </div>

  <!-- <div class="row mb-5 text-right" #noprint>
    <div class="col-md-12">
      <button type="button" class="btn btn-success" (click)="generatePDF()">Export as PDF</button>
    </div>
  </div> -->

  <!-- Print Out Section -->
  <div id="contentToConvert" style="position: absolute;width: 100vw;left: -5000px;top: 0;">
    <div class="logo-overlay"></div>
    <div class="row">
      <div class="col-md-5">
        <img src="/assets/img/logo2.png" width="120" alt="" /><br />
        <span class="font-weight-bold pl-2">Invoice to</span><br />
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Company Name</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="text-uppercase invoice-to-info">sun ceramic sdn bhd</span>
        </div>
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Contact Name</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="invoice-to-info">Contact Person Name</span>
        </div>
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Register ID HP</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="invoice-to-info">60123380984</span>
        </div>
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Address</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="invoice-to-info"></span>
        </div>
      </div>
      <div class="col-md-7 text-right">
        <div>
          <span class="font-weight-bold company-name-size">ARRIIVAL TECHNOLOGIES SDN BHD</span><span> (1294634-V)</span>
        </div>
        <div>
          <span>No.9, Jalan YTN 3, Industri Desa Aman, 52200 Kuala Lumpur.</span>
        </div>
        <div>
          <span class="mr-3">Tel: +603-2107-0187</span>
          <span class="mr-3">Email: support@arriival.com</span>
          <span>www.arriival.com</span>
        </div>
        <div class="mt-4">
          <span class="font-weight-bold text-uppercase invoice-label">invoice</span><br />
          <span class="font-weight-bold text-uppercase font-size-20">inv20201028-007</span><br />
          <span class="font-size-20">Date: 28/10/2020</span>
        </div>
      </div>
    </div>
  <div class="row mt-5 py-3 px-2 bg-invoice">
    <div class="col-md-1">
      <span class="t-title">Item</span>
    </div>
    <div class="col-md-6">
      <span class="t-title">Description</span>
    </div>
    <div class="col-md-1 text-center">
      <span class="t-title">Qty</span>
    </div>
    <div class="col-md-2 text-right">
      <span class="t-title">Unt Price (RM)</span>
    </div>
    <div class="col-md-2 text-right">
      <span class="t-title">Amount (RM)</span>
    </div>
  </div>
  <div class="row body-min-height">
    <div class="col-md-12">
      <div class="row py-2 px-2 invoice-border-bottom">
        <div class="col-md-1">
          <span>1</span>
        </div>
        <div class="col-md-6">
          <span>ARRIIVAL TOP UP CREDIT</span>
        </div>
        <div class="col-md-1 text-center">
          <span>1</span>
        </div>
        <div class="col-md-2 text-right">
          <span>500.00</span>
        </div>
        <div class="col-md-2 text-right">
          <span>500.00</span>
        </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="row mt-1">
    <div class="col-md-2 offset-md-9 text-left">
      <div class="d-flex">
        <span class="text-left price-label">Sub total</span>
        <span class="double-column">:</span>
        <span class="text-uppercase">RM500.00</span>
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-md-2 offset-md-9 text-left">
      <div class="d-flex">
        <span class="text-left price-label">Tax</span>
        <span class="double-column">:</span>
        <span class="text-uppercase">&#8208;&#8208;</span>
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-md-2 offset-md-9 text-left">
      <div class="d-flex price-large">
        <span class="text-left price-label">Total</span>
        <span class="double-column">:</span>
        <span class="text-uppercase">RM500.00</span>
      </div>
    </div>
  </div>

    <div class="row">
      <div class="col-md-6">
        <span>Notes :</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span>1. All prices are in Ringgit Malaysia and not refundable.</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span>2. This is a computer generated invoice and do not require any stamp or signature.</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <span class="font-weight-bold font-size-20">Thank you for your support.</span>
      </div>
    </div>
  </div>

</div>
