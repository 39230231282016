<div class="content col-md-9 col-lg-12 pt-3">
  <div class="row">
    <div class="col-md-8">
      <!-- Current Page Title -->
      <div class="row">
        <div class="col-md-12">
          <h2>Instant Delivery</h2>
        </div>
      </div>

      <!-- Current Page Sub-title -->
      <div class="row">
        <div class="col-md-12">
          <p>Enter your order details and let us know if you have any special arrangements needed.</p>
        </div>
      </div>

      <!-- Step 1 -->
      <div class="row">
        <div class="col-md-12">
          <span class="mr-3 step-span">Step 1</span>
          <span class="text-muted">Select your delivery type</span>
          <hr />
        </div>
      </div>

      <!-- Service Selector -->
      <div class="row">
        <div class="col-md-6">
          <div class="btn-group w-100 type-flex">
            <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateExpressDelivery}" (click)="activateExpressDeliveryButton()" name="button">
              <div>
                <div class="d-flex h-100 flex-row justify-content-center align-items-center">
                    <img src="assets/icons/icon-int-express-blue.png" *ngIf="activateExpressDelivery == false" width="80" alt="" />
                    <img src="assets/icons/icon-int-express-white.png" *ngIf="activateExpressDelivery == true" width="80" alt="" />
                    <span class="card-title">Express Delivery</span>
                </div>
              </div>
            </button>
            <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateMultiDelivery}" (click)="activateMultiDeliveryButton()" name="button">
              <div>
                <div class="d-flex h-100 flex-row justify-content-center align-items-center">
                  <img src="assets/icons/icon-int-muti-blue.png" *ngIf="activateMultiDelivery == false" width="80" alt="" />
                  <img src="assets/icons/icon-int-muti-white.png" *ngIf="activateMultiDelivery == true" width="80" alt="" />
                    <span class="card-title">MultiStop Delivery</span>
                </div>
              </div>
            </button>
          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <div class="arrow-up" [ngClass]="{'ml-23-percent': activateExpressDelivery, 'ml-74-percent': activateMultiDelivery}"></div>
              <div class="alert bg-green text-light">
                <p *ngIf="activateExpressDelivery">Urgent Delivery and it completed the delivery in 3 hours within same city.</p>
                <p *ngIf="activateMultiDelivery">Is the delivery type of who wish to delivery to multiple location. Delivery will complete in same day.</p>
              </div>
            </div>
          </div>

          <!-- <div class="d-flex flex-column">
            <label class="label-control font-weight-bold" for="">Parcel Type</label>
            <input type="text" class="form-control" name="" value="" (keyup)="keyUpTotalWeight($event)" placeholder="0 kg" />
          </div> -->

          <div class="d-flex flex-column">
            <label class="label-control" for="">Parcel Type</label>
            <div class="row">
              <div class="col-md-8">
                <select class="form-control" [(ngModel)]="selectedParcelType" name="">
                  <option *ngFor="let item of parcelTypeItems" [ngValue]="item.value">{{ item.name }}</option>
                  <!-- <option value="document" selected>Document</option>
                  <option value="foodAndBeverages">Food &amp; Beverages</option>
                  <option value="gift">Gift</option>
                  <option value="groceries">Groceries</option>
                  <option value="flower">Flower</option>
                  <option value="cake">Cake</option>
                  <option value="other">Other..</option> -->
                </select>
              </div>
              <div class="col-md-4">
                <label class="label-control" for="">
                  Non Halal <input type="checkbox" name="" value="" />
                </label>
              </div>
            </div>
            <div *ngIf="selectedParcelType == 'other'" class="row">
              <div class="col-md-8">
                <input type="text" class="form-control" name="" value="" placeholder="Specific your category" />
              </div>
            </div>
          </div>

        </div>

        <!-- Vehicle Selector -->
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <div class="btn-group-vertical w-100 d-flex vehicle-flex" style="flex-direction: column;">
                <button type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateMotor, 'pt-55-percent' : (activateMotor && activateExpressDelivery), 'pt-45-percent': (activateMotor && activateMultiDelivery)}" (click)="activateMotorButton()" name="button"><!--style="padding-top: 20%;"-->
                  <div>
                    <div class="d-flex h-100 flex-row justify-content-start align-items-center">
                      <div class="d-flex flex-column" [ngClass]="{'h-100': activateMotor}" style="position: relative;width: 100%;">
                        <div class="d-flex flex-row">
                          <img class="pl-3" src="assets/icons/icons-bike-blue.png" *ngIf="activateMotor == false" width="50" alt="" />
                          <img class="pl-3" src="assets/icons/icons-bike-white.png" *ngIf="activateMotor == true" width="50" alt="" />
                          <div class="d-flex flex-column align-items-start">
                            <span class="card-title">Motorcycle Up to 8KG</span>
                            <div class="flex-column card-small-label-wrapper">
                              <span class="card-small-label">(30cm x 30cm x 30cm)</span>
                              <!-- <span class="card-small-label card-weight-label">Up to 8KG</span> -->
                            </div>
                          </div>
                        </div>
                        <div [ngClass]="{'fade-animation': activateMotor, 'd-none': !activateMotor}" class="p-2 additional-service" style="width: 100%;height: 100%;">
                          <div class="d-flex flex-column align-items-start p-2 text-dark" style="background-color: #CCCCCC;border-radius: 5px;">
                            <span class="font-weight-bold">Additional Service</span>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Payment Collection Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Return Goods Service
                            </label>
                            <label *ngIf="activateExpressDelivery" for="">
                              <input type="checkbox" name="" value="" /> Return D.O Service
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateCar, 'pt-65-percent' : (activateExpressDelivery && activateCar), 'pt-55-percent': (activateMultiDelivery && activateCar)}" (click)="activateCarButton()" name="button">
                  <div>
                    <div class="d-flex h-100 flex-row justify-content-start align-items-center">
                      <div class="d-flex flex-column" [ngClass]="{'h-100': activateCar}" style="position: relative;width: 100%;">
                        <div class="d-flex flex-row">
                          <img class="pl-3" src="assets/icons/icons-car-blue.png" *ngIf="activateCar == false" width="50" alt="" />
                          <img class="pl-3" src="assets/icons/icons-car-white.png" *ngIf="activateCar == true" width="50" alt="" />
                          <div class="d-flex flex-column align-items-start">
                            <span class="card-title">Car Up to 50KG</span>
                            <div class="flex-column card-small-label-wrapper">
                              <span class="card-small-label">(60cm x 60cm x 60cm)</span>
                              <!-- <span class="card-small-label card-weight-label">9 - 50 KG</span> -->
                            </div>
                          </div>
                        </div>
                        <div [ngClass]="{'fade-animation': activateCar, 'd-none': !activateCar}" class="p-2 additional-service" style="width: 100%;height: 100%;">
                          <div class="d-flex flex-column align-items-start p-2 text-dark" style="background-color: #CCCCCC;border-radius: 5px;">
                            <span class="font-weight-bold">Additional Service</span>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Payment Collection Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Return Goods Service
                            </label>
                            <label *ngIf="activateExpressDelivery" for="">
                              <input type="checkbox" name="" value="" /> Return D.O Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Door-to-Door Service by Driver
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateVan, 'pt-75-percent': (activateExpressDelivery && activateVan), 'pt-55-percent': (activateMultiDelivery && activateVan)}" (click)="activateVanButton()" name="button">
                  <div>
                    <div class="d-flex h-100 flex-row justify-content-start align-items-center">
                      <div class="d-flex flex-column" [ngClass]="{'h-100': activateVan}" style="position: relative;width: 100%;">
                        <div class="d-flex flex-row">
                          <img class="pl-3" src="assets/icons/icons-van-blue.png" *ngIf="activateVan == false" width="50" alt="" />
                          <img class="pl-3" src="assets/icons/icons-van-white.png" *ngIf="activateVan == true" width="50" alt="" />
                          <div class="d-flex flex-column align-items-start">
                            <span class="card-title">Van Up to 500KG</span>
                            <div class="flex-column card-small-label-wrapper">
                              <span class="card-small-label">(210cm x 140cm x 130cm)</span>
                              <!-- <span class="card-small-label card-weight-label">51 - 500 KG</span> -->
                            </div>
                          </div>
                        </div>
                        <div [ngClass]="{'fade-animation': activateVan, 'd-none': !activateVan}" class="p-2 additional-service" style="width: 100%;height: 100%;">
                          <div class="d-flex flex-column align-items-start p-2 text-dark" style="background-color: #CCCCCC;border-radius: 5px;">
                            <span class="font-weight-bold">Additional Service</span>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Payment Collection Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Return Goods Service
                            </label>
                            <label *ngIf="activateExpressDelivery" for="">
                              <input type="checkbox" name="" value="" /> Return D.O Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Door-to-Door Service by Driver
                            </label>
                            <label *ngIf="activateExpressDelivery" for="">
                              <input type="checkbox" name="" value="" /> Door-to-Door Service + 1 Helper
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button *ngIf="activateExpressDelivery" type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateOneTonLorry, 'pt-75-percent': activateOneTonLorry}" (click)="activateOneTonLorryButton()" name="button">
                  <div>
                    <div class="d-flex h-100 flex-row justify-content-start align-items-center">
                      <div class="d-flex flex-column" [ngClass]="{'h-100': activateOneTonLorry}" style="position: relative;width: 100%;">
                        <div class="d-flex flex-row">
                          <img class="pl-3" src="assets/icons/icons-lorry-blue.png" *ngIf="activateOneTonLorry == false" width="50" alt="" />
                          <img class="pl-3" src="assets/icons/icons-lorry-white.png" *ngIf="activateOneTonLorry == true" width="50" alt="" />
                          <div class="d-flex flex-column align-items-start">
                            <span class="card-title">Lorry Up to 1000KG</span>
                            <div class="flex-column card-small-label-wrapper">
                              <span class="card-small-label">(275cm x 152cm x 152cm)</span>
                              <!-- <span class="card-small-label card-weight-label">1000 KG</span> -->
                            </div>
                          </div>
                        </div>
                        <div [ngClass]="{'fade-animation': activateOneTonLorry, 'd-none': !activateOneTonLorry}" class="p-2 additional-service" style="width: 100%;height: 100%;">
                          <div class="d-flex flex-column align-items-start p-2 text-dark" style="background-color: #CCCCCC;border-radius: 5px;">
                            <span class="font-weight-bold">Additional Service</span>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Payment Collection Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Return Goods Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Door-to-Door Service by Driver
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Door-to-Door Service + 1 Helper
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Loading Service
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button *ngIf="activateExpressDelivery" type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateThreeTonLorry, 'pt-75-percent': activateThreeTonLorry}" (click)="activateThreeTonLorryButton()" name="button">
                  <div>
                    <div class="d-flex h-100 flex-row justify-content-start align-items-center">
                      <div class="d-flex flex-column" [ngClass]="{'h-100': activateThreeTonLorry}" style="position: relative;width: 100%;">
                        <div class="d-flex flex-row">
                          <img class="pl-3" src="assets/icons/icons-lorry-blue.png" *ngIf="activateThreeTonLorry == false" width="50" alt="" />
                          <img class="pl-3" src="assets/icons/icons-lorry-white.png" *ngIf="activateThreeTonLorry == true" width="50" alt="" />
                          <div class="d-flex flex-column align-items-start">
                            <span class="card-title">Lorry Up to 3000KG</span>
                            <div class="flex-column card-small-label-wrapper">
                              <span class="card-small-label">(427cm x 220cm x 213cm)</span>
                              <!-- <span class="card-small-label card-weight-label">3000 KG</span> -->
                            </div>
                          </div>
                        </div>
                        <div [ngClass]="{'fade-animation': activateThreeTonLorry, 'd-none': !activateThreeTonLorry}" class="p-2 additional-service" style="width: 100%;height: 100%;">
                          <div class="d-flex flex-column align-items-start p-2 text-dark" style="background-color: #CCCCCC;border-radius: 5px;">
                            <span class="font-weight-bold">Additional Service</span>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Payment Collection Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Return Goods Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Door-to-Door Service by Driver
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Door-to-Door Service + 1 Helper
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Loading Service
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button *ngIf="activateExpressDelivery" type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateChilledLorry, 'pt-75-percent': activateChilledLorry}" (click)="activateChilledLorryButton()" name="button">
                  <div>
                    <div class="d-flex h-100 flex-row justify-content-start align-items-center">
                      <div class="d-flex flex-column" [ngClass]="{'h-100': activateChilledLorry}" style="position: relative;width: 100%;">
                        <div class="d-flex flex-row">
                          <img class="pl-3" src="assets/icons/icons-frozen-lorry-blue.png" *ngIf="activateChilledLorry == false" width="50" alt="" />
                          <img class="pl-3" src="assets/icons/icons-frozen-lorry-white.png" *ngIf="activateChilledLorry == true" width="50" alt="" />
                          <div class="d-flex flex-column align-items-start">
                            <span class="card-title">Chilled Lorry Up to 1000KG</span>
                            <div class="flex-column card-small-label-wrapper">
                              <span class="card-small-label">(275cm x 152cm x 125cm)</span>
                              <!-- <span class="card-small-label card-weight-label">1000 KG</span> -->
                            </div>
                          </div>
                        </div>
                        <div [ngClass]="{'fade-animation': activateChilledLorry, 'd-none': !activateChilledLorry}" class="p-2 additional-service" style="width: 100%;height: 100%;">
                          <div class="d-flex flex-column align-items-start p-2 text-dark" style="background-color: #CCCCCC;border-radius: 5px;">
                            <span class="font-weight-bold">Additional Service</span>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Payment Collection Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Return Goods Service
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Door-to-Door Service by Driver
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Door-to-Door Service + 1 Helper
                            </label>
                            <label for="">
                              <input type="checkbox" name="" value="" /> Loading Service
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <div class="btn-group w-100 trip-flex">
            <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateOneWayTrip}" (click)="activateOneWayTripButton()" name="button">
              <div>
                <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                    <i class="fas fa-2x fa-arrow-right"></i>
                    <span class="card-title">One Way</span>
                </div>
              </div>
            </button>
            <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateReturnTrip}" (click)="activateReturnTripButton()" name="button">
              <div>
                <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                  <i class="fas fa-2x fa-arrows-alt-h"></i>
                  <span class="card-title">Return Trip</span>
                </div>
              </div>
            </button>
          </div>
        </div> -->
      </div>

    </div>

    <div class="col-md-4 position-relative">
      <div class="h-100">
        <div class="cart-outer-box p-3" style="border: 1px solid var(--primary-color);border-radius: 5px;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);z-index: 1;background-color: white;position: sticky;top: 75px;">
          <div class="cart-inner-box">
            <div class="cart-header mb-3">
              <span class="text-uppercase font-weight-bold text-primary" style="font-size: 20px;">your order:</span>
            </div>
            <div class="cart-body">
              <div class="cart-body-date" style="font-size: 12px;">
                <div class="d-flex flex-row">
                  <span style="width: 30%;">Requested at:</span>
                  <div class="" style="width: 25%;">
                    <i class="fa fa-calendar mr-1"></i>
                    <span>10/3/2021</span>
                  </div>
                  <div class="">
                    <i class="fa fa-clock mr-1"></i>
                    <span>12:44 PM (later)</span>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <span style="width: 30%;">Drop Off by:</span>
                  <div class="" style="width: 25%;">
                    <i class="fa fa-calendar mr-1"></i>
                    <span>10/3/2021</span>
                  </div>
                  <div class="">
                    <i class="fa fa-clock mr-1"></i>
                    <span>15:44 PM</span>
                  </div>
                </div>
              </div>
              <div class="cart-body-header d-flex justify-content-between align-items-end">
                <span style="font-size: 16px;">Price Details</span>
                <i style="font-size: 16px;" *ngIf="!isCollapsed" (click)="isCollapsed = true" class="fa fa-times text-danger"></i>
                <i style="font-size: 16px;" *ngIf="isCollapsed" (click)="isCollapsed = false" class="fas fa-bars"></i>
              </div>
              <div *ngIf="!isCollapsed" class="cart-body-content" style="font-size: 12px;">
                <div class="row py-2" *ngFor="let item of cartItems[0].items">
                  <div class="col-md-4">
                    <span>{{ item.qty }} x {{ item.name }}</span>
                  </div>
                  <div class="col-md-4">
                    <span>(RM{{ item.price }} x {{ item.qty }})</span>
                  </div>
                  <div class="col-md-4 text-right">
                    <span>RM {{ item.sub_total }}</span>
                  </div>
                </div>
              </div>
              <div class="cart-body-footer mb-3">
                <div class="row mx-0">
                  <div class="col-md-12 text-right" style="border-top: 1px solid black;border-bottom: double black;">
                    <span class="font-weight-bold" style="font-size: 16px;">Total: </span><span class="font-weight-bold" style="font-size: 20px;">RM {{ cartItems[0].total }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <span style="font-weight: 600;" class="mr-2 text-primary">Corporate Price RM45.50</span><i class="far fa-question-circle"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="cart-footer">
              <div class="mb-2">
                <span *ngIf="!activateCouponField">Click <a (click)="showCouponField()" href="javascript:void(0);">here</a> to enter discount coupon</span>
                <div class="d-flex flex-row nowrap" style="align-items:flex-center;align-content:flex-center;"
                  *ngIf="activateCouponField">
                  <div class="pr-2" style="height: 28px;">
                    <label style="font-size: 14px;" for="">Coupon code</label>
                  </div>
                  <input class="mr-2" type="text" name="" value="" style="width: 40%;height: 28px;" />
                  <button type="button" class="btn btn-sm btn-secondary mr-2" name="button"
                    style="height: 28px;padding-top: 0;">Apply</button>
                  <div (click)="hideCouponField()" style="height: 28px;"><i class="fa fa-times text-danger"></i></div>
                </div>
              </div>
              <div class="mb-2">
                <button type="button" data-target="#instantSubmitOrderModal" data-toggle="modal" class="btn btn-primary btn-block" name="button">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="flex-fill">Buy Credit & Proceed with RM45.40</span>
                    <i class="fa fa-long-arrow-right"></i>
                  </div>
                </button>
              </div>
              <div class="mb-2">
                <button type="button" data-target="#instantSubmitOrderModal" data-toggle="modal" class="btn btn-secondary btn-block" name="button">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="flex-fill">Proceed Instant pay as RM48.40</span>
                    <i class="fa fa-long-arrow-right"></i>
                  </div>
                </button>
              </div>
              <div>
                <button type="button" data-target="#instantSubmitOrderModal" data-toggle="modal" class="btn btn-secondary btn-block" name="button">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="flex-fill">Pay driver with cash</span>
                    <i class="fa fa-long-arrow-right"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>





  <form class="mb-5 pb-5" action="index.html" method="post">
    <!-- <div class="row">
      <div class="col-md-4">
        <div class="btn-group w-100 type-flex">
          <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateExpressDelivery}" (click)="activateExpressDeliveryButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-row justify-content-center align-items-center">
                  <img src="assets/icons/icon-int-express-blue.png" *ngIf="activateExpressDelivery == false" width="80" alt="" />
                  <img src="assets/icons/icon-int-express-white.png" *ngIf="activateExpressDelivery == true" width="80" alt="" />
                  <span class="card-title">Express Delivery</span>
              </div>
            </div>
          </button>
          <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateMultiDelivery}" (click)="activateMultiDeliveryButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-row justify-content-center align-items-center">
                <img src="assets/icons/icon-int-muti-blue.png" *ngIf="activateMultiDelivery == false" width="80" alt="" />
                <img src="assets/icons/icon-int-muti-white.png" *ngIf="activateMultiDelivery == true" width="80" alt="" />
                  <span class="card-title">MultiStop Delivery</span>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <div class="btn-group w-100 trip-flex">
          <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateOneWayTrip}" (click)="activateOneWayTripButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                  <i class="fas fa-2x fa-arrow-right"></i>
                  <span class="card-title">One Way</span>
              </div>
            </div>
          </button>
          <button type="button" class="btn btn-outline-primary" [ngClass]="{'active' : activateReturnTrip}" (click)="activateReturnTripButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                <i class="fas fa-2x fa-arrows-alt-h"></i>
                <span class="card-title">Return Trip</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div> -->

    <!-- <div class="row mt-3">
      <div class="col-md-8">
        <div class="btn-group w-100 d-flex vehicle-flex">
          <button type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateMotor}" (click)="activateMotorButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                <img src="assets/icons/icons-bike-blue.png" *ngIf="activateMotor == false" width="50" alt="" />
                <img src="assets/icons/icons-bike-white.png" *ngIf="activateMotor == true" width="50" alt="" />
                <span class="card-title">Motorcycle</span>
                <div class="flex-column card-small-label-wrapper">
                  <span class="card-small-label">(30cm x 30cm x 30cm)</span>
                  <span class="card-small-label card-weight-label">1 - 8 KG</span>
                </div>
              </div>
            </div>
          </button>
          <button type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateCar}" (click)="activateCarButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                <img src="assets/icons/icons-car-blue.png" *ngIf="activateCar == false" width="50" alt="" />
                <img src="assets/icons/icons-car-white.png" *ngIf="activateCar == true" width="50" alt="" />
                <span class="card-title">Car</span>
                <div class="flex-column card-small-label-wrapper">
                  <span class="card-small-label">(60cm x 60cm x 60cm)</span>
                  <span class="card-small-label card-weight-label">9 - 50 KG</span>
                </div>
              </div>
            </div>
          </button>
          <button type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateVan}" (click)="activateVanButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                <img src="assets/icons/icons-van-blue.png" *ngIf="activateVan == false" width="50" alt="" />
                <img src="assets/icons/icons-van-white.png" *ngIf="activateVan == true" width="50" alt="" />
                <span class="card-title">Van</span>
                <div class="flex-column card-small-label-wrapper">
                  <span class="card-small-label">(210cm x 140cm x 130cm)</span>
                  <span class="card-small-label card-weight-label">51 - 500 KG</span>
                </div>
              </div>
            </div>
          </button>
          <button type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateOneTonLorry}" (click)="activateOneTonLorryButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                <img src="assets/icons/icons-lorry-blue.png" *ngIf="activateOneTonLorry == false" width="50" alt="" />
                <img src="assets/icons/icons-lorry-white.png" *ngIf="activateOneTonLorry == true" width="50" alt="" />
                <span class="card-title">Lorry (1 Ton)</span>
                <div class="flex-column card-small-label-wrapper">
                  <span class="card-small-label">(275cm x 152cm x 152cm)</span>
                  <span class="card-small-label card-weight-label">1000 KG</span>
                </div>
              </div>
            </div>
          </button>
          <button type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateThreeTonLorry}" (click)="activateThreeTonLorryButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                <img src="assets/icons/icons-lorry-blue.png" *ngIf="activateThreeTonLorry == false" width="50" alt="" />
                <img src="assets/icons/icons-lorry-white.png" *ngIf="activateThreeTonLorry == true" width="50" alt="" />
                <span class="card-title">Lorry (3 Ton)</span>
                <div class="flex-column card-small-label-wrapper">
                  <span class="card-small-label">(427cm x 220cm x 213cm)</span>
                  <span class="card-small-label card-weight-label">3000 KG</span>
                </div>
              </div>
            </div>
          </button>
          <button type="button" class="btn btn-outline-primary vehicle-button" [ngClass]="{'active' : activateChilledLorry}" (click)="activateChilledLorryButton()" name="button">
            <div>
              <div class="d-flex h-100 flex-column justify-content-center align-items-center">
                <img src="assets/icons/icons-frozen-lorry-blue.png" *ngIf="activateChilledLorry == false" width="50" alt="" />
                <img src="assets/icons/icons-frozen-lorry-white.png" *ngIf="activateChilledLorry == true" width="50" alt="" />
                <span class="card-title">Chilled Lorry</span>
                <div class="flex-column card-small-label-wrapper">
                  <span class="card-small-label">(275cm x 152cm x 125cm)</span>
                  <span class="card-small-label card-weight-label">1000 KG</span>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div> -->
    <!-- <div class="row mt-2">
      <div class="col-md-12">
        <div class="alert bg-green text-light">
          <p *ngIf="activateExpressDelivery">Urgent Delivery and it completed the delivery in 3 hours within same city.</p>
          <p *ngIf="activateMultiDelivery">Is the delivery type of who wish to delivery to multiple location. Delivery will complete in same day.</p>
        </div>
      </div>
    </div> -->
    <!-- <div class="row mt-4 mb-4">
      <div class="col-md-2">
        <label class="label-control font-weight-bold" for="">Total Weight</label>
      </div>
      <div class="col-md-2">
        <input type="text" class="form-control" name="" value="" (keyup)="keyUpTotalWeight($event)" placeholder="0 kg" />
      </div>
      <div class="offset-md-1 col-md-2 text-right">
        <button type="button" class="btn btn-custom-hover btn-success" name="button" [routerLink]="['/main/delivery/single/mode/csv/']">Import Address</button>
      </div>
    </div> -->
    <p>
      <span class="mr-3 step-span">Step 2</span>
      <span class="text-muted">Tell us where to go</span>
    </p>
    <hr />

    <div class="row">
      <div class="col-md-6">
        <!-- Pick Up Point -->
        <i class="fas fa-dot-circle point-circle pick-up"></i>
        <div class="delivery-form-decoration">
          <h3>Pick Up Point</h3>
          <div class="form-group row">
            <div class="col-md-6">
              <label class="label-control" for="">Booking Schedule Date</label>
              <input type="datetime-local" class="form-control" name="" value="" />
            </div>
            <div class="col-md-1 text-center">
              <label class="label-control" for="">&nbsp;</label>
              <div class="h-100" style="line-height: 2;">
                <span>or</span>
              </div>
            </div>
            <div class="col-md-5">
              <label class="label-control" for="">&nbsp;</label>
              <button type="button" class="btn btn-block btn-primary" name="button">Deliver Now</button>
            </div>

            <!-- <div class="col-md-3">
              <label class="label-control" for="">Booking Schedule Date</label>
            </div> -->
            <!-- <div class="col-md-4">
              <input type="datetime-local" class="form-control" name="" value="" />
            </div> -->
          </div>
          <div class="row">
            <div class="col-md-4">
              <label class="label-control" for="">Pick Up Address <span class="text-danger">*</span></label>
            </div>
            <div class="col-md-8 input-group">
              <input type="text" class="form-control" name="" value="" placeholder="Street / Postcode / Landmark" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary form-control-button" type="button">
                  <i class="fas fa-map-marker-alt"></i>
                </button>
                <button class="btn btn-outline-secondary form-control-button" type="button">
                  <i class="fa fa-heart text-danger"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <label class="label-control" for="">Address Details</label>
              <input type="text" class="form-control" name="" value="" placeholder="Apartment name, Unit number, Landmark, Instructions, etc" />
            </div>
            <!-- <div class="col-md-3">
              <label class="label-control" for="">Address Details</label>
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" name="" value="" placeholder="Apartment name, Unit number, Landmark, Instructions, etc" />
            </div> -->
          </div>
          <!-- <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Postcode</label>
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" name="" value="" placeholder="Postcode" />
            </div>
          </div> -->
          <div class="form-group row">
            <div class="col-md-6">
              <label class="label-control" for="">Sender Mobile Number <span class="text-danger">*</span></label>
              <!-- <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                </div>
                <input type="text" class="form-control" name="" value="" />
              </div> -->
              <div class="input-prefix">
  							<span class="prefix text-muted">601</span>
                  <input type="text" class="form-control input-field mb-3" name="" value="" />
              </div>
            </div>
            <div class="col-md-6">
              <label class="label-control" for="">Sender Name</label>
              <input type="text" class="form-control" name="" value="" />
            </div>
          </div>
          <!-- <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Sender Name</label>
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" name="" value="" placeholder="Sender Name" />
            </div>
          </div> -->
          <!-- <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Sender Mobile Number</label>
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" name="" value="" placeholder="Sender Mobile Number" />
            </div>
          </div> -->
        </div>


        <!-- TODO -->
      </div>

      <div class="col-md-6">
        <div class="w-100 text-center" style="height: 300px;background-color: #AAA;border-radius: 5px;padding-top: 25%;">
          Map
        </div>
      </div>
    </div>

    <div class="row">
      <div [ngClass]="{'col-md-12': activateMultiDelivery, 'col-md-6': activateExpressDelivery}">
        <!-- Delivery Point -->
        <i class="fas fa-dot-circle point-circle deliver"></i>
        <div class="delivery-form-decoration">
          <div class="row">
            <div [ngClass]="{'col-md-6': activateExpressDelivery, 'col-md-3': activateMultiDelivery}">
              <h3>Delivery Point</h3>
            </div>
            <div class="text-right" [ngClass]="{'col-md-6': activateExpressDelivery, 'col-md-3': activateMultiDelivery, 'pr-4': activateMultiDelivery}">
              <button type="button" class="btn btn-info" name="button">Import Address</button>
            </div>
          </div>

          <div *ngIf="activateExpressDelivery">
            <div class="row">
              <div class="col-md-4">
                <label class="label-control" for="">Delivery address <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-8 input-group">
                <input type="text" class="form-control" name="" value="" placeholder="Street / Postcode / Landmark" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary form-control-button" type="button">
                    <i class="fas fa-map-marker-alt"></i>
                  </button>
                  <button class="btn btn-outline-secondary form-control-button" type="button">
                    <i class="fas fa-heart text-danger"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="label-control" for="">Address Details</label>
                <input type="text" class="form-control" name="" value="" placeholder="Apartment name, Unit number, Landmark, Instructions, etc" />
              </div>
              <!-- <div class="col-md-3">
                <label class="label-control" for="">Address Details</label>
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" name="" value="" placeholder="Apartment name, Unit number, Landmark, Instructions, etc" />
              </div> -->
            </div>
            <!-- <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Postcode</label>
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" name="" value="" placeholder="Postcode" />
              </div>
            </div> -->
            <div class="form-group row">
              <div class="col-md-6">
                <label class="label-control" for="">Recipient Mobile Number</label>
                <!-- <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                  </div>
                  <input type="text" class="form-control" name="" value="" />
                </div> -->
                <div class="input-prefix">
    							<span class="prefix text-muted">601</span>
                    <input type="text" class="form-control input-field mb-3" name="" value="" />
                </div>
              </div>
              <div class="col-md-6">
                <label class="label-control" for="">Recipient Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" name="" value="" />
              </div>
            </div>
            <!-- <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Recipient Name</label>
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" name="" value="" placeholder="Recipient Name" />
              </div>
            </div> -->
            <!-- <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Recipient Mobile Number</label>
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" name="" value="" placeholder="Recipient Mobile Number" />
              </div>
            </div> -->

            <!-- <div class="form-group row">
              <div class="col-md-4 offset-md-3">
                <button type="button" class="btn btn-default text-danger" name="button">
                  <i class="fa fa-minus"></i>
                  Remove Address
                </button>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4 offset-md-3">
                <button type="button" class="btn btn-primary" name="button">Add Delivery Point</button>
              </div>
            </div> -->
          </div>

          <!-- Show only Multi Delivery is chosen -->
          <div *ngIf="activateMultiDelivery">
            <table class="table table-sm" style="width: 100%;table-layout: fixed;">
              <thead>
                <tr>
                  <th width="21%">Delivery Address <span class="text-danger">*</span></th>
                  <th width="15%">Address Details <span class="text-danger">*</span></th>
                  <!-- <th width="10%">Postcode</th> -->
                  <th width="15%">Recipient Name</th>
                  <th width="15%">Mobile No. <span class="text-danger">*</span></th>
                  <th width="7%">Qty <span class="text-danger">*</span></th>
                  <th width="11%">Weight/item <span class="text-danger">*</span></th>
                  <th width="10%">Price</th>
                  <th class="nowrap" width="6%"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="input-group">
                      <input type="text" class="form-control" name="" value="" placeholder="Address Details" />
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary btn-sm form-control-button" type="button">
                          <i class="fas fa-map-marker-alt"></i>
                        </button>
                        <button class="btn btn-outline-secondary form-control-button btn-sm" type="button">
                          <i class="fa fa-heart text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input type="text" class="form-control" name="" value="" placeholder="Apartment name, unit no, landmark" />
                  </td>
                  <!-- <td>
                    <input type="text" class="form-control" name="" value="" placeholder="Postcode" />
                  </td> -->
                  <td>
                    <input type="text" class="form-control" name="" value="" />
                  </td>
                  <td>
                    <!-- <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                      </div>
                      <input type="text" class="form-control" name="" />
                    </div> -->
                    <div class="input-prefix">
        							<span class="prefix text-muted">601</span>
                        <input type="text" class="form-control input-field mb-3" name="" value="" />
                    </div>
                  </td>
                  <td>
                    <select class="form-control" name="">
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                      <option value="">4</option>
                      <option value="">5</option>
                      <option value="">6</option>
                      <option value="">7</option>
                      <option value="">8</option>
                      <option value="">9</option>
                      <option value="">10</option>
                    </select>
                  </td>
                  <td>
                    <select class="form-control" name="">
                      <option>&lt; 1kg</option>
                      <option>&lt; 5kg</option>
                      <option>&lt; 20kg</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" class="form-control" name="" value="" disabled />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div class="input-group">
                      <input type="text" class="form-control" name="" value="" placeholder="Address Details" />
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary btn-sm form-control-button" type="button">
                          <i class="fas fa-map-marker-alt"></i>
                        </button>
                        <button class="btn btn-outline-secondary btn-sm form-control-button" type="button">
                          <i class="fa fa-heart text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input type="text" class="form-control" name="" value="" placeholder="Apartment name, unit no, landmark" />
                  </td>
                  <!-- <td>
                    <input type="text" class="form-control" name="" value="" placeholder="Postcode" />
                  </td> -->
                  <td>
                    <input type="text" class="form-control" name="" value="" />
                  </td>
                  <td>
                    <!-- <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                      </div>
                      <input type="text" class="form-control" name="" />
                    </div> -->
                    <div class="input-prefix">
        							<span class="prefix text-muted">601</span>
                        <input type="text" class="form-control input-field mb-3" name="" value="" />
                    </div>
                  </td>
                  <td>
                    <select class="form-control" name="">
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                      <option value="">4</option>
                      <option value="">5</option>
                      <option value="">6</option>
                      <option value="">7</option>
                      <option value="">8</option>
                      <option value="">9</option>
                      <option value="">10</option>
                    </select>
                  </td>
                  <td>
                    <select class="form-control" name="">
                      <option>&lt; 1kg</option>
                      <option>&lt; 5kg</option>
                      <option>&lt; 20kg</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" class="form-control" name="" value="" disabled />
                  </td>
                  <td><button type="button" class="btn btn-danger btn-sm" name="button"><i class="fa fa-times"></i></button></td>
                </tr>
              </tbody>
            </table>
            <div class="form-group row">
              <div class="col-md-12">
                <button type="button" class="btn btn-primary" name="button">
                  <!-- <i class="fa fa-plus"></i> -->
                  <span> Add Delivery Point</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row"><div class="col-md-12"><hr /></div></div>
        <div class="row" *ngIf="activateExpressDelivery">
          <div class="col-md-6">
            <button type="button" class="btn btn-primary" name="button">Add Delivery Point</button>
          </div>
          <div class="col-md-6 text-right">
            <button type="button" class="btn btn-default text-danger" name="button">
              <i class="fa fa-times"></i>
              Remove Address
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span class="text-danger">( * ) indicate Mandatory field</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <!-- Stock Information -->
        <div class="delivery-stock-decoration">
          <!-- <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Parcel Type</label>
            </div>
            <div class="col-md-4">
              <select class="form-control" name="">
                <option value="">Document</option>
                <option value="">Food &amp; Beverages</option>
                <option value="">Gift</option>
                <option value="">Groceries</option>
                <option value="">Flower</option>
                <option value="">Cake</option>
                <option value="" selected>Other..</option>
              </select>
            </div>
            <div class="col-md-4">
              <input type="text" class="form-control" name="" value="" placeholder="Specific your category" />
            </div>
          </div> -->
          <!-- <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Preference</label>
            </div>
            <div class="col-md-4">
              <div class="">
                <label class="label-control" for=""><input type="checkbox" name="" value=""> Non Halal</label>
              </div>
            </div>
          </div> -->
          <!-- <div class="form-group row">
            <div class="col-md-3">
              <label class="label-control" for="">Additional Services</label>
            </div>
            <div class="col-md-4">
              <div class="">
                <label for=""><input type="checkbox" name="" value=""> Payment Collection Service</label>
              </div>

              <div class="">
                <label for=""><input type="checkbox" name="" value=""> Return Goods/D.O.</label>
              </div>

              <div class="">
                <label for=""><input type="checkbox" name="" value=""> Door-to-Door Service &amp; Car Park Fee</label>
              </div>

              <div class="">
                <label for=""><input type="checkbox" name="" value=""> Door-to-Door Service + 1 Helper</label>
              </div>

            </div>

            <div class="col-md-4">
            </div>
          </div> -->
          <!-- <div class="form-group row">
            <div class="col-md-4 offset-md-3">
              <small class="text-danger font-italic">* Max. distance 30KM, +RM5/motorcycle, +RM10/car&amp;van, next within 45KM</small>
            </div>
          </div> -->
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <label class="label-control" for="">Notes to Your Driver:</label>
            <textarea name="name" class="form-control" rows="3" cols="80" placeholder="e.g. Call me before reach to avoid waiting time. Thank you"></textarea>
          </div>
        </div>

        <!-- <div class="form-group">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <label class="label-control" for="">Instruction Notes</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <textarea name="name" class="form-control" rows="3" cols="80"></textarea>
                </div>
                <div class="col-md-4">
                  <img src="https://i.stack.imgur.com/1XPRz.png" style="width: 100%;object-fit: contain;" alt="" />
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12 order-md-2 mb-3">
                          <h4 class="d-flex justify-content-between align-items-center mb-3">
                              <span class="text-muted">Your cart</span>
                              <span class="badge badge-secondary badge-pill">3</span>
                          </h4>
                          <ul class="list-group sticky-top">
                              <li class="list-group-item d-flex justify-content-between lh-condensed">
                                  <div>
                                      <h6 class="my-0">1</h6>
                                      <small class="text-muted">2</small>
                                  </div>
                                  <span class="text-muted">3 4</span>
                              </li>
                              <li class="list-group-item d-flex justify-content-between lh-condensed">
                                  <div>
                                      <h6 class="my-0">Second product</h6>
                                      <small class="text-muted">Brief description</small>
                                  </div>
                                  <span class="text-muted">$8</span>
                              </li>
                              <li class="list-group-item d-flex justify-content-between lh-condensed">
                                  <div>
                                      <h6 class="my-0">Third item</h6>
                                      <small class="text-muted">Brief description</small>
                                  </div>
                                  <span class="text-muted">$5</span>
                              </li>
                              <li class="list-group-item d-flex justify-content-between bg-light">
                                  <div class="text-success">
                                      <h6 class="my-0">Promo code</h6>
                                      <small>EXAMPLECODE</small>
                                  </div>
                                  <span class="text-success">-$5</span>
                              </li>
                              <li class="list-group-item d-flex justify-content-between">
                                  <span>Total (USD)</span>
                                  <strong>$20</strong>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <form class="card p-2">
                          <div class="input-group">
                              <input type="text" class="form-control" placeholder="Promo code">
                              <div class="input-group-append">
                                  <button type="submit" class="btn btn-secondary">Redeem</button>
                              </div>
                          </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="form-group row"> -->
          <!-- <div class="col-md-12 text-left"> -->
            <!-- <button type="button" data-target="#instantSubmitOrderModal" data-toggle="modal" class="btn btn-primary btn-round mr-2" name="button">Submit Order</button> -->
            <!-- <button type="button" [routerLink]="['/main/order/checkout/']" class="btn btn-primary btn-round mr-2" name="button">Submit Order</button> -->
            <!-- <button type="button" [routerLink]="['/main/order/list/']" class="btn btn-success btn-round" name="button">Manage Orders</button> -->
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </form>
</div>

<div class="modal fade" id="instantSubmitOrderModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content" >
      <div class="modal-header justify-content-start" style="background-color: #0089CF;color: white;">
        <button type="button" class="btn btn-default" style="color: white;" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">
          <span *ngIf="activateExpressDelivery">Express Delivery</span>
          <span *ngIf="activateMultiDelivery">MultiStop Delivery</span>
        </h4>
      </div>
      <div class="modal-body" style=" padding-left:25px; padding-right:25px; padding-bottom:20px;">
        <div *ngIf="activateExpressDelivery">
          <img src="assets/img/3popimg-parcel-express.jpg" alt="parcel-express" />
        </div>
        <div *ngIf="activateMultiDelivery">
          <img src="assets/img/3popimg-parcel-multiStop.jpg" alt="parcel-multiStop" />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-round mr-2" name="button">Back to order</button>
        <button type="button" class="btn btn-primary btn-round mr-2" name="button">Submit order</button>
      </div>
    </div>
  </div>
</div>
