<div class="content col-md-9 col-lg-12 pt-3">
  <h2>Track My Order</h2>
  <hr />

  <form class="" action="index.html" method="post">
    <div class="form-group row">
      <div class="offset-md-2 col-md-8 text-center">
        <label class="font-weight-bold" style="font-size: 18px;" for="">Enter Tracking Number</label>
        <div class="col-md-12 input-group">
          <input type="text" class="form-control" name="" value="" placeholder="E.g. ATC101120200001" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary form-control-button" type="button">
              <i class="fa fa-search"></i>
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="container h-100 mt-5">
    <div class="row">
      <div class="col-md-8 offset-md-2 py-3 px-5 tracking-wrapper">
        <div class="row">
          <div class="col-md-12">
            <span class="font-weight-bold tracking-box-title">Tracking History</span>
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="" *ngFor="let data of orderList">
              <i class="fas fa-dot-circle tracking-point-circle"></i>
              <div class="tracking-decoration">
                <span class="tracking-title">{{ data.orderStatus }}</span>
                <span class="tracking-subtitle text-muted">{{ data.orderTime }}</span>
              </div>
            </div>

            <!-- <div class="">
              <i class="fas fa-dot-circle tracking-point-circle"></i>
              <div class="tracking-decoration">
                <span class="tracking-title">Parcel is being delivered!</span>
                <span class="tracking-subtitle text-muted">24-11-2020, 02:48 p.m.</span>
              </div>
            </div>

            <div class="">
              <i class="fas fa-dot-circle tracking-point-circle"></i>
              <div class="tracking-decoration">
                <span class="tracking-title">Departed Arriival warehouse!</span>
                <span class="tracking-subtitle text-muted">22-11-2020, 03:22 p.m.</span>
              </div>
            </div>

            <div class="">
              <i class="fas fa-dot-circle tracking-point-circle"></i>
              <div class="tracking-decoration">
                <span class="tracking-title">Departed Kuala Lumpur!</span>
                <span class="tracking-subtitle text-muted">21-11-2020, 10:13 a.m.</span>
              </div>
            </div>

            <div class="">
              <i class="fas fa-dot-circle tracking-point-circle"></i>
              <div class="tracking-decoration">
                <span class="tracking-title">Order created</span>
                <span class="tracking-subtitle text-muted">20-11-2020, 01:36 p.m.</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>