<div class="content col-mg-9 col-lg-12 pt-3">
  <h2>Favourite</h2>
  <hr />

  <div class="modal fade" id="favouriteModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Create New Favourite</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <form class="" action="index.html" method="post">
            <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Name</label>
              </div>
              <div class="col-md-9">
                <input type="text" id="name" #name class="form-control" name="address_name"
                  [(ngModel)]="address_name" />
              </div>
            </div>
            <!-- <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Address</label>
              </div>
              <div class="col-md-9">
                <input type="text" id="address" #address class="form-control" name="address_address"
                  [(ngModel)]="address_address" />
              </div>
            </div> -->

            <div class="form-group">
              <label>Enter address</label>
              <input type="text" class="form-control" name="displayAddress" [(ngModel)]="displayAddress"
                (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off"
                autocapitalize="off" spellcheck="off" type="text" #search>
            </div>

            <div class="form-group row">
              <div class="col">
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                  <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                    (dragEnd)="markerDragEnd($event, 'add')"></agm-marker>
                </agm-map>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Mobile</label>
              </div>
              <div class="col-md-9">
                <input type="text" id="mobile" #mobile class="form-control" name="address_mobile"
                  [(ngModel)]="address_mobile" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Area Code</label>
              </div>
              <div class="col-md-9">
                <input type="text" id="areaCode" #areaCode class="form-control" name="address_area_code"
                  [(ngModel)]="address_area_code" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Address Door</label>
              </div>
              <div class="col-md-9">
                <input type="text" id="addressDoor" #addressDoor class="form-control" name="address_door"
                  [(ngModel)]="address_door" />
              </div>
            </div>
          </form>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="addNewAddress()" data-dismiss="modal">Add</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="editFavouriteModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Edit Favourite</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <form class="" action="index.html" method="post">
            <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Name</label>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" name="address_name" [(ngModel)]="address_name" />
              </div>
            </div>
            <!-- <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Address</label>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" name="address_address" [(ngModel)]="address_address" />
              </div>
            </div> -->
            <div class="form-group">
              <label>Enter address</label>
              <input type="text" class="form-control" name="editDisplayAddress" [(ngModel)]="editDisplayAddress"
                (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off"
                autocapitalize="off" spellcheck="off" type="text" #editsearch>
            </div>

            <div class="form-group row">
              <div class="col">
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                  <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                    (dragEnd)="markerDragEnd($event, 'edit')"></agm-marker>
                </agm-map>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Mobile</label>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" name="address_mobile" [(ngModel)]="address_mobile" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Area Code</label>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" name="address_area_code" [(ngModel)]="address_area_code" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="label-control" for="">Address Door</label>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" name="address_door" [(ngModel)]="address_door" />
              </div>
            </div>
          </form>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="updateAddress()" data-dismiss="modal">Edit</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="deleteFavouriteModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Delete Favourite</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          Are you sure want to delete this address?
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="deleteAddress()" data-dismiss="modal">Confirm</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <button type="button" data-toggle="modal" data-target="#favouriteModal" (click)="clearModal()"
        class="btn btn-sm btn-success" name="button"><i class="fa fa-plus"></i> Create New</button>
      <!-- <button type="button" (click)="clearModal()"
        class="btn btn-sm btn-success" name="button"><i class="fa fa-plus"></i> Create New</button> -->
    </div>
  </div>

  <!-- <div class="form-group">
    <label>Enter address</label>
    <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
      placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
  </div>

  <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
      (dragEnd)="markerDragEnd($event)"></agm-marker>
  </agm-map> -->

  <div class="row mt-5">
    <div class="col-md-4" *ngFor="let addr of my_address">
      <div class="card">
        <div class="card-header">
          <span style="font-size: 20px;vertical-align: middle;">{{ addr.addressName }}</span>
          <button type="button" data-toggle="modal" data-target="#deleteFavouriteModal" [attr.data-id]="addr.addressId"
            (click)="showDeleteModal($event)" class=" btn btn-sm btn-danger float-right" name="button"><i
              class="fa fa-trash-alt"></i> Delete</button>
          <button type="button" data-toggle="modal" data-target="#editFavouriteModal" [attr.data-id]="addr.addressId"
            [attr.data-lat]="addr.addressLat" [attr.data-lng]="addr.addressLng" [attr.data-name]="addr.addressName"
            [attr.data-address]="addr.addressAddress" [attr.data-mobile]="addr.addressPhone"
            [attr.data-areaCode]="addr.addressAreaCode" [attr.data-addressDoor]="addr.addressAddressDoor"
            (click)="showEditModal($event)" class=" btn btn-sm btn-primary float-right" name="button"><i
              class="fa fa-pencil-alt"></i> Edit</button>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <span>Address: </span>
            </div>
            <div class="col-md-8">
              <span>{{ addr.addressAddress }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <span>Mobile: </span>
            </div>
            <div class="col-md-8">
              <span>{{ addr.addressPhone }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <span>Area Code: </span>
            </div>
            <div class="col-md-8">
              <span>{{ addr.addressAreaCode }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <span>Address Door: </span>
            </div>
            <div class="col-md-8">
              <span>{{ addr.addressAddressDoor }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>