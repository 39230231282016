<div class="modal-header">
    <h3 class="modal-title">Angular Google Maps with Places Search Example</h3>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="form-group">
        <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
            placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
            #search>
    </div>

    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)"></agm-marker>
    </agm-map>

    <h5>Address: {{address}}</h5>
    <div>Latitude: {{latitude}}</div>
    <div>Longitude: {{longitude}}</div>


</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    <button type="button" class="btn btn-primary" (click)="saveLocation()">Save Location</button>
</div>