import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  @ViewChild('noprint', {static: false}) noprint:ElementRef;

  invoiceData:any[] = [{
    "dateRange": "01/02/2020 ~ 29/02/2020",
    "currency": "RM",
    "total": "500.00",
    "invoices": [
      {
        "dateTime": "2020-02-26 09:31:33",
        "invoiceNo": "CR-200009",
        "description": "Top up credit",
        "amount": "500.00"
      },
      {
        "dateTime": "2020-02-26 09:31:33",
        "invoiceNo": "CR-200008",
        "description": "Top up credit",
        "amount": "440.00"
      }
    ]
  }];
  public page:number = 1;
  public pageSize:number = 20;

  constructor() { }

  ngOnInit(): void {
  }

  generatePDF() {
    var data = document.getElementById('contentToConvert');
    this.noprint.nativeElement.style.display = "none";
    html2canvas(data).then(canvas => {
      var imgWidth = 188;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var paddingLeft = 10;
      var paddingTop = 10;
      pdf.addImage(contentDataURL, 'PNG', paddingTop, paddingLeft, imgWidth, imgHeight)
      pdf.save('arriival_invoice.pdf');
    });
    this.noprint.nativeElement.style.display = "block";
  }

}
