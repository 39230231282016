import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, first } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  public uploadBatchNo: string;
  public serviceId: string;
  public secretKey = 'hZMTVHXf6XmRNG2hJjk9WmzRfQvpUowE';
  public response: any;

  constructor(public _http: HttpClient) { }

  ngOnInit(): void {
    this.readOrder();
  }

  readOrder() {
    this.uploadBatchNo = localStorage.getItem('upload_batch_no');
    this.serviceId = localStorage.getItem('service_id');

    this._http.get(`https://api.int.janio.asia/api/order/order/?secret_key=${this.secretKey}&with_items=true&service_id=${this.serviceId}&upload_batch_no=${this.uploadBatchNo}`).pipe(
      catchError((err: HttpErrorResponse) => {
          return throwError(
              'Something bad happened; please try again later.');
      }) // then handle the error
    ).subscribe((respObj) => {
        this.response = respObj['results'][0];
    });
  }

}
