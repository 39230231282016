<div class="d-flex flex-row">
  <div class="d-flex flex-column mt-5 py-3 mr-5 category-box">
    <h4 class="text-center">Product Category</h4>
    <ul class="category-list">
      <li class="category-item">
        <a href="#" class="category-link">Category 1</a>
        <ul>
          <li class="category-item">
            <a href="#" class="category-link">Sub Category 1</a>
          </li>
        </ul>
      </li>
      <li class="category-item">
        <a href="#" class="category-link">Category 2</a>
      </li>
      <li class="category-item">
        <a href="#" class="category-link">Category 3</a>
      </li>
      <li class="category-item">
        <a href="#" class="category-link">Category 4</a>
      </li>
      <li class="category-item">
        <a href="#" class="category-link">Category 5</a>
      </li>
      <li class="category-item">
        <a href="#" class="category-link">Category 6</a>
      </li>
    </ul>
  </div>

  <div class="d-flex flex-row flex-wrap mt-5 mr-5 product-box">
    <figure class="card card-product m-2">
      <div class="img-wrap">
        <img src="https://i1.adis.ws/i/canon/EOS-r5_Martin_Bissig_Lifestyle_hero-e90f9dd2-be19-11ea-b23c-8c04ba195b5f?w=100%&sm=aspect&aspect=16:9&qlt=80&fmt=jpg&fmt.options=interlaced&bg=rgb(255,255,255)" alt="Product Name" />
      </div>
      <figcaption class="info-wrap">
          <h4 class="title">Name of item</h4>
          <p class="desc">Some small description goes here</p>
          <div class="rating-wrap">
            <div class="label-rating">132 reviews</div>
            <div class="label-rating">154 orders </div>
          </div> <!-- rating-wrap.// -->
      </figcaption>
      <div class="bottom-wrap">
        <a href="" class="btn btn-sm btn-primary float-right">Order Now</a>
        <div class="price-wrap h5">
          <span class="price-new">$1280</span> <del class="price-old">$1980</del>
        </div> <!-- price-wrap.// -->
      </div> <!-- bottom-wrap.// -->
    </figure>

    <figure class="card card-product m-2">
      <div class="img-wrap">
        <img src="https://i1.adis.ws/i/canon/EOS-r5_Martin_Bissig_Lifestyle_hero-e90f9dd2-be19-11ea-b23c-8c04ba195b5f?w=100%&sm=aspect&aspect=16:9&qlt=80&fmt=jpg&fmt.options=interlaced&bg=rgb(255,255,255)" alt="Product Name" />
      </div>
      <figcaption class="info-wrap">
          <h4 class="title">Another name of item</h4>
          <p class="desc">Some small description goes here</p>
          <div class="rating-wrap">
            <div class="label-rating">132 reviews</div>
            <div class="label-rating">154 orders </div>
          </div> <!-- rating-wrap.// -->
      </figcaption>
      <div class="bottom-wrap">
        <a href="" class="btn btn-sm btn-primary float-right">Order Now</a>
        <div class="price-wrap h5">
          <span class="price-new">$1280</span> <del class="price-old">$1980</del>
        </div> <!-- price-wrap.// -->
      </div> <!-- bottom-wrap.// -->
    </figure>

    <figure class="card card-product m-2">
      <div class="img-wrap">
        <img src="https://i1.adis.ws/i/canon/EOS-r5_Martin_Bissig_Lifestyle_hero-e90f9dd2-be19-11ea-b23c-8c04ba195b5f?w=100%&sm=aspect&aspect=16:9&qlt=80&fmt=jpg&fmt.options=interlaced&bg=rgb(255,255,255)" alt="Product Name" />
      </div>
      <figcaption class="info-wrap">
          <h4 class="title">Another name of item</h4>
          <p class="desc">Some small description goes here</p>
          <div class="rating-wrap">
            <div class="label-rating">132 reviews</div>
            <div class="label-rating">154 orders </div>
          </div> <!-- rating-wrap.// -->
      </figcaption>
      <div class="bottom-wrap">
        <a href="" class="btn btn-sm btn-primary float-right">Order Now</a>
        <div class="price-wrap h5">
          <span class="price-new">$1280</span> <del class="price-old">$1980</del>
        </div> <!-- price-wrap.// -->
      </div> <!-- bottom-wrap.// -->
    </figure>

    <figure class="card card-product m-2">
      <div class="img-wrap">
        <img src="https://i1.adis.ws/i/canon/EOS-r5_Martin_Bissig_Lifestyle_hero-e90f9dd2-be19-11ea-b23c-8c04ba195b5f?w=100%&sm=aspect&aspect=16:9&qlt=80&fmt=jpg&fmt.options=interlaced&bg=rgb(255,255,255)" alt="Product Name" />
      </div>
      <figcaption class="info-wrap">
          <h4 class="title">Another name of item</h4>
          <p class="desc">Some small description goes here</p>
          <div class="rating-wrap">
            <div class="label-rating">132 reviews</div>
            <div class="label-rating">154 orders </div>
          </div> <!-- rating-wrap.// -->
      </figcaption>
      <div class="bottom-wrap">
        <a href="" class="btn btn-sm btn-primary float-right">Order Now</a>
        <div class="price-wrap h5">
          <span class="price-new">$1280</span> <del class="price-old">$1980</del>
        </div> <!-- price-wrap.// -->
      </div> <!-- bottom-wrap.// -->
    </figure>

    <figure class="card card-product m-2">
      <div class="img-wrap">
        <img src="https://i1.adis.ws/i/canon/EOS-r5_Martin_Bissig_Lifestyle_hero-e90f9dd2-be19-11ea-b23c-8c04ba195b5f?w=100%&sm=aspect&aspect=16:9&qlt=80&fmt=jpg&fmt.options=interlaced&bg=rgb(255,255,255)" alt="Product Name" />
      </div>
      <figcaption class="info-wrap">
          <h4 class="title">Another name of item</h4>
          <p class="desc">Some small description goes here</p>
          <div class="rating-wrap">
            <div class="label-rating">132 reviews</div>
            <div class="label-rating">154 orders </div>
          </div> <!-- rating-wrap.// -->
      </figcaption>
      <div class="bottom-wrap">
        <a href="" class="btn btn-sm btn-primary float-right">Order Now</a>
        <div class="price-wrap h5">
          <span class="price-new">$1280</span> <del class="price-old">$1980</del>
        </div> <!-- price-wrap.// -->
      </div> <!-- bottom-wrap.// -->
    </figure>
  </div>
</div>
