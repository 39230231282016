<div class="content col-mg-9 col-lg-12 pt-3">
  <h2>Drafts</h2>
  <small class="text-muted">Save your booking details as draft and schedule your booking orders.</small>
  <hr />

  <div class="row mt-5">
    <div class="col-md-4" *ngFor="let cardData of cardDataset">
      <div class="card">
        <div class="card-header">
          <span style="font-size: 20px;vertical-align: middle;">{{ cardData.name }}</span>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <span>Date: </span>
            </div>
            <div class="col-md-8">
              <span>{{ cardData.date }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <span>Time: </span>
            </div>
            <div class="col-md-8">
              <span>{{ cardData.time }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <span>Address: </span>
            </div>
            <div class="col-md-8">
              <span>{{ cardData.address }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <span>Mobile: </span>
            </div>
            <div class="col-md-8">
              <span>{{ cardData.mobile }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <span>Email: </span>
            </div>
            <div class="col-md-8">
              <span>{{ cardData.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
