import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, retry, first } from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  public loginName: string = "";
  public mobileField: string = "";
  public smsCode: string = "";
  public userPassword: string = "";
  public confirmUserPassword: string = "";
  public userCurrentArea: string = "";

  constructor(public _http: HttpClient) {}

  ngOnInit(): void {}

  getCode() {
    if (this.mobileField.trim() == "") {
      alert("Please enter your mobile number");
    } else {
//      this._http
//        .get(
//          `http://uml.arriival.com/qmcs-u-api/sms/verifyingSMS?loginName=${this.loginName}&telPhone=60${this.mobileField}&verificationType=2&smsType=1`
//        )
//        .pipe(
//          catchError((err: HttpErrorResponse) => {
//            return throwError(
//              "Something bad happened; please try again later."
//            );
//          }) // then handle the error
//        )
//        .subscribe((respObj) => {
//          console.log(respObj);
//          if (respObj["code"] == 200) {
//            // proceed to register
//          } else {
//            alert(respObj["enMsg"]);
//          }
//        });
    }
  }

  registerAccount() {
    if (this.smsCode.trim() == "") {
      alert("Please enter SMS code");
    } else {
//      this._http
//        .get(
//          `http://uml.arriival.com/qmcs-u-api/sms/checkCode?telephone=60${this.mobileField}&appType=USER&verificationType=2&smsCode=${this.smsCode}`
//        )
//        .pipe(
//          catchError((err: HttpErrorResponse) => {
//            return throwError(
//              "Something bad happened; please try again later."
//            );
//          }) // then handle the error
//        )
//        .subscribe((respObj) => {
//          console.log(respObj);
//          if (respObj["code"] == 200) {
//            // proceed to register
//            if (this.userPassword != this.confirmUserPassword) {
//              alert("User password and confirm password is not matched");
//            }
//            this._http
//              .post(
//                `http://uml.arriival.com/qmcs-u-api/register?userTelphone=60${this.mobileField}&smsCode=${this.smsCode}&verificationType=2&userPassword=${this.userPassword}&userCurrentArea=${this.userCurrentArea}`,
//                ""
//              )
//              .pipe(
//                catchError((err: HttpErrorResponse) => {
//                  return throwError(
//                    "Something bad happened; please try again later."
//                  );
//                }) // then handle the error
//              )
//              .subscribe((respObj) => {
//                console.log(respObj);
//                if (respObj["code"] == 200) {
//                  // proceed to register
//                } else {
//                  alert(respObj["enMsg"]);
//                }
//              });
//          } else {
//            alert(respObj["enMsg"]);
//          }
//        });
      // var obj = [];

      // obj["uuid"] = "1a0018970a14615f0d8";
      // obj["weight"] = 20;
      // obj["distance"] = 20;
      // obj["orderTransType"] = 0;
      // obj["type"] = 123;
      // obj["lng"] = 3.16403;
      // obj["lat"] = 101.61539;
      // this._http
      //   .post(`http://uml.arriival.com/qmcs-u-api/order/orderNewFee`, obj)
      //   .pipe(
      //     catchError((err: HttpErrorResponse) => {
      //       return throwError(
      //         "Something bad happened; please try again later."
      //       );
      //     }) // then handle the error
      //   )
      //   .subscribe((respObj) => {
      //     console.log(respObj);
      //     if (respObj["code"] == 200) {
      //       // proceed to register
      //     } else {
      //       alert(respObj["enMsg"]);
      //     }
      //   });
    }
  }
}
