import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest
} from "@angular/common/http";
import { catchError, retry, first } from "rxjs/operators";
import { throwError } from "rxjs";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from "@angular/router";

declare var $: any;

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
  @ViewChild('daterangepicker') input;

  cancelFilterArgs = { "status": "Cancelled" };
  show1Star:Boolean = false;
  show2Star:Boolean = false;
  show3Star:Boolean = false;
  show4Star:Boolean = false;
  show5Star:Boolean = false;
  star1:Boolean = false;
  star2:Boolean = false;
  star3:Boolean = false;
  star4:Boolean = false;
  star5:Boolean = false;

  datas: any[] = [{
    "id": "1",
    "checkbox": "<input type='checkbox' name='' value='' />",
    "orderDateTime": "10-11-2020 17:33:56",
    "trackingNo": "ATC101120200001",
    "contactNo": "+60136582047",
    "senderName": "Mary",
    "senderAddress": "SS5, Petaling Jaya, Selangor, Malaysia",
    "receiverName": "Colin",
    "receiverAddress": "SS2, Petaling Jaya, Selangor, Malaysia",
    "typeOfDelivery": "Express Delivery",
    "status": "Completed"
  }, {
    "id": "2",
    "checkbox": "<input type='checkbox' name='' value='' />",
    "orderDateTime": "10-11-2020 17:33:56",
    "trackingNo": "ATC101120200002",
    "contactNo": "+60173645284",
    "senderName": "Connie",
    "senderAddress": "SS1, Petaling Jaya, Selangor, Malaysia",
    "receiverName": "Amy",
    "receiverAddress": "SS6, Petaling Jaya, Selangor, Malaysia",
    "typeOfDelivery": "Express Delivery",
    "status": "Cancelled"
  }];

  orderDateTime: Boolean = true;
  trackingOrderNo: Boolean = true;
  senderNameAddress: Boolean = false;
  senderCountry: Boolean = false;
  receiverNameAddress: Boolean = true;
  receiverHPNo: Boolean = false;
  receiverCountry: Boolean = false;
  orderType: Boolean = true;
  orderStatus: Boolean = true;

  // Order List
  public orderList: Array<any> = [];
  public pendingOrderList: Array<any> = [];
  public completedDeliveryOrderList: Array<any> = [];
  public failedDeliveryOrderList: Array<any> = [];
  public inDeliveryOrderList: Array<any> = [];
  public cancelledOrderList: Array<any> = [];
  public orderPriceDetailList: Array<any> = [];
  public deliveryInformation: Array<any> = [];

  /**
   * Order List numbers
   */
  public allOrdersNumbers: any = 0;
  public orderPendingNumbers: any = 0;
  public orderCompletedNumbers: any = 0;
  public orderFailedNumbers: any = 0;
  public orderInDeliveryingNumbers: any = 0;
  public orderCancelledNumbers: any = 0;

  public isHayakuDeliveryList: Boolean = false;
  public isCJDeliveryList: Boolean = false;
  public isOrderJointDeliveryList: Boolean = false;
  public orderIsCompleted: Boolean = false;

  constructor(
    public _http: HttpClient,
    private router: Router,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getAllOrders();
  }

  ngAfterViewInit() {
    // var picker = $(this.input.nativeElement).daterangepicker({
    //   "parentEl": "#daterangepicker1-container",
    //   "autoApply": true,
    //   "linkedCalendars": false
    // });
    // // range update listener
    // picker.on('apply.daterangepicker', function(ev, picker) {
    //   //$("#daterangepicker-result").html('Selected date range: ' + picker.startDate.format('YYYY-MM-DD') + ' to ' + picker.endDate.format('YYYY-MM-DD'));
    // });
    // // prevent hide after range selection
    // picker.data('daterangepicker').hide = function () {};
    // // show picker on load
    // picker.data('daterangepicker').show();
  }

  activate1Star():void {
    if (!this.star1) {
      this.show1Star = true;
      this.show2Star = false;
      this.show3Star = false;
      this.show4Star = false;
      this.show5Star = false;
    }
  }

  activate2Star():void {
    if (!this.star2) {
      this.show1Star = false;
      this.show2Star = true;
      this.show3Star = false;
      this.show4Star = false;
      this.show5Star = false;
    }
  }

  activate3Star():void {
    if (!this.star3) {
      this.show1Star = false;
      this.show2Star = false;
      this.show3Star = true;
      this.show4Star = false;
      this.show5Star = false;
    }
  }

  activate4Star():void {
    if (!this.star4) {
      this.show1Star = false;
      this.show2Star = false;
      this.show3Star = false;
      this.show4Star = true;
      this.show5Star = false;
    }
  }

  activate5Star():void {
    if (!this.star5) {
      this.show1Star = false;
      this.show2Star = false;
      this.show3Star = false;
      this.show4Star = false;
      this.show5Star = true;
    }
  }
  onTap1Star():void {
    this.star1 = !this.star1;
    this.star2 = false;
    this.star3 = false;
    this.star4 = false;
    this.star5 = false;
  }
  onTap2Star():void {
    this.star1 = false;
    this.star2 = !this.star2;
    this.star3 = false;
    this.star4 = false;
    this.star5 = false;
  }
  onTap3Star():void {
    this.star1 = false;
    this.star2 = false;
    this.star3 = !this.star3;
    this.star4 = false;
    this.star5 = false;
  }
  onTap4Star():void {
    this.star1 = false;
    this.star2 = false;
    this.star3 = false;
    this.star4 = !this.star4;
    this.star5 = false;
  }
  onTap5Star():void {
    this.star1 = false;
    this.star2 = false;
    this.star3 = false;
    this.star4 = false;
    this.star5 = !this.star5;
  }
  clearStarCard():void {
    this.show1Star = false;
    this.show2Star = false;
    this.show3Star = false;
    this.show4Star = false;
    this.show5Star = false;
  }
  clearActivateStarCard():void {
    this.star1 = false;
    this.star2 = false;
    this.star3 = false;
    this.star4 = false;
    this.star5 = false;
  }

  getAllOrders() {
    var formData: any = new FormData();

    this.orderList = [];
    this.pendingOrderList = [];
    this.completedDeliveryOrderList = [];
    this.failedDeliveryOrderList = [];
    this.inDeliveryOrderList = [];
    this.cancelledOrderList = [];

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("type", 0);
    formData.append("limitStart", 1);
    formData.append("pageSize", 10);
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/order/selectOrderList `,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          console.log(respObj['data']);
//
//          this.orderList = respObj['data'];
//
//          respObj['data'].forEach(element => {
//            if (element['orderStatus'] == 7) {
//              this.orderPendingNumbers += 1;
//              this.pendingOrderList.push(element);
//            } else if (element['orderStatus'] == 8) {
//              this.orderCompletedNumbers += 1;
//              this.completedDeliveryOrderList.push(element);
//            } else if (element['orderStatus'] == 12 || element['orderStatus'] == 13 || element['orderStatus'] == 14) {
//              this.orderFailedNumbers += 1;
//              this.failedDeliveryOrderList.push(element);
//            } else if (element['orderStatus'] == 7) {
//              this.orderInDeliveryingNumbers += 1;
//              this.inDeliveryOrderList.push(element);
//            } else if (element['orderStatus'] == 10 || element['orderStatus'] == 11) {
//              this.orderCancelledNumbers += 1;
//              this.cancelledOrderList.push(element);
//            }
//          });
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  cancelButtonOnClick(orderNo): void {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'Do you really want to cancel this order ?',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#2778C4'
    }).then(function (isConfirm) {
      if (isConfirm) {
        var formData: any = new FormData();

        formData.append("uuid", localStorage.getItem('uuid'));
        formData.append("zdtype", "web");
        formData.append("token", localStorage.getItem('token'));
        formData.append("orderNo", orderNo);

//        this._http
//          .post(
//            `http://uml.arriival.com/qmcs-u-api/order/cancel `,
//            formData
//          )
//          .pipe(
//            catchError((err: HttpErrorResponse) => {
//              return throwError("Something bad happened; please try again later.");
//            }) // then handle the error
//          )
//          .subscribe((respObj) => {
//            if (respObj["code"] == 200) {
//              Swal.fire({
//                title: 'Notice',
//                text: 'The order had been cancelled',
//                icon: 'warning',
//                confirmButtonText: 'Yes',
//                showCancelButton: true,
//                cancelButtonText: 'No',
//                cancelButtonColor: '#2778C4'
//              });
//            } else {
//              alert(respObj["enMsg"]);
//            }
//          });
      } else {
        // do nothing
      }
    });
  }

  toggleOrderDateTime(event): void {
    this.orderDateTime = event.target.checked;
  }

  toggletrackingOrderNo(event): void {
    this.trackingOrderNo = event.target.checked;
  }

  toggleSenderNameAddress(event): void {
    this.senderNameAddress = event.target.checked;
  }

  toggleSenderCountry(event): void {
    this.senderCountry = event.target.checked;
  }

  toggleReceiverNameAddress(event): void {
    this.receiverNameAddress = event.target.checked;
  }

  toggleReceiverHPNo(event): void {
    this.receiverHPNo = event.target.checked;
  }

  toggleReceiverCountry(event): void {
    this.receiverCountry = event.target.checked;
  }

  toggleOrderType(event): void {
    this.orderType = event.target.checked;
  }

  toggleOrderStatus(event): void {
    this.orderStatus = event.target.checked;
  }

  view_and_track(orderNumber) {
    $('#displayOrderNo').text(orderNumber);

    var formData: any = new FormData();

    formData.append("orderNo", 2104071107043525);

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/order/selectOrderByOrderNo`,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          console.log(respObj['data']);
//          $('#orderDetailsModal2').modal('show');
//
//          if (respObj['data']['order']['orderStatus'] == 1)
//            $('#displayOrderNo').text(orderNumber + " - Order Placed " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 2)
//            $('#displayOrderNo').text(orderNumber + " - Order Confirmed " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 3)
//            $('#displayOrderNo').text(orderNumber + " - Pickup " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 4)
//            $('#displayOrderNo').text(orderNumber + " - Parcel packeted " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 5)
//            $('#displayOrderNo').text(orderNumber + " - Sending " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 6)
//            $('#displayOrderNo').text(orderNumber + " - Parcel Acceptance " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 7)
//            $('#displayOrderNo').text(orderNumber + " - Delivering " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 8) {
//            this.orderIsCompleted = true;
//            $('#displayOrderNo').text(orderNumber + " - Completed " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          }
//          else if (respObj['data']['order']['orderStatus'] == 9)
//            $('#displayOrderNo').text(orderNumber + " - Rated " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 10)
//            $('#displayOrderNo').text(orderNumber + " - Normal Cancel " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 11)
//            $('#displayOrderNo').text(orderNumber + " - Abnormal Cancel " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 12)
//            $('#displayOrderNo').text(orderNumber + " - Rejected " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 13)
//            $('#displayOrderNo').text(orderNumber + " - Item Lost " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//          else if (respObj['data']['order']['orderStatus'] == 14)
//            $('#displayOrderNo').text(orderNumber + " - Item Broken " + new Date(respObj['data']['order']['orderRequiredTime']).toLocaleString());
//
//          $('#requestDateTime').text(new Date(respObj['data']['order']['orderTime']).toLocaleString());
//
//          if (respObj['data']['order']['orderGoodsType'] == 117)
//            $('#packageSize').text("Motorcycle");
//          if (respObj['data']['order']['orderGoodsType'] == 118)
//            $('#packageSize').text("Car");
//          if (respObj['data']['order']['orderGoodsType'] == 119)
//            $('#packageSize').text("Van");
//          if (respObj['data']['order']['orderGoodsType'] == 120)
//            $('#packageSize').text("Lorry (1 Ton)");
//          if (respObj['data']['order']['orderGoodsType'] == 121)
//            $('#packageSize').text("Lorry (3 Ton)");
//          if (respObj['data']['order']['orderGoodsType'] == 122)
//            $('#packageSize').text("Chilled Lorry");
//          if (respObj['data']['order']['orderGoodsType'] == 123)
//            $('#packageSize').text("120cm(L) X 70cm(H) X 70cm(W)");
//          if (respObj['data']['order']['orderGoodsType'] == 124)
//            $('#packageSize').text("150cm(L) X 150cm(H) X 150cm(W)");
//
//          $('#weight').text(respObj['data']['order']['orderGoodsWeight']);
//          $('#numberParcel').text("");
//
//          if (respObj['data']['order']['orderTransType'] == 0)
//            $('#deliveryType').text("Courier Service");
//          else if (respObj['data']['order']['orderTransType'] == 2)
//            $('#deliveryType').text("Express Delivery");
//          else if (respObj['data']['order']['orderTransType'] == 3)
//            $('#deliveryType').text("Bundled Delivery");
//          else if (respObj['data']['order']['orderTransType'] == 4)
//            $('#deliveryType').text("Cargo Service");
//          else if (respObj['data']['order']['orderTransType'] == 5)
//            $('#deliveryType').text("Air Freight");
//          else if (respObj['data']['order']['orderTransType'] == 6)
//            $('#deliveryType').text("International Delivery");
//          else if (respObj['data']['order']['orderTransType'] == 7)
//            $('#deliveryType').text("MultiStop Delivery");
//          else if (respObj['data']['order']['orderTransType'] == 8)
//            $('#deliveryType').text("Sea Carriage");
//
//          if (respObj['data']['orderPriceDetail'] != null) {
//            if (respObj['data']['orderPriceDetail']['priceDetailPayType'] == 30)
//              $('#paymentMethod').text("Wallet");
//            else if (respObj['data']['orderPriceDetail']['priceDetailPayType'] == 50)
//              $('#paymentMethod').text("Cash");
//            else if (respObj['data']['orderPriceDetail']['priceDetailPayType'] == 60)
//              $('#paymentMethod').text("Credit/Debit Card");
//
//            // $('#priceDetail').text("1 Parcel/Carton (" + respObj['data']['orderPriceDetail']['originalPrice'] + " X 2) RM" + respObj['data']['orderPriceDetail']['originalPrice']);
//            $('#totalPrice').text("RM" + respObj['data']['orderPriceDetail']['totalPrice']);
//
//            $('#basedPrice').text("RM" + respObj['data']['orderPriceDetail']['priceDetailBase']);
//            $('#distanceRangePrice').text("RM" + respObj['data']['orderPriceDetail']['priceDetailKm']);
//            $('#addStop').text("RM" + respObj['data']['orderPriceDetail']['totalPrice']);
//            $('#paymentCollectionService').text("RM" + respObj['data']['orderPriceDetail']['priceDetailPaymentCollectionService']);
//            $('#returnDOService').text("RM" + respObj['data']['orderPriceDetail']['priceDetailReturnDOService']);
//          }
//
//          // if (this.orderIsCompleted) {
//          //   $("#divPriceDetail").show();
//          //   $("#divDistancePriceDetail").hide();
//          // } else {
//          //   $("#divPriceDetail").hide();
//          //   $("#divDistancePriceDetail").show();
//          // }
//
//          if (respObj['data']['orderHayakuDeliveryList'] != null) {
//            this.isHayakuDeliveryList = true;
//            this.deliveryInformation = respObj['data']['orderHayakuDeliveryList']['TrackDetailsResponse']['EventList'][0]['Events'];
//            this.deliveryInformation.forEach(element => {
//              // element['StrDate'] = this.datepipe.transform(element['StrDate'], 'MMMM-dd-yyyy');
//              element['StrTime'] = new Date(element['StrTime'].substring(1, element['StrTime'].length - 1)).toLocaleString();
//              var splitTime = element['StrTime'].split(",");
//              element['StrTime'] = splitTime[1];
//            });
//          }
//          else if (respObj['data']['orderCjDeliveryList'] != null) {
//            this.isCJDeliveryList = true;
//            this.deliveryInformation = respObj['data']['orderCjDeliveryList']['TRACK'];
//          }
//          else if (respObj['data']['orderDeliveryList'] != null) {
//            this.isOrderJointDeliveryList = true;
//            this.deliveryInformation = respObj['data']['orderDeliveryList'];
//            this.deliveryInformation.forEach(element => {
//              // element['StrDate'] = this.datepipe.transform(element['StrDate'], 'MMMM-dd-yyyy');
//              element['deliveryCreatTime'] = new Date(element['deliveryCreatTime']).toLocaleString();
//            });
//          }
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  printWayBill(wayBillNo) {
    window.open("http://h5.arriival.com/waybill.html?id=" + wayBillNo);
  }

}
