import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  displayInstantSubMenu:Boolean = false;
  displayDomesticSubMenu:Boolean = false;
  displayInternationalSubMenu:Boolean = false;
  displayOrderSubMenu:Boolean = false;
  displayReferralSubMenu:Boolean = false;
  displayAccountSubMenu:Boolean = false;

  displayOwnCredit:number = 0;
  displayUsername:String = '';

  constructor() {
  }

  ngOnInit(): void {
    this.displayOwnCredit = 0;
    this.displayUsername = localStorage.getItem("username");
  }

  toggleInstantMenu(): void {
    this.displayInstantSubMenu = !this.displayInstantSubMenu;
  }

  toggleDomesticMenu(): void {
    this.displayDomesticSubMenu = !this.displayDomesticSubMenu;
  }

  toggleInternationalMenu(): void {
    this.displayInternationalSubMenu = !this.displayInternationalSubMenu;
  }

  toggleOrderMenu(): void {
    this.displayOrderSubMenu = !this.displayOrderSubMenu;
  }

  toggleReferralSubMenu(): void {
    this.displayReferralSubMenu = !this.displayReferralSubMenu;
  }

  toggleAccountMenu(): void {
    this.displayAccountSubMenu = !this.displayAccountSubMenu;
  }

}
