import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.css']
})
export class StatementComponent implements OnInit {
  @ViewChild('noprint', {static: false}) noprint:ElementRef;

  statementData:any[] = [{
    "companyName": "SUN CERAMIC SDN BHD",
    "registerIDHP": "60123380984",
    "dateRange": "01/02/2020 ~ 29/02/2020",
    "currency": "RM",
    "total": "500.00",
    "statements": [
      {
        "orderTime": "2020-02-26 09:31:33",
        "documentNo": "2002261645583390",
        "type": "FREE DELIVERY CREDIT",
        "debit": "500.00",
        "credit": "500.00",
        "balance": "500.00"
      },
      {
        "orderTime": "2020-02-26 09:31:33",
        "documentNo": "2002261645583390",
        "type": "FREE DELIVERY CREDIT",
        "debit": "500.00",
        "credit": "-60.00",
        "balance": "440.00"
      }
    ]
  }];
  public page:number = 1;
  public pageSize:number = 20;

  constructor() { }

  ngOnInit(): void {
  }

  generatePDF() {
    var data = document.getElementById('contentToConvert');
    this.noprint.nativeElement.style.display = "none";
    html2canvas(data).then(canvas => {
      var imgWidth = 188;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var paddingLeft = 10;
      var paddingTop = 10;
      pdf.addImage(contentDataURL, 'PNG', paddingTop, paddingLeft, imgWidth, imgHeight)
      pdf.save('arriival_statement.pdf');
    });
    this.noprint.nativeElement.style.display = "block";
  }
}
