<div class="modal fade" id="orderDetailsModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header justify-content-start" style="background-color: #0089CF;color: white;">
        <button type="button" class="btn btn-default" style="color: white;" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Order No.: <span id="displayOrderNo"></span></h4>
      </div>
      <div class="modal-body" style=" padding-left:25px; padding-right:25px; padding-bottom:20px;">
        <div class="row">
          <div class="col-6">
            Request Date/Time: <span id="requestDateTime"></span><br>
            Package Size(cm): <span id="packageSize"></span><br>
            Weight: <span id="weight"></span><br>
            No of Parcel/Carton: <span id="numberParcel"></span><br>
            Delivery Type: <span id="deliveryType"></span><br>
          </div>
          <div class="col-6">
            Payment Method <span id="paymentMethod"></span><br>
            Price Detail <br>
            <!-- <div id="divPriceDetail">
              <span id="priceDetail"></span><br>
            </div> -->
            <div id="divDistancePriceDetail">
              Based Price <span id="basedPrice"></span><br>
              Distance Range Price <span id="distanceRangePrice"></span><br>
              Add Stop <span id="addStop"></span><br>
              Payment Collection Service <span id="paymentCollectionService"></span><br>
              Return D.O. Service <span id="returnDOService"></span><br>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Note to Angel: <br>
            we need driver door to door and delivery to receiver
          </div>
        </div>
        <div class="row">
          <div class="col">
            Delivery Information <br>
            <div class="row" *ngIf="isHayakuDeliveryList">
              <div class="col-md-12">
                <div class="" *ngFor="let data of deliveryInformation">
                  <i class="fas fa-dot-circle tracking-point-circle"></i>
                  <div class="tracking-decoration">
                    <span class="tracking-title">{{ data.StrDate }} {{ data.StrTime}}</span>
                    <span class="tracking-subtitle text-muted">{{ data.EventDescription }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isCJDeliveryList">
              <div class="col-md-12">
                <div class="" *ngFor="let data of deliveryInformation">
                  <i class="fas fa-dot-circle tracking-point-circle"></i>
                  <div class="tracking-decoration">
                    <span class="tracking-title">{{ data.SCAN_DT }}</span>
                    <span class="tracking-subtitle text-muted">{{ data.STS_MSG }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isOrderJointDeliveryList">
              <div class="col-md-12">
                <div class="" *ngFor="let data of deliveryInformation">
                  <i class="fas fa-dot-circle tracking-point-circle"></i>
                  <div class="tracking-decoration">
                    <span class="tracking-title">{{ data.deliveryCreatTime }}</span>
                    <span class="tracking-subtitle text-muted">{{ data.deliveryOrderDetail }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="orderDetailsModal2" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header justify-content-between p-0 pl-3 align-items-center bg-orange"><!-- bg-orange, bg-light-blue, bg-green, bg-pink -->
        <h6 class="modal-title">Order No.: #2102261717219599 - Pending</h6>
        <div class="d-flex flex-row align-items-center">
          <span class="pr-3">Oct-12-2020 10:15am</span>
          <button type="button" class="btn btn-danger" style="color: white;" data-dismiss="modal">&times;</button>
        </div>
      </div>
      <div class="modal-body pt-0">
        <div class="row px-4 py-3" style="background-color: #f1f2f4;">
          <div class="col-md-6">
            <div class="d-flex flex-row">
              <span style="width: 42%;">Request Date/time:</span>
              <span>Oct-11-2020 10:10:00 am</span>
            </div>

            <div class="d-flex flex-row">
              <span style="width: 42%;">Package Size (cm):</span>
              <span>60(L) x 60(H) x 60(W)</span>
            </div>

            <div class="d-flex flex-row">
              <span style="width: 42%;">Weight:</span>
              <span>5kg</span>
            </div>

            <div class="d-flex flex-row">
              <span style="width: 42%;">No. of Stop:</span>
              <span>1 Stop / Qty: 4 &lt; 5kg</span>
            </div>

            <div class="d-flex flex-row">
              <span style="width: 42%;">Parcel Type:</span>
              <span>Fresh Food / Non Halal</span>
            </div>

            <div class="d-flex flex-row">
              <span style="width: 42%;">Item Price Value:</span>
              <span>RM 30 / Parcel</span>
            </div>

            <div class="d-flex flex-row">
              <span style="width: 42%;">Delivery Type:</span>
              <span class="font-weight-bold">Express Delivery</span>
            </div>

            <div class="d-flex flex-row">
              <span style="width: 42%;">From:</span>
              <span>Kuala Lumpur</span>
            </div>

            <div class="d-flex flex-row">
              <span style="width: 42%;">To:</span>
              <span>Sabah</span>
            </div>

            <div class="d-flex flex-column pt-3">
              <div class="d-flex flex-row">
                <span class="font-weight-bold">Arriival Angel</span>
                <!-- *ngIf="matched" -->
                <div class="d-flex flex-row align-items-center">
                  <span class="px-2">| Rate: </span>
                  <i class="fas fa-star text-warning pr-1"></i>
                  <span>4.5</span>
                </div>
              </div>
              <div class="d-flex flex-row">
                <img width="50" height="50" class="rounded-circle mr-2" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcT9rpl4EUmQD4YPmAjS76iCXSkHqAJg8qvyA2ueuqN2nxYtjZBNI4-J9gJKFSO0nwu5hzpiRugqx9oPzuqcFjs-4Wm3PL-dvGingHO-&amp;usqp=CAU&amp;ec=45707746">
                <div class="d-flex flex-column">
                  <span class="text-uppercase">matching....</span>
                  <div class="d-flex flex-row align-items-center">
                    <i class="fas fa-car-side pr-2 text-muted"></i>
                    <span>Car</span>
                  </div>
                  <!-- Show only before rated, and order completed -->
                  <!-- <div class="d-flex flex-row align-items-center">
                    <span class="pr-2">Your Rate</span>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="far fa-star text-muted"></i>
                    <i class="far fa-star text-muted"></i>
                  </div> -->
                  <!-- Show only after rated, and order completed -->
                  <div class="d-flex flex-row align-items-center bg-light p-2" style="border: 1px solid grey;border-radius: 10px;">
                    <span class="pr-2">Rate Angel</span>
                    <i (mouseenter)="activate1Star();clearActivateStarCard();" (click)="onTap1Star();clearStarCard();" [ngClass]="{'fas': show1Star || show2Star || show3Star || show4Star || show5Star || star1 || star2 || star3 || star4 || star5, 'text-warning': show1Star || show2Star || show3Star || show4Star || show5Star || star1 || star2 || star3 || star4 || star5}" class="far fa-star"></i>
                    <i (mouseenter)="activate2Star();clearActivateStarCard();" (click)="onTap2Star();clearStarCard();" [ngClass]="{'fas': show2Star || show3Star || show4Star || show5Star || star2 || star3 || star4 || star5, 'text-warning': show2Star || show3Star || show4Star || show5Star || star2 || star3 || star4 || star5}" class="far fa-star"></i>
                    <i (mouseenter)="activate3Star();clearActivateStarCard();" (click)="onTap3Star();clearStarCard();" [ngClass]="{'fas': show3Star || show4Star || show5Star || star3 || star4 || star5, 'text-warning': show3Star || show4Star || show5Star || star3 || star4 || star5}" class="far fa-star"></i>
                    <i (mouseenter)="activate4Star();clearActivateStarCard();" (click)="onTap4Star();clearStarCard();" [ngClass]="{'fas': show4Star || show5Star || star4 || star5, 'text-warning': show4Star || show5Star || star4 || star5}" class="far fa-star"></i>
                    <i (mouseenter)="activate5Star();clearActivateStarCard();" (click)="onTap5Star();clearStarCard();" [ngClass]="{'fas': show5Star || star5, 'text-warning': show5Star || star5}" class="far fa-star"></i>
                  </div>

                  <!-- 1,2,3 Stars -->
                  <div *ngIf="show1Star || show2Star || show3Star || star1 || star2 || star3" [ngClass]="{'left-73px': show1Star || star1, 'left-92px': show2Star || star2, 'left-109px': show3Star || star3}" class="arrow-up position-absolute" style="z-index: 2;bottom: -1px;margin-left: 99px;"></div>
                  <div (mouseleave)="clearStarCard()" *ngIf="show1Star || show2Star || show3Star" class="position-absolute" style="background-color: white;border-radius: 10px;bottom: -63px;z-index: 1;right: 38px;width: 190px;box-shadow: 4px 4px 10px grey;padding: 5px 10px 10px 10px;">
                    <span>You will not to see this Angel anymore</span>
                  </div>
                  <div (mouseleave)="clearStarCard()" *ngIf="star1 || star2 || star3" class="position-absolute" style="background-color: white;border-radius: 10px;bottom: -230px;z-index: 1;right: 0px;width: max-content;box-shadow: 4px 4px 10px grey;">
                    <div class="d-flex flex-column m-3">
                      <label for="">
                        <input type="radio" name="" value=""> Angel was late
                      </label>
                      <label for="">
                        <input type="radio" name="" value=""> Angel was rude
                      </label>
                      <label for="">
                        <input type="radio" name="" value=""> Angel damaged the item
                      </label>
                      <label for="">
                        <input type="radio" name="" value=""> Angel was untidy
                      </label>
                      <label for="">
                        <input type="radio" name="" value=""> Other
                      </label>
                      <button type="button" class="btn btn-primary" style="border-radius: 10px;" name="button">Send</button>
                    </div>
                  </div>

                  <!-- 4 Stars -->
                  <div *ngIf="show4Star || star4" class="arrow-up position-absolute" style="z-index: 2;left: 128px;bottom: -1px;margin-left: 99px;"></div>
                  <div (mouseleave)="clearStarCard()" *ngIf="show4Star" class="position-absolute" style="background-color: white;border-radius: 10px;bottom: -63px;z-index: 1;right: 38px;width: 230px;box-shadow: 4px 4px 10px grey;padding: 5px 10px 10px 10px;">
                    <span>Not bad, but no need to give additional priority next time.</span>
                  </div>
                  <div (mouseleave)="clearStarCard()" *ngIf="star4" class="position-absolute" style="background-color: white;border-radius: 10px;bottom: -119px;z-index: 1;right: -65px;width: 250px;box-shadow: 4px 4px 10px grey;">
                    <div class="d-flex flex-column m-3">
                      <span>Not bad, but no need to give additional priority next time.</span>
                      <button type="button" class="btn btn-primary" style="border-radius: 10px;" name="button">Send</button>
                    </div>
                  </div>

                  <!-- 5 Stars -->
                  <div *ngIf="show5Star || star5" class="arrow-up position-absolute" style="z-index: 2;left: 145px;bottom: -1px;margin-left: 99px;"></div>
                  <div (mouseleave)="clearStarCard()" *ngIf="show5Star" class="position-absolute" style="background-color: white;border-radius: 10px;bottom: -63px;z-index: 1;right: 38px;width: 220px;box-shadow: 4px 4px 10px grey;padding: 5px 10px 10px 10px;">
                    <span>The Angel will get priority on your next order!</span>
                  </div>
                  <div (mouseleave)="clearStarCard()" *ngIf="star5" class="position-absolute" style="background-color: white;border-radius: 10px;bottom: -119px;z-index: 1;right: -65px;width: 250px;box-shadow: 4px 4px 10px grey;">
                    <div class="d-flex flex-column m-3">
                      <span>The Angel will get priority on your next order!</span>
                      <button type="button" class="btn btn-primary" style="border-radius: 10px;" name="button">Send</button>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          </div>
          <div class="col-md-6">
            <div class="d-flex flex-row justify-content-between">
              <span>Payment Method</span>
              <div class="d-flex flex-row align-items-center">
                <i class="fa fa-wallet pr-2"></i>
                <span class="font-weight-bold">Wallet</span>

                <!-- <i class="fa fa-money-bill-alt pr-2"></i>
                <span class="font-weight-bold">Cash</span>

                <i class="fa fa-credit-card pr-2"></i>
                <span class="font-weight-bold">Credit/Debit Card</span>

                <i class="fa fa-university pr-2"></i>
                <span class="font-weight-bold">Internet Banking</span> -->
              </div>
            </div>
            <div style="border-top: 1px solid black;"></div>
            <div class="d-flex flex-row">
              <span class="font-weight-bold">Price Detail</span>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <span>Based Price</span>
              <span>RM 6.80</span>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <span>Distance Range Price</span>
              <span>RM 2.10</span>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <span>Payment Collection Service</span>
              <span>RM 3.00</span>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <span>Return D.O. Service</span>
              <span>RM 3.00</span>
            </div>
            <div style="border-top: 1px solid black;"></div>
            <div class="d-flex flex-row justify-content-between font-weight-bold" style="font-size: 22px;">
              <span>Total</span>
              <span>RM14.90</span>
            </div>
            <div class="mb-1" style="border-top: 1px solid black;"></div>
            <div style="border-top: 1px solid black;"></div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-6">
            <span class="font-italic font-weight-bold text-muted">Note to Angel:</span> <br />
            <span>we need driver door to door and delivery to receiver</span>
          </div>
          <div class="col-md-6">
            <span class="font-weight-bold text-muted font-italic">Priority Fee</span><br />
            <p>Adding tips when looking for a driver may increase your chances of being matched faster. You can add up to a total of RM100 from your credit.</p>
            <span>Current Credit:</span><span style="font-weight: 600;"> RM20.00 or </span><span class="font-weight-bold" style="color: rgba(252, 122, 38, 1);">Buy Credit</span><br />
            <div class="d-flex flex-row">
              <input type="text" name="" class="mr-2" value="" placeholder="+ RM" />
              <button type="button" class="btn btn-primary" name="button">
                <i class="fa fa-plus"></i>
                Add Priority Fee
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span style="color: rgba(97, 158, 180, 1);" class="font-weight-bold">Delivery Information</span><br />
            <div class="position-relative">
              <div class="position-absolute h-100" style="z-index: 0;border-left: 1px solid grey;left: 10.5px;"></div>
              <!-- Put align-items-start into ngClass, if index == last index, no need this class -->
              <div class="d-flex flex-row mt-2 align-items-start">
                <i style="font-size: 24px;z-index: 1;color: rgba(97, 158, 180, 1);" class="fas fa-circle pr-3 bg-light"></i>
                <div class="d-flex flex-column">
                  <span>Oct-12-2020 08:45 am</span>
                  <span class="text-muted">Made ordered by Chan Ming Heng 60179495984</span>
                </div>
              </div>
              <div class="d-flex flex-row mt-2">
                <i style="font-size: 24px;z-index: 1;padding-left: 2px;" class="fas fa-map-marker-alt text-primary pr-3 bg-light"></i>
                <div class="d-flex flex-column">
                  <span>Oct-12-2020 08:45 am</span>
                  <span class="text-muted">Made ordered by Chan Ming Heng 60179495984</span>
                </div>
              </div>
            </div>

            <!-- <div class="row" *ngIf="isHayakuDeliveryList">
              <div class="col-md-12">
                <div class="" *ngFor="let data of deliveryInformation">
                  <i class="fas fa-dot-circle tracking-point-circle"></i>
                  <div class="tracking-decoration">
                    <span class="tracking-title">{{ data.StrDate }} {{ data.StrTime}}</span>
                    <span class="tracking-subtitle text-muted">{{ data.EventDescription }}</span>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="row" *ngIf="isCJDeliveryList">
              <div class="col-md-12">
                <div class="" *ngFor="let data of deliveryInformation">
                  <i class="fas fa-dot-circle tracking-point-circle"></i>
                  <div class="tracking-decoration">
                    <span class="tracking-title">{{ data.SCAN_DT }}</span>
                    <span class="tracking-subtitle text-muted">{{ data.STS_MSG }}</span>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="row" *ngIf="isOrderJointDeliveryList">
              <div class="col-md-12">
                <div class="" *ngFor="let data of deliveryInformation">
                  <i class="fas fa-dot-circle tracking-point-circle"></i>
                  <div class="tracking-decoration">
                    <span class="tracking-title">{{ data.deliveryCreatTime }}</span>
                    <span class="tracking-subtitle text-muted">{{ data.deliveryOrderDetail }}</span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="modal-footer text-right" style="background-color: #f1f2f4;">
        <button type="button" class="btn btn-primary" name="button">Cancel Order</button>
      </div>
    </div>
  </div>
</div>

<div class="content col-mg-9 col-lg-12 pt-3">
  <div class="d-flex flex-row">
    <div class="flex-fill">
      <h2>Manage Orders</h2>
      <div class="">
        <small>Manage and overview of all corporate orders</small>
      </div>
      <div class="text-primary">
        <span style="font-weight: 600;">Keep tracks of all order by selected date.</span>
      </div>
    </div>
    <!-- <div class="flex-fill">
      <h1 class="text-center font-weight-bold">90 Days Overview</h1>
    </div> -->
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="">
        <label for="">Date from</label>
        <div class="form-inline">
          <input type="date" class="form-control mr-2 flex-fill" name="" value="" />
          <div class="btn disabled" style="opacity: 0;">Search</div>
        </div>
      </div>
      <div class="">
        <label for="">Date end</label>
        <div class="form-inline">
          <input type="date" class="form-control mr-2 flex-fill" name="" value="" />
          <button type="button" class="btn btn-primary" name="button">Search</button>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="">
        <label for="">Search Keyword</label>
        <input type="text" class="form-control" name="" value="" placeholder="Shortcut to Search" />
      </div>
      <div class="">
        <label for="">Delivery Type</label>
        <select class="form-control" name="">
          <option value="" selected disabled>Search by Type</option>
          <option value="all">All type of delivery</option>
          <option value="express">Search by Express Delivery</option>
          <option value="sameday">Search by Same Day Delivery</option>
          <option value="courier">Search by Courier Service</option>
          <option value="cargo">Search by Cargo Service</option>
          <option value="airfreight">Search by Air Freight Service</option>
          <option value="international">Search by International Delivery</option>
        </select>
      </div>
    </div>
    <div class="col-md-5">
      <div class="row">
        <div class="col-md-4">
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">{{ allOrdersNumbers }}</span>
            <span style="font-size: 14px;" class="text-muted">Total Orders</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">{{ orderPendingNumbers }}</span>
            <span style="font-size: 14px;" class="text-muted">Pending Delivery</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">{{ orderFailedNumbers }}</span>
            <span style="font-size: 14px;" class="text-muted">Fail Delivery</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2 text-success">{{ orderCompletedNumbers }}</span>
            <span style="font-size: 14px;" class="text-muted">Completed Orders</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">{{ orderInDeliveryingNumbers }}</span>
            <span style="font-size: 14px;" class="text-muted">In Deliverying</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2 text-danger">{{ orderCancelledNumbers }}</span>
            <span style="font-size: 14px;" class="text-muted">Cancelled</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column">
    <div class="d-flex flex-row mt-2">
      <!-- <div class="d-flex flex-column" style="width: 280px;">
        <div class="">
          <label for="allDate">
            <input type="checkbox" id="allDate" name="" value="" /> Select all date
          </label>
        </div>
        <div class="">
          <input #daterangepicker id="daterangepicker1" type="hidden">
          <div id="daterangepicker1-container" class="embedded-daterangepicker"></div>
        </div>
      </div> -->
      <!-- <div class="d-flex flex-column">
        <div class="">
          <label for="">Search Keyword</label>
          <input type="text" class="form-control" name="" value="" placeholder="Shortcut to Search" />
        </div>
        <div class="mt-5">
          <label for="">Delivery Type</label>
          <select class="form-control" name="">
            <option value="" selected disabled>Search by Type</option>
            <option value="all">All type of delivery</option>
            <option value="express">Search by Express Delivery</option>
            <option value="sameday">Search by Same Day Delivery</option>
            <option value="courier">Search by Courier Service</option>
            <option value="cargo">Search by Cargo Service</option>
            <option value="airfreight">Search by Air Freight Service</option>
            <option value="international">Search by International Delivery</option>
          </select>
        </div>
      </div> -->
      <!-- <div class="d-flex flex-column flex-fill">
        <div class="d-flex flex-row justify-content-between text-center">
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">106</span>
            <span class="font-weight-bold">Total Order</span>
          </div>
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">26</span>
            <span class="font-weight-bold">Pending Delivery</span>
          </div>
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">5</span>
            <span class="font-weight-bold">In Deliverying</span>
          </div>
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">32</span>
            <span class="font-weight-bold">Completed</span>
          </div>
        </div>
        <div class="d-flex flex-row mt-5 justify-content-center text-center">
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">3</span>
            <span class="font-weight-bold">Fail Delivery</span>
          </div>
          <div class="d-flex flex-column mx-3">
            <span style="font-size: 26px;font-weight: 600;" class="mb-2">4</span>
            <span class="font-weight-bold">Cancelled</span>
          </div>
        </div>
      </div> -->
    </div>
    <div class="d-flex flex-row flex-wrap table-column-controller" style="padding-top: 20px;">
      <div class="">
        <label for="">
          <input type="checkbox" name="orderDateTime" (click)="toggleOrderDateTime($event)" [checked]="orderDateTime" />
          Order Date/Time
        </label>
      </div>
      <div class="">
        <label for="">
          <input type="checkbox" name="trackingOrderNo" (click)="toggletrackingOrderNo($event)"
            [checked]="trackingOrderNo" /> Tracking No./Order No.
        </label>
      </div>
      <div class="">
        <label for="">
          <input type="checkbox" name="orderStatus" (click)="toggleOrderStatus($event)" [checked]="orderStatus" />
          Status
        </label>
      </div>
      <div class="">
        <label for="">
          <input type="checkbox" name="receiverNameAddress" (click)="toggleReceiverNameAddress($event)"
            [checked]="receiverNameAddress" /> Receiver's Name/Address
        </label>
      </div>
      <div class="">
        <label for="">
          <input type="checkbox" name="receiverHPNo" (click)="toggleReceiverHPNo($event)" [checked]="receiverHPNo" />
          Receiver's HP No.
        </label>
      </div>
      <div class="">
        <label for="">
          <input type="checkbox" name="orderType" (click)="toggleOrderType($event)" [checked]="orderType" /> Delivery
          Type
        </label>
      </div>
      <div class="">
        <label for="">
          <input type="checkbox" name="senderNameAddress" (click)="toggleSenderNameAddress($event)"
            [checked]="senderNameAddress" /> Sender's Name/Address
        </label>
      </div>
      <div class="">
        <label for="">
          <input type="checkbox" name="senderCountry" (click)="toggleSenderCountry($event)" [checked]="senderCountry" />
          Sender's Country
        </label>
      </div>
      <!-- <div class="">
        <label for="">
          <input type="checkbox" name="receiverCountry" (click)="toggleReceiverCountry($event)" [checked]="receiverCountry" /> Receiver's Country
        </label>
      </div> -->
    </div>
  </div>


  <nav class="mt-3">
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a class="nav-item nav-link active" id="nav-all-tab" data-toggle="tab" href="#nav-all" role="tab"
        aria-controls="nav-all" aria-selected="true">All</a>
      <a class="nav-item nav-link" id="nav-pd-tab" data-toggle="tab" href="#nav-pd" role="tab" aria-controls="nav-pd"
        aria-selected="false">Pending Delivery</a>
      <a class="nav-item nav-link" id="nav-id-tab" data-toggle="tab" href="#nav-id" role="tab" aria-controls="nav-id"
        aria-selected="false">In Deliverying</a>
      <a class="nav-item nav-link" id="nav-dc-tab" data-toggle="tab" href="#nav-dc" role="tab" aria-controls="nav-dc"
        aria-selected="false">Delivery Completed</a>
      <a class="nav-item nav-link" id="nav-fd-tab" data-toggle="tab" href="#nav-fd" role="tab" aria-controls="nav-fd"
        aria-selected="false">Fail Delivery</a>
      <a class="nav-item nav-link" id="nav-oc-tab" data-toggle="tab" href="#nav-oc" role="tab" aria-controls="nav-oc"
        aria-selected="false">Order Cancelled</a>
    </div>
  </nav>

  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">
      <div class="row">
        <div class="col-md-12 table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="bg-primary text-light">
                <th class="nowrap">*</th>
                <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                <th *ngIf="orderStatus" class="nowrap">Status</th>
                <th class="nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="orderList.length == 0; else displayOrderList">
                <td colspan="9" style="text-align: center;">No data.</td>
              </tr>

              <!-- <ng-template #displayOrderList> -->
                <tr *ngFor="let data of datas">
                  <td>{{ data.orderId }}</td>
                  <td><input type="checkbox" name="" value="" /></td>
                  <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                  <td *ngIf="trackingOrderNo">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderNo }}</span>
                      <span>{{ data.orderReceiverTel }}</span>
                    </div>
                  </td>
                  <td *ngIf="senderNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                      <span>{{ data.orderSendAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="receiverNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                      <span>{{ data.orderReceiveAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                  <td *ngIf="orderStatus">
                    <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                      class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                  </td>
                  <td class="nowrap">
                    <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp; -->
                    <!-- <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                    <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                      name="button">View & Track</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                      name="button">Print</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                      name="button">Cancel</button>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="text-center">
                      <button type="button" class="btn btn-sm btn-info" name="button">Print Labels</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                    </div>
                  </td>
                </tr>
              <!-- </ng-template> -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="nav-pd" role="tabpanel" aria-labelledby="nav-pd-tab">
      <div class="row">
        <div class="col-md-12 table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="bg-primary text-light">
                <th class="nowrap">*</th>
                <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                <th *ngIf="orderStatus" class="nowrap">Status</th>
                <th class="nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="orderList.length == 0; else displayOrderList">
                <td colspan="9" style="text-align: center;">No data.</td>
              </tr>

              <ng-template #displayOrderList>
                <tr *ngFor="let data of pendingOrderList">
                  <td>{{ data.orderId }}</td>
                  <td><input type="checkbox" name="" value="" /></td>
                  <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                  <td *ngIf="trackingOrderNo">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderNo }}</span>
                      <span>{{ data.orderReceiverTel }}</span>
                    </div>
                  </td>
                  <td *ngIf="senderNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                      <span>{{ data.orderSendAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="receiverNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                      <span>{{ data.orderReceiveAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="orderType"><span class="font-weight-bold">Express Delivery</span></td>
                  <td *ngIf="orderStatus">
                    <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                      class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                  </td>
                  <td class="nowrap">
                    <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                    <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                      name="button">View & Track</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                      name="button">Print</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                      name="button">Cancel</button>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="text-center">
                      <button type="button" class="btn btn-sm btn-info" name="button">Print Labels</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="nav-id" role="tabpanel" aria-labelledby="nav-id-tab">
      <div class="row">
        <div class="col-md-12 table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="bg-primary text-light">
                <th class="nowrap">*</th>
                <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                <th *ngIf="orderStatus" class="nowrap">Status</th>
                <th class="nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="orderList.length == 0; else displayOrderList">
                <td colspan="9" style="text-align: center;">No data.</td>
              </tr>

              <ng-template #displayOrderList>
                <tr *ngFor="let data of inDeliveryOrderList">
                  <td>{{ data.orderId }}</td>
                  <td><input type="checkbox" name="" value="" /></td>
                  <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                  <td *ngIf="trackingOrderNo">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderNo }}</span>
                      <span>{{ data.orderReceiverTel }}</span>
                    </div>
                  </td>
                  <td *ngIf="senderNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                      <span>{{ data.orderSendAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="receiverNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                      <span>{{ data.orderReceiveAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                  <td *ngIf="orderStatus">
                    <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                      class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                  </td>
                  <td class="nowrap">
                    <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                    <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                      name="button">View & Track</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                      name="button">Print</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                      name="button">Cancel</button>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="text-center">
                      <button type="button" class="btn btn-sm btn-info" name="button">Print Labels</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="nav-dc" role="tabpanel" aria-labelledby="nav-dc-tab">
      <div class="row">
        <div class="col-md-12 table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="bg-primary text-light">
                <th class="nowrap">*</th>
                <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                <th *ngIf="orderStatus" class="nowrap">Status</th>
                <th class="nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="orderList.length == 0; else displayOrderList">
                <td colspan="9" style="text-align: center;">No data.</td>
              </tr>

              <ng-template #displayOrderList>
                <tr *ngFor="let data of completedDeliveryOrderList">
                  <td>{{ data.orderId }}</td>
                  <td><input type="checkbox" name="" value="" /></td>
                  <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                  <td *ngIf="trackingOrderNo">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderNo }}</span>
                      <span>{{ data.orderReceiverTel }}</span>
                    </div>
                  </td>
                  <td *ngIf="senderNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                      <span>{{ data.orderSendAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="receiverNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                      <span>{{ data.orderReceiveAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                  <td *ngIf="orderStatus">
                    <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                      class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                  </td>
                  <td class="nowrap">
                    <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                    <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                      name="button">View & Track</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                      name="button">Print</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                      name="button">Cancel</button>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="text-center">
                      <button type="button" class="btn btn-sm btn-info" name="button">Print Labels</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="nav-fd" role="tabpanel" aria-labelledby="nav-fd-tab">
      <div class="row">
        <div class="col-md-12 table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="bg-primary text-light">
                <th class="nowrap">*</th>
                <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                <th *ngIf="orderStatus" class="nowrap">Status</th>
                <th class="nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="orderList.length == 0; else displayOrderList">
                <td colspan="9" style="text-align: center;">No data.</td>
              </tr>

              <ng-template #displayOrderList>
                <tr *ngFor="let data of failedDeliveryOrderList">
                  <td>{{ data.orderId }}</td>
                  <td><input type="checkbox" name="" value="" /></td>
                  <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                  <td *ngIf="trackingOrderNo">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderNo }}</span>
                      <span>{{ data.orderReceiverTel }}</span>
                    </div>
                  </td>
                  <td *ngIf="senderNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                      <span>{{ data.orderSendAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="receiverNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                      <span>{{ data.orderReceiveAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                  <td *ngIf="orderStatus">
                    <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                      class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                  </td>
                  <td class="nowrap">
                    <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                    <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                      name="button">View & Track</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                      name="button">Print</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                      name="button">Cancel</button>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="text-center">
                      <button type="button" class="btn btn-sm btn-info" name="button">Print Labels</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="nav-oc" role="tabpanel" aria-labelledby="nav-oc-tab">
      <div class="row">
        <div class="col-md-12 table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="bg-primary text-light">
                <th class="nowrap">*</th>
                <th class="nowrap"><input type="checkbox" name="" value="" /></th>
                <th *ngIf="orderDateTime" class="nowrap">Order Date/Time</th>
                <th *ngIf="trackingOrderNo" class="nowrap">Tracking No./HP No.</th>
                <th *ngIf="senderNameAddress" class="nowrap">Sender's Name/Address</th>
                <th *ngIf="receiverNameAddress" class="nowrap">Receiver's Name/Address</th>
                <th *ngIf="orderType" class="nowrap">Type of Delivery</th>
                <th *ngIf="orderStatus" class="nowrap">Status</th>
                <th class="nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="orderList.length == 0; else displayOrderList">
                <td colspan="9" style="text-align: center;">No data.</td>
              </tr>

              <ng-template #displayOrderList>
                <tr *ngFor="let data of cancelledOrderList">
                  <td>{{ data.orderId }}</td>
                  <td><input type="checkbox" name="" value="" /></td>
                  <td *ngIf="orderDateTime">{{ data.orderTime }}</td>
                  <td *ngIf="trackingOrderNo">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderNo }}</span>
                      <span>{{ data.orderReceiverTel }}</span>
                    </div>
                  </td>
                  <td *ngIf="senderNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderSenderName }}</span>
                      <span>{{ data.orderSendAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="receiverNameAddress">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold">{{ data.orderReceiverName }}</span>
                      <span>{{ data.orderReceiveAddr }}</span>
                    </div>
                  </td>
                  <td *ngIf="orderType"><span class="font-weight-bold">{{ data.typeOfDelivery }}</span></td>
                  <td *ngIf="orderStatus">
                    <div [ngClass]="{'Completed':'text-success', 'Cancelled':'text-cancel'}[data.orderStatus]"
                      class="tracking-status-box text-center">{{ data.orderStatus }}</div>
                  </td>
                  <td class="nowrap">
                    <!-- <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/main/statement/']" name="button">View</button>&nbsp;
                <button type="button" class="btn btn-sm btn-success" name="button">Track</button>&nbsp; -->
                    <button type="button" class="btn btn-sm btn-primary" (click)="view_and_track(data.orderNo)"
                      name="button">View & Track</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-info" (click)="printWayBill(data.waybillNo)"
                      name="button">Print</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-warning" (click)="cancelButtonOnClick(data.orderNo)"
                      name="button">Cancel</button>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="text-center">
                      <button type="button" class="btn btn-sm btn-info" name="button">Print Waybill</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Track Orders</button>&nbsp;
                      <button type="button" class="btn btn-sm btn-info" name="button">Download</button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
