<!-- <div class="login-reg-panel">

		<div class="register-info-box">
			<h2>Don't have an account?</h2>
			<p>Join us now!</p>
      <button type="button" class="btn-register" name="button" [routerLink]="['/register/']">Register</button>
		</div>

		<div class="white-panel">
			<div class="login-show">
				<h2>LOGIN</h2>
				<input type="text" id="inputLoginName" placeholder="Phone Number (+60)" name="loginName" [(ngModel)]="loginName">
				<input type="password" placeholder="Password" name="password" [(ngModel)]="password">
				//<input type="button" value="Login" [routerLink]="['/main/']">
				 <input type="button" value="Login" (click)="login()">
				<a href="">Forgot password?</a>
			</div>
		</div>
	</div> -->

<div class="container-fluid bg-login w-100 h-100" [ngClass]="{'bg-login': showLogin, 'bg-reset-password': showReset || showVerification || showChangePassword}">
	<div class="row">
		<div class="col-md-6 text-light position-relative" style="height: 100vh;">
			<div *ngIf="showLogin" class="position-relative h-100 w-100">
				<div class="position-absolute" style="bottom: 20%;left: 10%;">
					<span style="font-size: 30px;" class="text-uppercase font-italic font-weight-bold">arriival</span><br />
					<span style="font-size: 30px;" class="text-uppercase font-weight-bold pl-3">1 platform</span><br />
					<span style="font-size: 30px;" class="pl-3">for all your</span><span style="font-size: 30px;" class="font-weight-bold"> Logistic Needs !</span>
				</div>
			</div>

			<div *ngIf="showReset || showVerification || showChangePassword" class="position-relative h-100 w-100">
				<div class="position-absolute" style="bottom: 30%;left: 10%;line-height: 1;">
					<span style="font-size: 40px;" class="text-uppercase font-weight-bold">lost password</span><br />
					<span style="font-size: 24px;" class="">changing new password ...</span><br />
				</div>
			</div>
		</div>
		<div class="col-md-6" style="padding: 6.5% 12%;">
			<div class="card position-relative" style="border-radius: 20px;border: none;">
				<div class="logo-overlay"></div>
				<div class="d-flex flex-column">
					<div class="pt-3 pl-4">
						<img src="/assets/img/logo2.png" width="110" alt="logo" />
					</div>
					<div class="d-flex flex-column px-5 align-items-center" [ngClass]="{'pt-5': showReset || showChangePassword, 'pb-3': showReset, 'mt-3': showReset}">
						<span *ngIf="showLogin" class="text-uppercase mb-3" style="font-size: 20px;letter-spacing: 1px;font-weight: 600;">login</span>
						<span *ngIf="showReset" class="text-uppercase mb-3" style="font-size: 20px;letter-spacing: 1px;font-weight: 600;">sms verification code</span>
						<span *ngIf="showVerification" class="text-uppercase mb-1 mt-4" style="font-size: 20px;letter-spacing: 1px;font-weight: 600;">code verification</span>
						<span *ngIf="showChangePassword" class="text-uppercase mb-3" style="font-size: 20px;letter-spacing: 1px;font-weight: 600;">reset new password</span>
						<span class="d-block" *ngIf="showVerification">SMS has been sent to: 0160000000</span>
						<div *ngIf="showVerification" class="d-flex flex-row">
							<a class="text-primary" (click)="showResetOnTap()" href="javascript:void(0);">Modify number</a>
							<span class="px-3">or</span>
							<a class="text-primary" href="#">Re-sent SMS ?</a>
						</div>
						<div *ngIf="showLogin || showReset" class="input-prefix">
							<span class="prefix text-muted">601</span>
                <input type="text" style="border-radius: 20px;" class="form-control input-field mb-3" name="" value="" [(ngModel)]="loginName" />
            </div>
						<input *ngIf="showLogin" type="password" style="border-radius: 20px;" class="form-control mb-3" name="" value="" placeholder="Password" [(ngModel)]="password" />
						<div *ngIf="showVerification" class="d-flex flex-row my-3">
							<input type="text" class="form-control mx-2" name="" maxlength="1" value="" />
							<input type="text" class="form-control mx-2" name="" maxlength="1" value="" />
							<input type="text" class="form-control mx-2" name="" maxlength="1" value="" />
							<input type="text" class="form-control mx-2" name="" maxlength="1" value="" />
						</div>
						<input *ngIf="showChangePassword" type="password" style="border-radius: 20px;" class="form-control mb-3" name="" value="" placeholder="New password" />
						<input *ngIf="showChangePassword" type="password" style="border-radius: 20px;" class="form-control mb-3" name="" value="" placeholder="Re-confirm new password" />

						<button *ngIf="showLogin" (click)="login()" type="button" style="border-radius: 20px;" class="btn btn-primary btn-block" name="button">Login</button>
						<button *ngIf="showReset" (click)="showVerificationOnTap()" type="button" style="border-radius: 20px;" class="btn btn-primary btn-block" name="button">Get Code</button>
						<button *ngIf="showVerification" (click)="showChangePasswordOnTap()" type="button" style="border-radius: 20px;" class="btn btn-primary btn-block" name="button">Submit</button>
						<button *ngIf="showChangePassword" (click)="showLoginOnTap()" type="button" style="border-radius: 20px;" class="btn btn-primary btn-block" name="button">Confirm</button>
					</div>
					<div class="d-flex flex-column mt-4 mb-5 align-items-center">
						<a class="text-primary" *ngIf="showLogin" (click)="showResetOnTap()" href="javascript:void(0);">Forgot password ?</a>
						<a class="text-primary" *ngIf="showReset || showVerification || showChangePassword" (click)="showLoginOnTap()" href="javascript:void(0);">Go back Login ?</a>
					</div>
					<div class="d-flex flex-column px-5 mb-4 align-items-center">
						<span class="text-muted mb-2" style="font-size: 14px;">Don't have an account?</span>
						<button [routerLink]="['/register/']" type="button" style="padding: 3px 30px;border-radius: 20px;" class="btn btn-secondary btn-sm text-uppercase font-weight-bold" name="button">register now !</button>
					</div>
					<div class="d-block text-right mb-3 pr-4">
						<span class="pr-2">Need Help ? </span>
						<i class="fab fa-2x fa-whatsapp"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
