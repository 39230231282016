<!-- <div class="login-reg-panel">

		<div class="register-info-box">
			<h2>Already have an account?</h2>
			<p>Login now!</p>
      <button type="button" class="btn-login" name="button" [routerLink]="['/login/']">Login</button>
		</div>

		<div class="white-panel">
			<div class="login-show">
				<h2>REGISTER</h2>
				<input type="text" placeholder="FULLNAME" />
				<input type="text" placeholder="EMAIL ADDRESS">
				<input type="password" placeholder="PASSWORD">
				<input type="password" placeholder="CONFIRM PASSWORD">
				<input type="text" placeholder="MOBILE NUMBER" />
				<select class="register-select" name="">
					<option value="">Please select your state</option>
				</select>
				<input type="text" placeholder="REFERRAL CODE (Optional)" />
				<input type="button" value="Register">
			</div>
		</div>
	</div> -->
<head>
	<script type="text/javascript">
	$(document).ready(function(){
	    $('input[type="radio"]').click(function(){
	        var inputValue = $(this).attr("value");
	        var targetBox = $("." + inputValue);
	        $(".box").not(targetBox).hide();
	        $(targetBox).show();
	    });
	});
	</script>

	</head>
	<div>

		<div class="page-content">

			<div class="form-v6-content">

				<div class="form-left">
	                <div class="desktopview">
					<img src="assets/img/3banner2.jpg" alt="form">
	                </div>

	                <div class="mobileview">
	                <img src="assets/img/3banner-m2.jpg" alt="form">

	                </div>

				</div>

				<form class="form-detail " action="#" method="post">

	                <h2 style="line-height: 28px; color:#0089D0 ">ARRIIVAL CORPORATE ACCOUNT </h2>

	                <div align="center" style="padding-left:30px; width: 85%; font-size:18px; font-weight: normal; line-height:22px; padding-bottom:0px; color:#333; margin-top:-10px">You are invited to join us! Sign Up Now !</div>


	                <div style="padding-left: 10px; padding-right: 10px;">
	                    <div style="font-size:13px; margin-top: 15px; font-weight: bold; color: red">All fields requested!</div>
	                    <div>
	                    <span class="txtlabel">Full Name: </span><input type="text" class="genfield" name="loginName" [(ngModel)]="loginName">
	                    </div>

	                    <div>
	                    <span class="txtlabel">Mobile no. </span> <span style="color:#999">+60 </span><input type="text" class="mobilefield" name="mobileField" [(ngModel)]="mobileField" maxlength = "12" >
	                    </div>

	                    <div style="margin-left:100px; background-color:none;">
	                    <input type="text" class="sms-code" name="smsCode" [(ngModel)]="smsCode" placeholder="Verification Code">
	                    <input type="button" class="btn" (click)="getCode()" value="Get SMS Code">
	                    </div>

	                    <div style="margin-top: 10px;">
	                    <div class="txtlabel">Set Password  <span style="font-size:12px;font-weight: bold;color:coral">note: min. 6 numbers</span></div>
	                    <input type="password" placeholder="password" class="pswd" name="userPassword" [(ngModel)]="userPassword">
	                    <input type="password" placeholder="confirm again" class="pswd" name="confirmUserPassword" [(ngModel)]="confirmUserPassword">
	                    </div>

	                    <div style="height: 10px;">&nbsp;</div>

	                    <div>
	                    <span class="txtlabel">Your Email:</span><input type="text" name="your-email" class="genfield" required>
						</div>
						<div>
							<span class="txtlabel">Postcode:</span><input type="text" name="userCurrentArea" [(ngModel)]="userCurrentArea" class="genfield" required>
							</div>
	                    <div style="margin-top:10px;">
	                        <span class="txtlabel" style="font-weight: bold;">Account by: </span>
	                        <input type="radio" name="account" id="account" value="box" checked
	                               >
	                        <span style="color:#333; font-size: 14px; font-weight: bold;" >Company</span>

	                        <input type="radio" name="account" id="account" value="hide personal">
	                        <span style="color:#333; font-size: 14px; font-weight: bold;">Personal</span>


	                    </div>
	                    <div class="box">
	                    <span class="txtlabel">Company:</span><input type="text" name="company-name" class="genfield">
	                    </div>

	                    <div class="box">
	                    <span class="txtlabel">Logistic Person in charge Name:</span>
	                        <input type="text" name=" Logistic person in charge name" class="logisticname">
	                        <br>
	                        <span class="txtlabel">Logistic Person in charge Tel:</span>
	                        <input type="text" name="Logistic person in charge tel" class="logistictel">
	                    </div>

	                    <div>
	                    <span class="txtlabel">Pickup Address:</span>
	                        <input type="text" name="address1" class="addr">
	                    <input type="text" name="address2" class="addr">
	                    </div>

	                    <div style="margin-top: 10px; ">
	                    <span class="txtlabel" style="color:darkorange; font-weight: bold;">Register with Printer:</span><br class="br">
	                        <input type="radio" name="printer" id="printer" value="No Printer" checked style="color:#333; ">
	                        <span style="font-size:14px; color:  darkorange">No</span>

	                        <input type="radio" id="printer" name="printer" value="Yes Printer" ><span style="font-size:14px; color: darkorange">Yes</span>

	                    </div>




	                </div>
	                <div></div>

	                <div style="height: 10px;">&nbsp;</div>
	                <div class="form-row-last">
						<input type="submit" name="register" class="register" value="Register" (click)="registerAccount()">
					</div>


	            </form>

									<div align="center" class="footertxt" style="height:60px;bottom:-65px;">

											<div class="support">
													Call support <a href="tel:0102464991" style="color:orange; text-decoration: none; font-weight: bold;"> 010 246 4991</a> for Help
											</div>

											&copy; 2020 Arriival Technologies Sdn Bhd, all right reserved. <a href="https://www.artcromedia.com" style="color:#ccc; text-decoration:none;" >Designed by Artcromedia Design</a>
									<p>&nbsp;</p>
									</div>
			</div>


		</div>
	</div>
