import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, retry, first } from "rxjs/operators";
import { throwError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-order-track',
  templateUrl: './order-track.component.html',
  styleUrls: ['./order-track.component.css']
})
export class OrderTrackComponent implements OnInit {

  public orderList: Array<any> = [];

  constructor(public _http: HttpClient, private routeInfo: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.view_and_track();
  }

  view_and_track() {
    var formData: any = new FormData();

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));
    formData.append("orderNo", localStorage.getItem('"orderNo'));

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/order/selectOrder `,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          console.log(respObj['data']);
//          this.orderList = respObj['data'];
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

}
