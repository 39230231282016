<div class="content col-md-9 col-lg-12 pt-3">
  <div class="row">
    <div class="col-md-5 offset-md-1">
      <div class="row pt-4">
        <div class="col-md-12">
          <h2>My Referral Link</h2>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <span style="font-size: 18px;font-weight: 600;">What is our Referral Program?</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Referral program is simply a process in which you become Arriival Agent and you can get your Arriival Point after you successfully refer us a new user.
            </div>
          </div>
        </div>
      </div>

      <br /><br />
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <span style="font-size: 18px;font-weight: 600;">How to earn?</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Use your own Referral Link, copy and share to other new user.
            </div>
          </div>
        </div>
      </div>

      <br /><br />
      <div class="row">
        <div class="col-md-12 text-center">
          <span style="font-weight: 600;font-size: 18px;" class="text-uppercase">Copy and paste</span><br />
          <div class="mt-2">
            <span>Copy below link and click social icon and share your referral link.</span>
          </div>
          <div class="referral-link-wrapper mt-2">
            <span class="referral-link" data-toggle="tooltip" data-placement="bottom" title="Click to copy" style="border-bottom: 1px dashed #AAAAAA;cursor: pointer;">https://arriival.com/?referral=thisIsMyRefCode</span>
            <i class="ml-1 far fa-copy" style="cursor: pointer"></i>
          </div>
          <div class="pt-4">
            <i class="mx-1 fab fa-2x fa-instagram"></i>
            <i class="mx-1 fab fa-2x fa-facebook-square"></i>
            <i class="mx-1 fab fa-2x fa-linkedin"></i>
            <i class="mx-1 fab fa-2x fa-twitter-square"></i>
            <i class="mx-1 fab fa-2x fa-whatsapp-square"></i>
            <i class="mx-1 fab fa-2x fa-facebook-messenger"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 pt-5">
      <img src="/assets/img/guide-referral-reward.png" style="width: 100%;object-fit: contain;" alt="" />
    </div>
  </div>
</div>




<!-- <h2>Referral Program</h2>

<div class="row">
  <div class="col-md-8 offset-md-2 text-center redeem-box">
    <p class="font-weight-bold">Your Referral Link</p>
    <div class="row">
      <div class="col-md-8 offset-md-2 pt-3">
        <div class="referral-link-wrapper">
          <span class="referral-link" data-toggle="tooltip" data-placement="bottom" title="Click to copy" style="border-bottom: 1px dashed #AAAAAA;cursor: pointer;">https://arriival.com/?referral=thisIsMyRefCode</span>
          <i class="ml-1 far fa-copy" style="cursor: pointer"></i>
        </div>
        <div class="pt-4">
          <i class="mx-1 fab fa-2x fa-instagram"></i>
          <i class="mx-1 fab fa-2x fa-facebook-square"></i>
          <i class="mx-1 fab fa-2x fa-linkedin"></i>
          <i class="mx-1 fab fa-2x fa-twitter-square"></i>
          <i class="mx-1 fab fa-2x fa-whatsapp-square"></i>
          <i class="mx-1 fab fa-2x fa-facebook-messenger"></i>
        </div>
      </div>
    </div>
  </div>
</div> -->
