<div class="content pt-3">
  <div class="row">
    <div class="col-md-6">
      <h2>Statement</h2>
      <div class="d-flex pl-2">
        <span style="width: 125px;" class="invoice-to-info invoice-to-label">Company Name</span>
        <span class="mr-1 invoice-to-info double-column">:</span>
        <span class="text-uppercase invoice-to-info">sun ceramic sdn bhd</span>
      </div>
      <div class="d-flex pl-2">
        <span style="width: 125px;" class="invoice-to-info invoice-to-label">Register ID HP</span>
        <span class="mr-1 invoice-to-info double-column">:</span>
        <span class="invoice-to-info">60123380984</span>
      </div>
      <div class="d-flex pl-2">
        <span style="width: 125px;" class="invoice-to-info invoice-to-label">Date</span>
        <span class="mr-1 invoice-to-info double-column">:</span>
        <span class="invoice-to-info font-weight-bold">01/02/2020 ~ 29/02/2020</span>
      </div>
      <div class="d-flex pl-2">
        <span style="width: 125px;" class="invoice-to-info invoice-to-label">Total entries</span>
        <span class="mr-1 invoice-to-info double-column">:</span>
        <span class="invoice-to-info">6</span>
      </div>
    </div>
    <div class="col-md-6 text-right pt-2" #noprint>
      <button type="button" class="btn btn-primary btn-sm" style="padding: 5px 25px;border-radius: 20px;" (click)="generatePDF()">Export as PDF</button>
      <h4 class="mt-4 font-weight-bold">Balance Credit: RM 465.20</h4>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <div class="d-flex flex-row align-items-start">
        <div class="d-flex flex-row mr-3" style="width: 30%;">
          <select class="form-control mr-1" name="" placeholder="o">
            <option value="" disabled selected hidden>Operation Type</option>
            <option value=""></option>
          </select>
          <input type="text" class="form-control ml-1" placeholder="Order Number" name="" value="" />
        </div>

        <div class="d-flex flex-row mr-2" style="max-width: 45%;">
          <div class="d-flex flex-row mr-1">
            <label class="mr-1" style="white-space: nowrap;line-height: 2.5" for="">Date Start</label>
            <input type="date" name="" class="form-control" value="" style="width: 166px;" />
          </div>
          <div class="d-flex flex-row ml-1">
            <label class="mr-1" style="white-space: nowrap;line-height: 2.5;" for="">Date End</label>
            <input type="date" name="" class="form-control" value="" style="width: 166px;" />
          </div>
        </div>

        <button type="button" class="btn btn-primary btn-sm" style="padding: 8px 20px;border-radius: 20px;" name="button">Search</button>

        <div class="d-flex flex-row ml-4">
          <span style="line-height: 2.5;">show</span>
          <select class="form-control mx-1" name="">
            <option value="">10</option>
          </select>
          <span style="line-height: 2.5;">entries</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-md-12">
      <div class="d-flex flex-row py-3 px-2 bg-statement">
        <div class="flex-fill column-width">
          <span class="t-title">Transaction Date/Time</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Order No</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Operation Types</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Detail</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Balance Before</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Operating Amount</span>
        </div>
        <div class="flex-fill column-width">
          <span class="t-title">Balance After</span>
        </div>
      </div>

      <div *ngFor="let statement of statementData[0].statements | slice: (page-1) * pageSize : page * pageSize; let i = index" class="d-flex flex-row mini-font statement-border-bottom py-3 px-2">
        <div class="flex-fill column-width"> <!--[ngClass]="{'page-number':i % 60 == 0}"-->
          <span>{{ statement.orderTime }}</span>
        </div>
        <div class="flex-fill column-width">
          <span>{{ statement.documentNo }}</span>
        </div>
        <div class="flex-fill column-width">
          <span>{{ statement.type }}</span>
        </div>
        <div class="flex-fill column-width">
          <span>{{ statement.type }}</span>
        </div>
        <div class="d-flex flex-fill column-width">
          <span class="pr-2">{{ statementData[0].currency }}</span>
          <span>{{ statement.debit }}</span>
        </div>
        <div class="d-flex flex-fill column-width" [ngClass]="(statement.credit > 0) ? 'text-success' : 'text-danger'">
          <span class="pr-2">{{ statementData[0].currency }}</span>
          <span>{{ statement.credit }}</span>
        </div>
        <div class="d-flex flex-fill column-width">
          <span class="pr-2">{{ statementData[0].currency }}</span>
          <span>{{ statement.balance }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-6 text-left">
      <span class="font-weight-bold">Showing 1 to 6 of 6 entries</span>
    </div>
    <div class="col-md-6 d-flex flex-row justify-content-end">
      <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="statementData[0].statements.length"></ngb-pagination>
    </div>
  </div>

  <!-- Print Out Section -->
  <div id="contentToConvert" style="position: absolute;width: 100vw;left: -5000px;top: 0;">
    <div class="logo-overlay"></div>
    <div class="row">
      <div class="col-md-6">
        <img src="/assets/img/logo2.png" width="200" alt="" /><br />
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Company Name</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="text-uppercase invoice-to-info">sun ceramic sdn bhd</span>
        </div>
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Contact Name</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="invoice-to-info">Contact Person Name</span>
        </div>
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Register ID HP</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="invoice-to-info">60123380984</span>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <div>
          <span class="font-weight-bold company-name-size">ARRIIVAL TECHNOLOGIES SDN BHD</span><span> (1294634-V)</span>
        </div>
        <div>
          <span>No.9, Jalan YTN 3, Industri Desa Aman, 52200 Kuala Lumpur.</span>
        </div>
        <div>
          <span class="mr-3">Tel: +603-2107-0187</span>
          <span class="mr-3">Email: support@arriival.com</span>
          <span>www.arriival.com</span>
        </div>
        <div class="mt-4">
          <span class="font-weight-bold text-uppercase statement-label">statement</span><br />
          <span class="font-weight-bold text-uppercase font-size-20">01/02/2020 ~ 29/02/2020</span><br />
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12 text-right">
        <span class="font-italic">Showing 1 to 6 of 6 entries</span>
      </div>
    </div>

    <div class="d-flex flex-row py-3 px-2 bg-statement">
      <div class="flex-fill column-width">
        <span class="t-title">Transaction Date/Time</span>
      </div>
      <div class="flex-fill column-width">
        <span class="t-title">Order No</span>
      </div>
      <div class="flex-fill column-width">
        <span class="t-title">Operation Types</span>
      </div>
      <div class="flex-fill column-width">
        <span class="t-title">Detail</span>
      </div>
      <div class="flex-fill column-width">
        <span class="t-title">Balance Before</span>
      </div>
      <div class="flex-fill column-width">
        <span class="t-title">Operating Amount</span>
      </div>
      <div class="flex-fill column-width">
        <span class="t-title">Balance After</span>
      </div>
    </div>

    <div *ngFor="let statement of statementData[0].statements; let i = index" class="d-flex flex-row mini-font statement-border-bottom py-3 px-2">
      <div class="flex-fill column-width"> <!--[ngClass]="{'page-number':i % 60 == 0}"-->
        <span>{{ statement.orderTime }}</span>
      </div>
      <div class="flex-fill column-width">
        <span>{{ statement.documentNo }}</span>
      </div>
      <div class="flex-fill column-width">
        <span>{{ statement.type }}</span>
      </div>
      <div class="flex-fill column-width">
        <span>{{ statement.type }}</span>
      </div>
      <div class="d-flex flex-fill column-width">
        <span class="pr-2">{{ statementData[0].currency }}</span>
        <span>{{ statement.debit }}</span>
      </div>
      <div class="d-flex flex-fill column-width" [ngClass]="(statement.credit > 0) ? 'text-success' : 'text-danger'">
        <span class="pr-2">{{ statementData[0].currency }}</span>
        <span>{{ statement.credit }}</span>
      </div>
      <div class="d-flex flex-fill column-width">
        <span class="pr-2">{{ statementData[0].currency }}</span>
        <span>{{ statement.balance }}</span>
      </div>
    </div>

    <div class="d-flex flex-row mt-3">
      <div class="column-width"></div>
      <div class="column-width"></div>
      <div class="column-width"></div>
      <div class="column-width"></div>
      <div class="column-width"></div>
      <div class="d-flex font-weight-bold total-balance-width flex-fill justify-content-end font-size-20">
        <span>Balance Credit:</span>
        <span class="pl-2 pr-2">{{ statementData[0].currency }}</span>
        <span class="pr-2">{{ statementData[0].total }}</span>
      </div>
    </div>
  </div>

</div>
