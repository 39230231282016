<div class="content col-mg-9 col-lg-12 pt-3">
  <form>
      <div class="container">
          <div class="searchbgbox">
              <!--<div class="col-md-1"></div>--->
              <div class="col-md-12">
                  <div class="row">

                      <div class="col-md-2 col-sm-2 col-xs-6"> <span class="searchtxt bold">Pickup from</span>
                          <input type="text" required placeholder="Postcode" class="form-control field4postcode" name="Pick up">
                      </div>
                      <div class="col-md-2 col-sm-2 col-xs-6"> <span class="searchtxt bold">Receiver to</span>
                          <input type="text" required placeholder="Postcode" class="form-control field4postcode" name="Drop Off">
                      </div>
                      <div class="col-md-1 col-sm-2 col-xs-5">
                          <span class="searchtxt bold">Weight(KG)</span>
                          <input type="text" required placeholder="1.0" class="form-control  field4weight" name="Weight">
                      </div>

                      <div class="col-md-2 col-sm-2 col-xs-7">
                          <span class="searchtxt bold">No. Item / Location </span>
                          <input type="text" required placeholder="Qty" class="form-control field4qty" name="qty">

                      </div>

                      <div class="col-md-3 col-sm-3 col-xs-12">
                          <span class="searchtxt bold">Delivery Type / Options</span>
                          <select class="form-control" name="Type-Delivery">
                                  <option>Auto Suggest</option>
                                  <option>Express Delivery</option>
                                  <option>MultiStop Delivery</option>
                                  <option>Courier Service</option>
                                  <option>Cargo Service</option>
                                  <option>Air Freight Service</option>
                                  <option>International Delivery</option>


                              </select>
                      </div>
                      <div class="col-md-2 col-xs-12">
                          <button class="btn btn-success mt20"> <i class="fa fa-calculator"></i> Get Quote </button>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-3 col-xs-12 mt10">
                          <span class="searchtxt bold ">
                      Frozen Product ?
                      </span> &nbsp;&nbsp;
                          <label><input  type="radio" name="Frozen Product" (click)="toggleFrozenProduct(false)" value="No" checked /> No </label>
                          <label><input type="radio" name="Frozen Product" (click)="toggleFrozenProduct(true)" value="Yes" /> Yes   </label>

                      </div>

                      <div class="col-md-8 col-xs-12">
                          <!-- this message which tick "yes" frozen product-->
                          <h5 class="noticefrozen" *ngIf="frozenProduct">
                              For those Frozen Product, Delivery service in Klang Valley only
                          </h5>

                      </div>

                  </div>

              </div>
              <div class="col-md-2 col-xs-12">
                  <div class="heroplace-">
                      <img src="/assets/img/images-quote/img-arriival-heroman.png" class="hero">
                  </div>
              </div>

          </div>

              <div class="container">
              <div class="row">
              <div class="tdmsg"><i class="fa fa-lightbulb" style="color:orange"></i> Price shown are estimated based on postcodes, actual pricing will be shown upon entering exact address.</div>
              </div>
              </div>

          <!-- truck location with postcode-->
          <div class="row  mt30">
              <div class="col-md-1"></div>
              <div class="col-md-2 col-xs-5 text-center"  >
                  <img src="/assets/img/images-quote/pickup-from.png" class="iconpoint" >
                  <span>Petaling Jaya</span>
              </div>
              <div class="col-md-6 col-xs-2 linepoint"></div>

              <div class="col-md-2 col-xs-5 text-center"  >
                  <img src="assets/img/images-quote/dropoff.png" class="iconpoint" >
                  <span>Kuala Lumpur</span>
              </div>
              <div class="col-md-1"></div>

          </div>
      </div>



      <div class="container">
          <div class="row mt20">
              <div class="col-md-12" align="center">
                  <span>Quote for:</span>
                  <h3 style="margin-top:0px;">Express Delivery</h3>
              </div>
          </div>

      </div>

      <div class="container">
          <!-- result price table -->
          <div class="row mt30">

              <!-- table of info -->
              <div class="table-responsive ">
                  <table class="table table-striped table-condensed">
                      <thead>
                          <tr>
                              <th scope="col" class="tdno"></th>
                              <th scope="col" class="tdtype ">Delivery Method</th>
                              <th scope="col" class="tdinfo "></th>
                          </tr>
                      </thead>
                      <tbody>

                          <tr>
                              <th scope="row">
                                  <div >1</div>
                              </th>
                              <td >
                                  <div >
                                      <div class=" col-md-4 tdclear" >
                                          <a href="" target="_blank">
                                              <img src="/assets/img/images-quote/icons-frozen-lorry-gray.png" class="tdIcon">
                                          </a>
                                      </div>
                                      <div class="col-md-8 tdSpace tdclear text-left"><b class="tdtypetitle">Chilled Lorry</b>
                                      <br>
                                      <span class="tdtypetitle">275x152x152cm</span>
                                      </div>

                                  </div>
                              </td>
                              <td>
                                  <div class="tdSpace">
                                      <div class="col-md-3 tdclear" >
                                          <img src="/assets/img/images-quote/icon110-clock.png" class="tdIconsmall ">
                                          <div class="icotxt">within 3 Hours</div>
                                      </div>
                                      <div class="col-md-3 tdclear">
                                          <img src="/assets/img/images-quote/icon110-money.png" class="tdIconsmall ">
                                          <div class="icotxt">RM 000.00</div>
                                      </div>
                                      <div class="col-md-3 tdclear">
                                          <img src="/assets/img/images-quote/icon110-weight.png" class="tdIconsmall ">
                                          <div class="icotxt">1 Ton</div>

                                      </div>

                                      <div class="col-md-3  ">
                                          <div class="tdSpaceButton ">
                                      <a class="btn btn-success" href="" role="button"><i class="fa fa-edit"></i> Order Now</a>
                                  </div>

                                      </div>

                                  </div>
                              </td>


                          </tr>







                      </tbody>
                  </table>

              </div>
              </div>
              </div>
  </form>

  <div class="container sectionitem mb30">
      <div class="row">
          <div class="col-md-6 mb20">
              <div><h4>Single Delivery</h4></div>
              <div>3-hours same day & next day delivery service. You can check our rates by entering your pickup and delivery point. Our services are covered throughout Klang-Valley and major zones in Selangor. </div>

          </div>

          <div class="col-md-6 mb20">
              <div><h4>Bulk Delivery</h4></div>
              <div>Same day delivery bulk orders starting from MYR 7 per-delivery, with a minimum of 5 shipments and 15KG per pick-up. Save more with Credit and enjoy our best price for bulk orders. For more info contact us at <a href="mailto:support@arriival.com">support@arriival.com </a></div>

          </div>

      </div>


  </div>

  <!-- popup info-->
  <!-- weight info -->
  <div class="modal fade" id="weightPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                  <h4 class="modal-title">Weight Info </h4>
              </div>
              <div class="modal-body" style="padding-left:30px; padding-right:30px;">
                  <div class="modalBody">
                      <div class="titleSetModal" style="margin-top:0;">Maximum Weight </div> 60KG
                      <div class="titleSetModal">Maximum Dimension </div>
                      <div style="font-weight:bold; color:#48555f; margin-bottom:20px;">70cm (L) x 50cm (W) x 50cm (H). </div>
                      Parcel weight will be determined by either actual or volumetric weight (VW) or whichever is higher. VM also applies if either one dimension is above 50cm.
                      <div class="titleSetModal">Calculation Formula </div> VW Formula = (height x width x length) cm / 5000
                      <div class="titleSetModal">Why ? </div> Suitable for bulkier items or when you need extra care. Sending flowers, fragile items or items that doesn't fit on bikes will be an ideal choice for your delivery via ZeptoCar.
                      <br><br>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <p>&nbsp;</p>
</div>
