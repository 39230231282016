import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  displayFullName:String = "";
  displayTelephone:String = "";

  constructor() { }

  ngOnInit(): void {
    this.displayFullName = localStorage.getItem("username");
    this.displayTelephone = localStorage.getItem("usertel");
  }

}
