import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, retry, first } from "rxjs/operators";
import { throwError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from 'sweetalert2';

import $ from 'jquery'
declare var $: $

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  datas: any[] = [{
    "id": "1",
    "checkbox": "<input type='checkbox' name='' value='' />",
    "orderDateTime": "10-11-2020 17:33:56",
    "trackingNo": "ATC101120200001",
    "contactNo": "+60136582047",
    "senderName": "Mary",
    "senderAddress": "SS5, Petaling Jaya, Selangor, Malaysia",
    "receiverName": "Colin",
    "receiverAddress": "SS2, Petaling Jaya, Selangor, Malaysia",
    "typeOfDelivery": "Express Delivery",
    "status": "Completed"
  }, {
    "id": "2",
    "checkbox": "<input type='checkbox' name='' value='' />",
    "orderDateTime": "10-11-2020 17:33:56",
    "trackingNo": "ATC101120200002",
    "contactNo": "+60173645284",
    "senderName": "Connie",
    "senderAddress": "SS1, Petaling Jaya, Selangor, Malaysia",
    "receiverName": "Amy",
    "receiverAddress": "SS6, Petaling Jaya, Selangor, Malaysia",
    "typeOfDelivery": "Express Delivery",
    "status": "Cancelled"
  }];

  orderDateTime: Boolean = true;
  trackingOrderNo: Boolean = true;
  senderNameAddress: Boolean = true;
  senderCountry: Boolean = true;
  receiverNameAddress: Boolean = true;
  receiverHPNo: Boolean = true;
  receiverCountry: Boolean = true;
  orderType: Boolean = true;
  orderStatus: Boolean = true;

  // Order List
  public orderList: Array<any> = [];
  public pendingOrderList: Array<any> = [];
  public completedDeliveryOrderList: Array<any> = [];
  public failedDeliveryOrderList: Array<any> = [];
  public inDeliveryOrderList: Array<any> = [];
  public cancelledOrderList: Array<any> = [];

  /**
   * Order List numbers
   */
  public allOrdersNumbers: any = 0;
  public orderPendingNumbers: any = 0;
  public orderCompletedNumbers: any = 0;
  public orderFailedNumbers: any = 0;
  public orderInDeliveryingNumbers: any = 0;
  public orderCancelledNumbers: any = 0;

  newPaymentPass: String;
  oldPaymentPass: String;

  constructor(public _http: HttpClient, private routeInfo: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.judgePaymentPassword();
    this.getAllOrders();
  }

  cancelButtonOnClick(orderNo): void {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'Do you really want to cancel this order ?',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#2778C4'
    }).then(function (isConfirm) {
      if (isConfirm) {
        var formData: any = new FormData();

        formData.append("uuid", localStorage.getItem('uuid'));
        formData.append("zdtype", "web");
        formData.append("token", localStorage.getItem('token'));
        formData.append("orderNo", orderNo);

//        this._http
//          .post(
//            `http://uml.arriival.com/qmcs-u-api/order/cancel `,
//            formData
//          )
//          .pipe(
//            catchError((err: HttpErrorResponse) => {
//              return throwError("Something bad happened; please try again later.");
//            }) // then handle the error
//          )
//          .subscribe((respObj) => {
//            if (respObj["code"] == 200) {
//              Swal.fire({
//                title: 'Notice',
//                text: 'The order had been cancelled',
//                icon: 'warning',
//                confirmButtonText: 'Yes',
//                showCancelButton: true,
//                cancelButtonText: 'No',
//                cancelButtonColor: '#2778C4'
//              });
//            } else {
//              alert(respObj["enMsg"]);
//            }
//          });
      } else {
        // do nothing
      }
    });
  }

  judgePaymentPassword() {
//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/pay/judgePayPwd?uuid=${localStorage.getItem("uuid")}&zdtype=web&token=${localStorage.getItem("token")}`,
//        ""
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        console.log(respObj);
//        if (respObj["code"] == 200) {
//          // $('#paymentPasswordModal').modal('show');
//        } else {
//          $('#paymentPasswordModal').modal('show');
//        }
//      });
  }

  getAllOrders() {
    var formData: any = new FormData();

    this.orderList = [];
    this.pendingOrderList = [];
    this.completedDeliveryOrderList = [];
    this.failedDeliveryOrderList = [];
    this.inDeliveryOrderList = [];
    this.cancelledOrderList = [];

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("type", 0);
    formData.append("limitStart", 1);
    formData.append("pageSize", 10);
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/order/selectOrderList `,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          console.log(respObj['data']);
//
//          this.orderList = respObj['data'];
//
//          respObj['data'].forEach(element => {
//            if (element['orderStatus'] == 7) {
//              this.orderPendingNumbers += 1;
//              this.pendingOrderList.push(element);
//            } else if (element['orderStatus'] == 8) {
//              this.orderCompletedNumbers += 1;
//              this.completedDeliveryOrderList.push(element);
//            } else if (element['orderStatus'] == 12 || element['orderStatus'] == 13 || element['orderStatus'] == 14) {
//              this.orderFailedNumbers += 1;
//              this.failedDeliveryOrderList.push(element);
//            } else if (element['orderStatus'] == 7) {
//              this.orderInDeliveryingNumbers += 1;
//              this.inDeliveryOrderList.push(element);
//            } else if (element['orderStatus'] == 10 || element['orderStatus'] == 11) {
//              this.orderCancelledNumbers += 1;
//              this.cancelledOrderList.push(element);
//            }
//          });
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  submitPaymentPassword() {
//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/pay/setPayPwd?newPwd=${this.newPaymentPass}&oldPwd=${this.oldPaymentPass}&type=0&uuid=${localStorage.getItem("uuid")}&zdtype=web&token=${localStorage.getItem("token")}`,
//        ""
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        console.log(respObj);
//        if (respObj["code"] == 200) {
//          alert(respObj["enMsg"]);
//          localStorage.setItem("payPassword", this.newPaymentPass.toString());
//          $('#paymentPasswordModal').modal('hide');
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  view_and_track(orderNumber) {
    console.log(orderNumber);

    var formData: any = new FormData();

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));
    formData.append("orderNo", orderNumber);

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/order/selectOrder `,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          console.log(respObj['data']);
//          localStorage.setItem('orderNo', orderNumber);
//          localStorage.setItem('orderTrackingCode', respObj['data']['orderTrackingCode']);
//
//          this.router.navigate(['/main/order/track/']);
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  printWayBill(wayBillNo) {
    window.open("http://h5.arriival.com/waybill.html?id=" + wayBillNo);
  }

}
