<nav class="navbar navbar-primary sticky-top bg-primary flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">
    <div class="logo-wrapper">
      <img src="assets/img/logo-01.png" alt="" />
    </div>
  </a>
  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <ul class="navbar-nav px-3 navbar-nav-hide">
    <li class="nav-item text-nowrap" style="width: 250px;margin-right: 0px;position: absolute;left: 350px;">
      <input type="text" class="form-control" name="" value="" placeholder="Enter your tracking number.." />
    </li>
    <li class="nav-item text-nowrap" style="position: absolute;left: 610px;">
      <button type="button" class="btn btn-light" [routerLink]="['/main/order/track']" name="button">Search</button>
    </li>
    <!-- <li class="nav-item text-nowrap">
      <a class="nav-link" [routerLink]="['/main/dashboard/']">Dashboard</a>
    </li> -->
    <li class="nav-item text-nowrap">
      <a class="nav-link" [routerLink]="['/main/quote/']">Get Quote</a>
    </li>
    <!-- <li class="nav-item text-nowrap">
      <a class="nav-link" [routerLink]="['/main/delivery/single/']">New Job</a>
    </li> -->
    <!-- <li class="nav-item text-nowrap">
      <a class="nav-link" [routerLink]="['/main/user/profile/']">My Profile</a>
    </li> -->
    <!-- <li class="nav-item text-nowrap">
      <a class="nav-link" [routerLink]="['/main/redeem/']">Redeem</a>
    </li> -->
    <li class="nav-item">
      <div class="dropdown">
        <a href="javascript:void(0);" class="nav-link dropdown-toggle" data-toggle="dropdown" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false">RM {{ displayOwnCredit }}</a>
        <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          John Doe
        </button> -->
        <div class="dropdown-menu dropdown-menu-right position-absolute" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" [routerLink]="['/main/credit/buy/']">Top Up</a>
          <a class="dropdown-item" [routerLink]="['/main/statement']">Credit</a>
        </div>
      </div>
    </li>
    <li class="nav-item">
      <div class="dropdown">
        <a href="javascript:void(0);" class="nav-link dropdown-toggle" data-toggle="dropdown" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false">
          <img width="25" class="rounded-circle" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcT9rpl4EUmQD4YPmAjS76iCXSkHqAJg8qvyA2ueuqN2nxYtjZBNI4-J9gJKFSO0nwu5hzpiRugqx9oPzuqcFjs-4Wm3PL-dvGingHO-&usqp=CAU&ec=45707746" /> {{ displayUsername }}
        </a>
        <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          John Doe
        </button> -->
        <div class="dropdown-menu dropdown-menu-right position-absolute" aria-labelledby="dropdownMenuButton">
          <!-- <a class="dropdown-item" [routerLink]="['/main/user/profile/']">My Profile</a> -->
          <!-- <a class="dropdown-item" [routerLink]="['/main/statement']">Credit</a> -->
          <a class="dropdown-item" href="#">Sign out</a>
        </div>
      </div>
    </li>
    <!-- <li class="nav-item text-nowrap">
      <a class="nav-link" href="#">Sign out</a>
    </li> -->
  </ul>
</nav>

<div class="container-fluid">
  <div class="row">
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar collapse">
      <div class="sidebar-sticky pt-3">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link active" [routerLink]="['/main/dashboard/']">
              <i class="fas fa-tachometer-alt"></i>
              Dashboard
            </a>
          </li>
          <!-- <li class="nav-item custom-dropdown">
            <a class="nav-link" href="javascript:void(0);" (click)="toggleInstantMenu();">
              <img src="assets/icons/icon-instant-delivery-gray.png" style="width: 22px;height: 22px;" alt="" />
              Instant Delivery
            </a>
            <ul class="custom-dropdown-menu" [ngClass]="(displayInstantSubMenu === false) ? 'd-none' : 'd-block'">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/delivery/single/']" [queryParams]="{'m': 'express'}">
                  <img src="assets/icons/icon-int-express-gray.png" style="width: 22px;height: 22px;" alt="" />
                  Express Delivery
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/delivery/single/']" [queryParams]="{'m': 'multi'}">
                  <img src="assets/icons/icon-int-muti-gray.png" style="width: 22px;height: 22px;" alt="" />
                  MultiStop Delivery
                </a>
              </li>
            </ul>
          </li> -->
          <li class="nav-item custom-dropdown">
            <a class="nav-link" href="javascript:void(0);" (click)="toggleDomesticMenu();">
              <img src="assets/icons/icon-domestic-delivery-gray.png" style="width: 22px;height: 22px;" alt="" />
              Domestic Delivery
            </a>
            <ul class="custom-dropdown-menu" [ngClass]="(displayDomesticSubMenu === false) ? 'd-none' : 'd-block'">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/delivery/domestic/']" [queryParams]="{'m': 'courier'}">
                  <img src="assets/icons/icon-domestic-courier-gray.png" style="width: 22px;height: 22px;" alt="" />
                  Courier Service
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/delivery/domestic/']" [queryParams]="{'m': 'cargo'}">
                  <img src="assets/icons/icon-domestic-cargo-gray.png" style="width: 22px;height: 22px;" alt="" />
                  Cargo Service
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/delivery/domestic/']" [queryParams]="{'m': 'air-freight'}">
                  <img src="assets/icons/icons-air-malaysial-gray.png" style="width: 22px;height: 22px;" alt="" />
                  Air Freight Service
                </a>
              </li>
            </ul>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/delivery/international/']">
              <img src="assets/icons/icons-air-international-gray.png" style="width: 22px;height: 22px;" alt="" />
              International Delivery
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/draft/']">
              <i class="fab fa-firstdraft"></i>
              Drafts
            </a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/favourite/']">
              <i class="fas fa-heart"></i>
              Favourite
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/order/list/']">
              <i class="fas fa-file"></i>
              Manage Orders
            </a>
          </li>
          <!-- <li class="nav-item custom-dropdown">
            <a class="nav-link" href="javascript:void(0);" (click)="toggleOrderMenu();">
              <i class="fas fa-file"></i>
              My Orders
            </a>
            <ul class="custom-dropdown-menu" [ngClass]="(displayOrderSubMenu === false) ? 'd-none' : 'd-block'">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/order/list/']">
                  <i class="fas fa-file"></i>
                  Order List
                </a>
              </li>
            </ul>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/credit/buy/']">
              <i class="fas fa-coins"></i>
              Buy Credits
            </a>
          </li>
          <li class="nav-item custom-dropdown">
            <a class="nav-link" href="javascript:void(0);" (click)="toggleReferralSubMenu();">
              <i class="fas fa-user-check"></i>
              Referrals Program
            </a>
            <ul class="custom-dropdown-menu" [ngClass]="(displayReferralSubMenu === false) ? 'd-none' : 'd-block'">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/referral/']">
                  <img src="assets/icons/menu/icon-refferal-lightgray.png" style="width: 18px;height: 18px;" alt="" />
                  My Referral Link
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/reward/']">
                  <i class="fas fa-gift"></i>
                  My Rewards
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/transaction/ap']">
                  <img src="assets/icons/menu/icon-ap-transaction-record-lightgray.png" style="width: 18px;height: 18px;" alt="" />
                  AP Transaction Record
                </a>
              </li>
            </ul>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/construction/']">
              <i class="fas fa-gift"></i>
              Rewards
            </a>
          </li> -->
          <!-- <li class="nav-item collapse-show-only">
            <a class="nav-link" href="#">
              <span data-feather="home"></span>
              New Job
            </a>
          </li>
          <li class="nav-item collapse-show-only">
            <a class="nav-link" href="#">
              <span data-feather="home"></span>
              My Profile
            </a>
          </li>
          <li class="nav-item collapse-show-only">
            <a class="nav-link" href="#">
              <span data-feather="home"></span>
              Redeem
            </a>
          </li>
          <li class="nav-item custom-dropdown">
            <a class="nav-link" href="javascript:void(0);" (click)="toggleAccountMenu();">
              <i class="fas fa-user"></i>
              Account
            </a>
            <ul class="custom-dropdown-menu" [ngClass]="(displayAccountSubMenu === false) ? 'd-none' : 'd-block'">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/invoice/']">
                  <i class="fas fa-file"></i>
                  Invoice
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/main/statement/']">
                  <i class="fas fa-file"></i>
                  Statement
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/store/']">
              <i class="fas fa-store"></i>
              Arriival Store
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/user/profile/']">
              <i class="fas fa-user"></i>
              Account
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/construction/']">
              <i class="fas fa-cogs"></i>
              Integrations
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/main/construction/']">
              <i class="fas fa-file"></i>
              About
            </a>
          </li> -->
          <li class="nav-item collapse-show-only">
            <a class="nav-link" href="#">
              <span data-feather="home"></span>
              <i class="fas fa-power-off"></i>
              Sign out
            </a>
          </li>
          <!-- <li class="nav-item pt-5">
            <a class="nav-link">
              <div class="nav-item-img-wrapper">
                <img src="assets/img/app_store.png" alt="" />
              </div>
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link">
              <div class="nav-item-img-wrapper">
                <img src="assets/img/play_store.png" alt="" />
              </div>
            </a>
          </li> -->
        </ul>
      </div>
    </nav>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>

  </div>
</div>
