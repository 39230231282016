<div class="content col-mg-9 col-lg-12 pt-3">
  <div class="row">
    <div class="col-md-12">
      <h2>About Us</h2>
      <span class="text-uppercase">malaysia's 1st service provider with instant and domestic delivery</span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <img src="/assets/img/graphic-about.png" style="width: 100%;height: 100%;object-fit: contain;" alt="">
    </div>
    <div class="col-md-8" >
      <div class="row mt-5">
        <div class="col-md-5">
          <span class="text-primary" style="font-size: 20px;font-weight: 500;">Instant Delivery:</span><br />
          <span>Monday to Saturday: 9am - 6pm</span><br />
          <span>Sunday &amp; Public Holiday:</span><br />
          <span>By Appointment (Cut Off time: 4pm)</span>
        </div>
        <div class="col-md-7">
          <span class="text-primary" style="font-size: 20px;font-weight: 500;">Domestic Delivery:</span><br />
          <span>Monday to Friday: 9am - 5pm (Cutoff time: 12pm)</span><br />
          <span>Saturday: 9am - 1pm (Cut off time: 10am)</span><br />
          <span>Sunday &amp; Public Holiday: Off</span>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-5">
          <span class="text-primary" style="font-size: 20px;font-weight: 500;">Support Team:</span><br />
          <span>Monday to Friday: 9am - 6pm</span><br />
          <span>Saturday &amp; Public Holiday: Off</span><br />
          <span>Lunch Time: 12:30pm - 1:30pm</span>
        </div>
        <div class="col-md-7">
          <span class="text-primary" style="font-size: 20px;font-weight: 500;">Contact us:</span>
          <div class="d-flex flex-row">
            <i style="line-height: 1.5;width: 25px;" class="text-center fas fa-mobile-alt"></i>
            <span>010 246 4991 (Customer Support)</span>
          </div>
          <div class="d-flex flex-row">
            <i style="line-height: 1.5;width: 25px;" class="text-center fas fa-phone"></i>
            <span>603 2107 0187</span>
          </div>
          <div class="d-flex flex-row">
            <i style="line-height: 1.5;width: 25px;" class="text-center fas fa-envelope"></i>
            <span>support@arriival.com</span>
          </div>
          <div class="d-flex flex-row">
            <i style="line-height: 1.5;width: 25px;" class="text-center fas fa-map-marker-alt"></i>
            <span>Arriival Technologies Sdn Bhd (HQ)</span>
          </div>
          <div class="d-flex flex-row">
            <i style="line-height: 1.5;width: 25px;color: transparent;" class="text-center fas fa-map-marker-alt"></i>
            <span style="width: 70%;">No.9, Jln YTN 3, Industri Desa Aman, Kepong, 52200 Kuala Lumpur</span>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="offset-md-5 col-md-7">
          <span style="font-weight: 600;" class="d-block pl-3">Connect with us</span>
          <a class="px-3" style="font-size: 24px;color: black;" href="javascript:void(0);"><i class="fas fa-globe-asia"></i></a>
          <a class="px-3" style="font-size: 24px;color: black;" href="javascript:void(0);"><i class="fab fa-facebook-square"></i></a>
          <a class="px-3" style="font-size: 24px;color: black;" href="javascript:void(0);"><i class="fab fa-instagram-square"></i></a>
          <a class="px-3" style="font-size: 24px;color: black;" href="javascript:void(0);"><i class="fab fa-youtube"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>
