import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, retry, first } from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
  selector: "app-buy",
  templateUrl: "./buy.component.html",
  styleUrls: ["./buy.component.css"],
})
export class BuyComponent implements OnInit {
  credit: Number = 4000;

  public arriivalPoint: Number;

  public spcs: Number;
  public mpcs: Number;
  public lpcs: Number;

  public ReceiverName: string;
  public ReceiverEmail: string;
  public ContactNumber: string;
  public Address: string;

  

  constructor(public _http: HttpClient) {}

  ngOnInit(): void {

    this.ReceiverEmail = localStorage.getItem('useremail');
  }

  setCredit(event: any): void {
    this.credit = event.target.value;

    this.setView();


  }


  setView() {


    if(this.credit == 50)
      {
        document.getElementById("arriivalShippingFee").innerHTML = "0.00";
        document.getElementById("arriivalWaybillSticker").innerHTML = "0";
        document.getElementById("arriivalWaybillBag").innerHTML = "0";
      }

      if(this.credit == 100)
      {
        document.getElementById("arriivalShippingFee").innerHTML = "6.80";
        document.getElementById("arriivalWaybillSticker").innerHTML = "0";
        document.getElementById("arriivalWaybillBag").innerHTML = "10";
      }
      if(this.credit == 300)
      {
        document.getElementById("arriivalShippingFee").innerHTML = "6.80";
        document.getElementById("arriivalWaybillSticker").innerHTML = "50";
        document.getElementById("arriivalWaybillBag").innerHTML = "50";
      }
      if(this.credit == 600)
      {
        document.getElementById("arriivalPoint").innerHTML = ""+ (600 * 0.005);
        document.getElementById("arriivalShippingFee").innerHTML = "6.80";
        document.getElementById("arriivalWaybillSticker").innerHTML = "100";
        document.getElementById("arriivalWaybillBag").innerHTML = "100";
      }
      if(this.credit == 1000)
      {
        document.getElementById("arriivalPoint").innerHTML = ""+ (1000 * 0.01);
        document.getElementById("arriivalShippingFee").innerHTML = "9.80";
        document.getElementById("arriivalWaybillSticker").innerHTML = "150";
        document.getElementById("arriivalWaybillBag").innerHTML = "150";
      }
      if(this.credit == 2000)
      {
        document.getElementById("arriivalPoint").innerHTML = ""+ (2000 * 0.015);
        document.getElementById("arriivalShippingFee").innerHTML = "14.30";
        document.getElementById("arriivalWaybillSticker").innerHTML = "300";
        document.getElementById("arriivalWaybillBag").innerHTML = "300";
      }
      if(this.credit == 4000)
      {
        document.getElementById("arriivalPoint").innerHTML = ""+ (4000 * 0.015);
        document.getElementById("arriivalShippingFee").innerHTML = "36.80";
        document.getElementById("arriivalWaybillSticker").innerHTML = "600";
        document.getElementById("arriivalWaybillBag").innerHTML = "600";
        document.getElementById("arriivalWaybillBag").innerHTML = "4140.80";
      }




    let issticker = (document.getElementById("sticker") as HTMLInputElement);
    let isprinter = (document.getElementById("printer") as HTMLInputElement);
    let iscourierbag = (document.getElementById("courierbag") as HTMLInputElement);
    let offterPoint = (document.getElementById("offterPoint") as HTMLInputElement );
    let giftItemsDiv = (document.getElementById("giftItemsDiv") as HTMLDivElement);

    let printerDiv = (document.getElementById("printerDiv") as HTMLDivElement);
    let stickerDiv = (document.getElementById("stickerDiv") as HTMLDivElement);
    let receiverDiv = (document.getElementById("receiverDiv") as HTMLDivElement);

    let offterDiv = (document.getElementById("offterDiv") as HTMLDivElement);
    let offterPointDiv = (document.getElementById("offterPointDiv") as HTMLDivElement);



    if(offterPoint.checked == true)
    {
      issticker.checked = false;
      isprinter.checked = false;
      iscourierbag.checked = false;
      giftItemsDiv.style.display="none";
      receiverDiv.style.display="none";
      document.getElementById("arriivalShippingFee").innerHTML = "0.00";
      
      this.spcs = 0;
      this.mpcs = 0;
      this.lpcs = 0;

      this.ReceiverName = "";
      this.ContactNumber = "";
      this.Address = "";
      if(this.credit == 50)
      {
        offterDiv.style.display="none";
        document.getElementById("arriivalTotalAmount").innerHTML = "51.30";
      }
      if(this.credit == 100)
      {
        offterDiv.style.display="";
        offterPointDiv.style.display="none";
        document.getElementById("arriivalTotalAmount").innerHTML = "102.60";
      }
      if(this.credit == 300)
      {
        offterDiv.style.display="";
        offterPointDiv.style.display="none";
        document.getElementById("arriivalTotalAmount").innerHTML = "307.8";
      }
      if(this.credit == 600)
      {
        offterDiv.style.display="";
        offterPointDiv.style.display="";
        document.getElementById("arriivalPoint").innerHTML = ""+ (600 * 0.005);
        document.getElementById("arriivalTotalAmount").innerHTML = "615.60";
      }
      if(this.credit == 1000)
      {
        offterDiv.style.display="";
        offterPointDiv.style.display="";
        document.getElementById("arriivalPoint").innerHTML = ""+ (1000 * 0.01);
        document.getElementById("arriivalTotalAmount").innerHTML = "1026.00";
      }
      if(this.credit == 2000)
      {
        offterDiv.style.display="";
        offterPointDiv.style.display="";
        document.getElementById("arriivalPoint").innerHTML = ""+ (2000 * 0.015);
        document.getElementById("arriivalTotalAmount").innerHTML = "2052.00";
      }
      if(this.credit == 4000)
      {
        offterDiv.style.display="";
        offterPointDiv.style.display="";
        document.getElementById("arriivalPoint").innerHTML = ""+ (4000 * 0.015);
        document.getElementById("arriivalTotalAmount").innerHTML = "4104.00";
      }


    }
    else
    {
      giftItemsDiv.style.display="";
      if(this.credit == 50)
      {
        document.getElementById("arriivalShippingFee").innerHTML = "0.00";
        this.spcs = 0;
        this.mpcs = 0;
        this.lpcs = 0;
        issticker.checked = false;
        isprinter.checked = false;
        iscourierbag.checked = false;
        giftItemsDiv.style.display="none";
        stickerDiv.style.display="none";
        printerDiv.style.display="none";
        receiverDiv.style.display="none";
        offterDiv.style.display="none";
        document.getElementById("arriivalTotalAmount").innerHTML = "51.30";

        this.ReceiverName = "";
        this.ContactNumber = "";
        this.Address = "";

      }

      if(this.credit == 100)
      {
        stickerDiv.style.display="none";
        printerDiv.style.display="none";
        receiverDiv.style.display="";
        offterDiv.style.display="";
        offterPointDiv.style.display="none";
        document.getElementById("arriivalWaybillSticker").innerHTML = "0";
        document.getElementById("arriivalWaybillBag").innerHTML = "10";
        isprinter.checked = false;
        if(issticker.checked == false && iscourierbag.checked == false)
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "102.60";
          document.getElementById("arriivalShippingFee").innerHTML = "0.00";
          this.spcs = 0;
          this.mpcs = 0;
          this.lpcs = 0;
        }
        else
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "109.40";
          document.getElementById("arriivalShippingFee").innerHTML = "6.80";
          if(iscourierbag.checked == false)
          {
            this.spcs = 0;
            this.mpcs = 0;
            this.lpcs = 0;
          }
        }
      }
      if(this.credit == 300)
      {
        stickerDiv.style.display="";
        printerDiv.style.display="none";
        receiverDiv.style.display="";
        offterDiv.style.display="";
        offterPointDiv.style.display="none";
        document.getElementById("arriivalWaybillSticker").innerHTML = "50";
        document.getElementById("arriivalWaybillBag").innerHTML = "50";
        isprinter.checked = false;
        if(issticker.checked == false && iscourierbag.checked == false)
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "307.8";
          document.getElementById("arriivalShippingFee").innerHTML = "0.00";
          this.spcs = 0;
          this.mpcs = 0;
          this.lpcs = 0;
        }
        else
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "314.60";
          document.getElementById("arriivalShippingFee").innerHTML = "6.80";
          if(iscourierbag.checked == false)
          {
            this.spcs = 0;
            this.mpcs = 0;
            this.lpcs = 0;
          }
        }
      }
      if(this.credit == 600)
      {
        stickerDiv.style.display="";
        printerDiv.style.display="none";
        receiverDiv.style.display="";
        offterDiv.style.display="";
        offterPointDiv.style.display="";
        document.getElementById("arriivalPoint").innerHTML = ""+ (600 * 0.005);
        document.getElementById("arriivalWaybillSticker").innerHTML = "100";
        document.getElementById("arriivalWaybillBag").innerHTML = "100";
        isprinter.checked = false;
        if(issticker.checked == false && iscourierbag.checked == false)
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "615.60";
          document.getElementById("arriivalShippingFee").innerHTML = "0.00";
          this.spcs = 0;
          this.mpcs = 0;
          this.lpcs = 0;
        }
        else
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "622.40";
          document.getElementById("arriivalShippingFee").innerHTML = "6.80";
          if(iscourierbag.checked == false)
          {
            this.spcs = 0;
            this.mpcs = 0;
            this.lpcs = 0;
          }
        }
      }
      if(this.credit == 1000)
      {
        stickerDiv.style.display="";
        printerDiv.style.display="";
        receiverDiv.style.display="";
        offterDiv.style.display="";
        offterPointDiv.style.display="";
        document.getElementById("arriivalPoint").innerHTML = ""+ (1000 * 0.01);
        document.getElementById("arriivalWaybillSticker").innerHTML = "150";
        document.getElementById("arriivalWaybillBag").innerHTML = "150";
        if(issticker.checked == false && iscourierbag.checked == false && isprinter.checked == false)
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "1026.00";
          document.getElementById("arriivalShippingFee").innerHTML = "0.00";
          this.spcs = 0;
          this.mpcs = 0;
          this.lpcs = 0;
        }
        else
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "1035.80";
          document.getElementById("arriivalShippingFee").innerHTML = "9.80";
          if(iscourierbag.checked == false)
          {
            this.spcs = 0;
            this.mpcs = 0;
            this.lpcs = 0;
          }
        }
      }
      if(this.credit == 2000)
      {
        stickerDiv.style.display="";
        printerDiv.style.display="";
        receiverDiv.style.display="";
        offterDiv.style.display="";
        offterPointDiv.style.display="";
        document.getElementById("arriivalPoint").innerHTML = ""+ (2000 * 0.015);
        document.getElementById("arriivalWaybillSticker").innerHTML = "300";
        document.getElementById("arriivalWaybillBag").innerHTML = "300";
        if(issticker.checked == false && iscourierbag.checked == false && isprinter.checked == false)
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "2052.00";
          document.getElementById("arriivalShippingFee").innerHTML = "0.00";
          this.spcs = 0;
          this.mpcs = 0;
          this.lpcs = 0;
        }
        else
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "2066.30";
          document.getElementById("arriivalShippingFee").innerHTML = "14.30";
          if(iscourierbag.checked == false)
          {
            this.spcs = 0;
            this.mpcs = 0;
            this.lpcs = 0;
          }
        }
      }
      if(this.credit == 4000)
      {
        stickerDiv.style.display="";
        printerDiv.style.display="";
        receiverDiv.style.display="";
        offterDiv.style.display="";
        offterPointDiv.style.display="";
        document.getElementById("arriivalPoint").innerHTML = ""+ (4000 * 0.015);
        document.getElementById("arriivalWaybillSticker").innerHTML = "600";
        document.getElementById("arriivalWaybillBag").innerHTML = "600";
        if(issticker.checked == false && iscourierbag.checked == false && isprinter.checked == false)
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "4104.00";
          document.getElementById("arriivalShippingFee").innerHTML = "0.00";
          this.spcs = 0;
          this.mpcs = 0;
          this.lpcs = 0;
        }
        else
        {
          document.getElementById("arriivalTotalAmount").innerHTML = "4140.80";
          document.getElementById("arriivalShippingFee").innerHTML = "36.80";
          if(iscourierbag.checked == false)
          {
            this.spcs = 0;
            this.mpcs = 0;
            this.lpcs = 0;
          }
        }
      }

      
    }


  }


  submitOrder() {


    let sticker = (document.getElementById("sticker") as HTMLInputElement);
    let printer = (document.getElementById("printer") as HTMLInputElement);
    let courierbag = (document.getElementById("courierbag") as HTMLInputElement);
    let offterPoint = (document.getElementById("offterPoint") as HTMLInputElement );

    let offterType: number = 1;
    if(offterPoint.checked == true)
    {
      offterType = 0;
    }
    let isPrinter: number = 0;
    if(printer.checked == true)
    {
      isPrinter = 1;
    }
    let isWaybillSticker: number = 0;
    if(sticker.checked == true)
    {
      isWaybillSticker = 1;
    }
    let isCbAndFs: number = 0;
    if(courierbag.checked == true)
    {
      isCbAndFs = 1;
    }
    

    var formData: any = new FormData();

      //formData.append("money", document.getElementById("arriivalTotalAmount").innerText);
      formData.append("money", this.credit);
      formData.append("payType", "onlinePay");
      formData.append("offterType", offterType);
      formData.append("isPrinter", isPrinter);
      formData.append("isWaybillSticker", isWaybillSticker);
      formData.append("isCbAndFs", isCbAndFs);
      formData.append("courierBaySizeS", this.spcs);
      formData.append("courierBaySizeM", this.mpcs);
      formData.append("courierBaySizeL", this.lpcs);
      formData.append("receiverName", this.ReceiverName);
      formData.append("contactNumber", this.ContactNumber);
      formData.append("fullAddress", this.Address);
      formData.append("buyerEmail", this.ReceiverEmail);
      formData.append("successCallback", "/credit/buy?ordernumber=");
      //formData.append("failureCallback", "http://send.arriival.com?ordernumber=");

      formData.append("uuid", localStorage.getItem('uuid'));
      formData.append("zdtype", "web");
      formData.append("token", localStorage.getItem('token'));


      console.log(formData);

//      this._http
//        .post(
//          `http://uml.arriival.com/qmcs-u-api/user/onlineRecharge`,
//          formData
//        )
//        .pipe(
//          catchError((err: HttpErrorResponse) => {
//            return throwError("Something bad happened; please try again later.");
//          }) // then handle the error
//        )
//        .subscribe((respObj) => {
//          console.log(respObj);
//          if (respObj["code"] == 200) {
//
//            // alert(respObj["data"]["recordOrderNo"]);
//            // alert(respObj["data"]["recordTax"]);
//            // alert(respObj["data"]["money"]);
//            // alert(respObj["data"]["recordDelivery"]);
//            // alert(respObj["data"]["recordIsPrinter"]);
//            // alert(respObj["data"]["recordCourierBag"]);
//            // alert(respObj["data"]["recordWaybillSticker"]);
//            // alert(respObj["data"]["recordFragileSticker"]);
//            // alert(respObj["data"]["recordArriivalPoint"]);
//            // alert(respObj["data"]["recordCourierBagS"]);
//            // alert(respObj["data"]["recordCourierBagM"]);
//            // alert(respObj["data"]["recordCourierBagL"]);
//            // alert(respObj["data"]["recordTotalPrice"]);
//            // alert(respObj["data"]["paymentUrl"]);
//            window.location.href = respObj["data"]["paymentUrl"];
//            //this.makePayment(respObj["data"]["recordOrderNo"] , respObj["data"]["recordTotalPrice"]);
//          } else {
//            alert(respObj["enMsg"]);
//          }
//        });


  }


  recordPaymentCallback() {
    var formData: any = new FormData();
    formData.append("recordOrderNo", "AR010287722103022208106996");

//    this._http
//        .post(
//          `http://uml.arriival.com/qmcs-u-api/user/recordPaymentCallback`,
//          formData
//        )
//        .pipe(
//          catchError((err: HttpErrorResponse) => {
//            return throwError("Something bad happened; please try again later.");
//          }) // then handle the error
//        )
//        .subscribe((respObj) => {
//          console.log(respObj);
//          if (respObj["code"] == 200) {
//            alert(respObj["enMsg"]);
//          } else {
//            alert(respObj["enMsg"]);
//          }
//        });



  }



  // makePayment(order_number:string , amount:string) {
  //   var access_token = "";
  //   var reqObj = {};

  //   reqObj["grant_type"] = "client_credentials";
  //   reqObj["client_id"] = "2";
  //   reqObj["client_secret"] = "naR1Ju4KOKvY8SPZfMlHMZDbDfqFBhdFAgap3m93";

  //   // get access token
  //   this._http
  //     .post(`https://cc.development.qbayar.com/oauth/token`, reqObj)
  //     .pipe(
  //       catchError((err: HttpErrorResponse) => {
  //         return throwError("Something bad happened; please try again later.");
  //       }) // then handle the error
  //     )
  //     .subscribe((respObj) => {
  //       access_token = respObj["access_token"];

  //       const headerDict = {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         "Access-Control-Allow-Headers": "Content-Type",
  //         Authorization: "Bearer " + access_token,
  //       };

  //       var reqPaymentObj = {};

  //       reqPaymentObj["order_number"] = order_number;
  //       reqPaymentObj["amount"] = amount;
  //       reqPaymentObj["payment_remark"] = "Recharge";
  //       reqPaymentObj["success_callback"] = "";
  //       reqPaymentObj["failure_callback"] = "";

  //       // make payment
  //       this._http
  //         .post(
  //           `https://cc.development.qbayar.com/api/v1/request_payment`,
  //           reqPaymentObj
  //         )
  //         .pipe(
  //           catchError((err: HttpErrorResponse) => {
  //             return throwError(
  //               "Something bad happened; please try again later."
  //             );
  //           }) // then handle the error
  //         )
  //         .subscribe((respObj) => {
  //           if(respObj["access_token"] == 200)
  //           {

  //           }
  //           else
  //           {
  //               alert(respObj["message"]);
  //           }
  //           //access_token = respObj["access_token"];

  //           // make payment
  //         });
  //     });
  // }

  
  

}


