import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.css']
})
export class SingleComponent implements OnInit {

  activateExpressDelivery:Boolean = true;
  activateMultiDelivery:Boolean = false;

  activateOneWayTrip:Boolean = true;
  activateReturnTrip:Boolean = false;

  activateMotor:Boolean = true;
  activateCar:Boolean = false;
  activateVan:Boolean = false;
  activateOneTonLorry:Boolean = false;
  activateThreeTonLorry:Boolean = false;
  activateChilledLorry:Boolean = false;
  inputTotalWeight:Number = 0;
  activateCouponField: Boolean = false;
  public isCollapsed: Boolean = false;

  selectedParcelType:String = "document";
  parcelTypeItems: any = [{
    "name": "Document",
    "value": "document"
  }, {
    "name": "Food & Beverages",
    "value": "foodAndBeverages"
  }, {
    "name": "Gift",
    "value": "gift"
  }, {
    "name": "Groceries",
    "value": "groceries"
  }, {
    "name": "Flower",
    "value": "flower"
  }, {
    "name": "Cake",
    "value": "cake"
  }, {
    "name": "Other",
    "value": "other"
  }];

  cartItems: any = [{
    "total": "48.40",
    "items": [{
      "qty": "2",
      "name": "Parcels",
      "price": "8.30",
      "sub_total": "16.60"
    }, {
      "qty": "1",
      "name": "Parcel",
      "price": "6.80",
      "sub_total": "6.80"
    }, {
      "qty": "1",
      "name": "Oversized",
      "price": "10.00",
      "sub_total": "10.00"
    }, {
      "qty": "1",
      "name": "Overweight",
      "price": "15.00",
      "sub_total": "15.00"
    }]
  }];

  constructor(private activatedRoute:ActivatedRoute, private router:Router) {

  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let m = this.activatedRoute.snapshot.queryParams["m"];
    m === "express" ? this.activateExpressDeliveryButton() : this.activateMultiDeliveryButton();
  }

  showCouponField(): void {
    this.activateCouponField = true;
  }
  hideCouponField(): void {
    this.activateCouponField = false;
  }

  activateExpressDeliveryButton():void {
    this.activateExpressDelivery = true;
    this.activateMultiDelivery = false;
    this.activateMotorButton();
  }
  activateMultiDeliveryButton():void {
    this.activateMultiDelivery = true;
    this.activateExpressDelivery = false;
    this.activateMotorButton();
  }

  activateOneWayTripButton():void {
    this.activateOneWayTrip = true;
    this.activateReturnTrip = false;
  }
  activateReturnTripButton():void {
    this.activateReturnTrip = true;
    this.activateOneWayTrip = false;
  }

  activateMotorButton():void {
    this.activateMotor = true;
    this.activateCar = false;
    this.activateVan = false;
    this.activateOneTonLorry = false;
    this.activateThreeTonLorry = false;
    this.activateChilledLorry = false;
  }
  activateCarButton():void {
    this.activateMotor = false;
    this.activateCar = true;
    this.activateVan = false;
    this.activateOneTonLorry = false;
    this.activateThreeTonLorry = false;
    this.activateChilledLorry = false;
  }
  activateVanButton():void {
    this.activateMotor = false;
    this.activateCar = false;
    this.activateVan = true;
    this.activateOneTonLorry = false;
    this.activateThreeTonLorry = false;
    this.activateChilledLorry = false;
  }
  activateOneTonLorryButton():void {
    this.activateMotor = false;
    this.activateCar = false;
    this.activateVan = false;
    this.activateOneTonLorry = true;
    this.activateThreeTonLorry = false;
    this.activateChilledLorry = false;
  }
  activateThreeTonLorryButton():void {
    this.activateMotor = false;
    this.activateCar = false;
    this.activateVan = false;
    this.activateOneTonLorry = false;
    this.activateThreeTonLorry = true;
    this.activateChilledLorry = false;
  }
  activateChilledLorryButton():void {
    this.activateMotor = false;
    this.activateCar = false;
    this.activateVan = false;
    this.activateOneTonLorry = false;
    this.activateThreeTonLorry = false;
    this.activateChilledLorry = true;
  }

  keyUpTotalWeight(event:any) {
    this.inputTotalWeight = parseFloat(event.target.value);

    if (this.inputTotalWeight > 1000) {
      this.activateThreeTonLorryButton();
    } else if (this.inputTotalWeight > 500) {
      this.activateOneTonLorryButton();
    } else if (this.inputTotalWeight > 50) {
      this.activateVanButton();
    } else if (this.inputTotalWeight > 8) {
      this.activateCarButton();
    } else {
      this.activateMotorButton();
    }
  }
}
