<div class="content col-md-9 col-lg-12 pt-3">
  <h2>Profile</h2>
  <!-- <p>
    <span>My Referral Link: </span>
    <a href="#">Example Link</a>
  </p> -->
  <!-- <p>
    <span>My Referral Code: </span>
    <span>Example Code</span>
  </p> -->

  <!-- Member Level -->
  <!-- <div class="container">
    <div class="row"><br />
      <div class="col-md-12">
        <div class="progress">
          <div class="one warning-color">Member</div>
          <div class="two warning-color">Silver</div>
          <div class="three no-color">Gold</div>
          <div class="four no-color">Platinum</div>
          <div class="progress-bar bg-warning" style="width: 60%"></div>
        </div>
      </div>
    </div>
  </div> -->

  <hr />
  <div class="user-avatar-wrapper">
    <img class="rounded-circle" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcT9rpl4EUmQD4YPmAjS76iCXSkHqAJg8qvyA2ueuqN2nxYtjZBNI4-J9gJKFSO0nwu5hzpiRugqx9oPzuqcFjs-4Wm3PL-dvGingHO-&usqp=CAU&ec=45707746" alt="" />
  </div>

  <form class="" action="index.html" method="post">
    <div class="form-group row">
      <div class="col-md-12">
        <label for="">Account Type</label>
      </div>
      <div class="col-md-12">
        <input type="radio" name="" value="" checked /> INDIVIDUAL
        <input type="radio" name="" value="" /> COMPANY
      </div>
    </div>

    <div class="form-group row d-none">
      <div class="col-md-12">
        <label for="">Company Name</label>
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control" name="" value="" />
      </div>
    </div>
    <div class="form-group row d-none">
      <div class="col-md-12">
        <label for="">Company Registration Number</label>
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control" name="" value="" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="">Fullname</label>
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control" name="displayFullName" [(ngModel)]="displayFullName" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="">Email</label>
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control" name="" value="" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="">Phone Number</label>
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control" name="displayTelephone" [(ngModel)]="displayTelephone" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="">Reset Password</label>
      </div>
      <div class="col-md-12">
        <input type="password" class="form-control" name="" value="" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="">Re-Confirm Password</label>
      </div>
      <div class="col-md-12">
        <input type="password" class="form-control" name="" value="" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="">API Token</label>
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control" name="" value="" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="">API App ID</label>
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control" name="" value="" />
      </div>
    </div>
    <div class="form-group row">
      <button type="button" class="btn btn-lg btn-primary" name="button">Save Profile</button>
    </div>
  </form>
</div>
