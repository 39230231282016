<div class="content col-mg-9 col-lg-12 pt-3">
  <h2>Voucher Redemption</h2>

  <div class="row">
    <div class="col-md-8 offset-md-2 text-center redeem-box">
      <p>Insert your voucher code below and redeem credits</p>
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="">
            <input type="text" class="form-control" name="" value="" placeholder="Enter voucher code and redeem" />
          </div>
          <div class="pt-3">
            <button type="button" class="btn btn-warning btn-round mr-1" name="button">Cancel</button>
            <button type="button" class="btn btn-primary btn-round ml-1" name="button">Validate</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h2>Purchase Credits</h2>
  <h3>How much credits do you want to purchase ?</h3>
  <hr />
  <div class="row">
    <div class="col-md-8">
      <p>
        Credits are in MYR (Malaysian Ringgit), are non-refundable and will expire one year from your last purchase. For larger amounts, please contact the <a href="#">Support Team</a>.
      </p>

      <form class="" action="index.html" method="post">
        <div class="form-group mb-4">
          <div class="mb-4">
            <label for="rdoCredit_50" class="d-block">
              <input type="radio" name="rdoCredit" id="rdoCredit_50" class="mr-2" name="" value="" />
              <span class="credit-price">
                <sup>RM</sup>50
              </span>
              <span class="credit-label"> Credit</span>
            </label>
          </div>
          <div class="mb-4">
            <label for="rdoCredit_100" class="d-block">
              <input type="radio" name="rdoCredit" id="rdoCredit_100" class="mr-2" name="" value="" />
              <span class="credit-price">
                <sup>RM</sup>100
              </span>
              <span class="credit-label"> Free RM20 Credit</span>
            </label>
          </div>
          <div class="mb-4">
            <label for="rdoCredit_200" class="d-block">
              <input type="radio" name="rdoCredit" id="rdoCredit_200" class="mr-2" name="" value="" />
              <span class="credit-price">
                <sup>RM</sup>200
              </span>
              <span class="credit-label"> Free RM42 Credit</span>
            </label>
          </div>
          <div class="mb-4">
            <label for="rdoCredit_500" class="d-block">
              <input type="radio" name="rdoCredit" id="rdoCredit_500" class="mr-2" name="" value="" />
              <span class="credit-price">
                <sup>RM</sup>500
              </span>
              <span class="credit-label"> Free RM110 Credit</span>
            </label>
          </div>
          <div class="mb-4">
            <label for="rdoCredit_1000" class="d-block">
              <input type="radio" name="rdoCredit" id="rdoCredit_1000" class="mr-2" name="" value="" />
              <span class="credit-price">
                <sup>RM</sup>1000
              </span>
              <span class="credit-label"> Free RM225 Credit</span>
            </label>
          </div>
          <div class="mb-4">
            <label for="rdoCredit_2000" class="d-block">
              <input type="radio" name="rdoCredit" id="rdoCredit_2000" class="mr-2" name="" value="" />
              <span class="credit-price">
                <sup>RM</sup>2000
              </span>
              <span class="credit-label"> Free RM465 Credit</span>
            </label>
          </div>
          <div class="mb-4">
            <label for="rdoCredit_5000" class="d-block">
              <input type="radio" name="rdoCredit" id="rdoCredit_5000" class="mr-2" name="" value="" />
              <span class="credit-price">
                <sup>RM</sup>5000
              </span>
              <span class="credit-label"> Free RM1150 Credit</span>
            </label>
          </div>
        </div>
        <h2>Choose Payment Method</h2>
        <hr />
        <div class="form-group mb-5">
          <div class="">
            <label for="">
              <input type="radio" name="" value=""> Online Banking / Credit Card
            </label>
          </div>
          <div class="">
            <label for="">
              <input type="radio" name="" value=""> Boost E-wallet
            </label>
          </div>
        </div>
        <div class="form-group">
          <button type="button" class="btn btn-success btn-round" name="button">Purchase Now</button>
        </div>
      </form>
    </div>
  </div>
</div>
