import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, first } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-international',
  templateUrl: './international.component.html',
  styleUrls: ['./international.component.css'],
  providers: [DatePipe]
})
export class InternationalComponent implements OnInit {

  public isCollapsed = false;
  public totalPriceToPay = 0;
  cartDetails: any[];
  activateCouponField: Boolean = false;

  pickupCountries:any[] = [{
    "name": "Malaysia",
    "value": "Malaysia",
  }];

  states:any[] = [{
    "name": "Johor",
    "value": "Johor"
  }, {
    "name": "Selangor",
    "value": "Selangor"
  }, {
    "name": "Sabah",
    "value": "Sabah"
  }, {
    "name": "Sarawak",
    "value": "Sarawak"
  }, {
    "name": "Kedah",
    "value": "Kedah"
  }, {
    "name": "Kelantan",
    "value": "Kelantan"
  }, {
    "name": "Pahang",
    "value": "Pahang"
  }, {
    "name": "Kelantan",
    "value": "Kelantan"
  }, {
    "name": "Negeri Sembilan",
    "value": "Negeri Sembilan"
  }, {
    "name": "Perak",
    "value": "Perak"
  }, {
    "name": "Perlis",
    "value": "Perlis"
  }, {
    "name": "Perak",
    "value": "Perak"
  }, {
    "name": "Terrenganu",
    "value": "Terrenganu"
  }];

  destinationCountries:any[] = [ {
    "name": "Singapore",
    "value": "Singapore"
  }];


  destinationStates:any[] = [{
    "name": "Singapore",
    "value": "Singapore"
  }];




  public secretKey = 'hZMTVHXf6XmRNG2hJjk9WmzRfQvpUowE';
  public serviceId: number;
  public paymentType: string;
  public orderLength: number;
  public orderWidth: number;
  public orderHeight: number;
  public orderWeight: number;

  public pickupContactName: string;
  public pickupContactNumber: string;
  public pickupCountry: string;
  public pickupAddress: string;
  public pickupPostal: string;
  public pickupState: string;
  public pickupDate: string;

  public consigneeName: string;
  public consigneeNumber: string;
  public consigneeCountry: string;
  public consigneeAddress: string;
  public consigneePostal: string;
  public consigneeState: string;

  public items = [];
  public itemArray = [];
  public itemDesc: string;
  public itemQuantity: number;
  public itemCategory: string;
  public itemPriceValue: number;
  public itemPriceCurrency = 'MYR';

  public orderTotalPrice: number;

  constructor(public _router: Router, public _http: HttpClient, public datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  public fieldArray: Array<any> = [];
  public newAttribute: any = {};

  showCouponField(): void {
    this.activateCouponField = true;
  }
  hideCouponField(): void {
    this.activateCouponField = false;
  }

  addNewItem() {
    this.fieldArray.push(this.newAttribute)
    this.newAttribute = {};
  }

  removeItem(index) {
    this.fieldArray.splice(index, 1);
  }

  sendOrder() {
    var reqObj = {};

    reqObj['secret_key'] = this.secretKey;
    reqObj['blocking'] = false;

    var orderObj = {};

    orderObj['service_id'] = 25;
    // orderObj['tracking_no'] = 'TRACKINGNUMBER1';
    orderObj['payment_type'] = 'cod';
    orderObj['order_length'] = this.orderLength;
    orderObj['order_weight'] = this.orderWeight;
    orderObj['order_width'] = this.orderWidth;
    orderObj['order_height'] = this.orderHeight;

    orderObj['pickup_country'] = this.pickupCountry;
    orderObj['pickup_state'] = this.pickupState;
    orderObj['consignee_country'] = this.consigneeCountry;
    // orderObj['pickup_date'] = this.datePipe.transform(this.pickupDate, 'yyyy-MM-dd');
    orderObj['pickup_contact_name'] = this.pickupContactName;
    orderObj['pickup_contact_number'] = this.pickupContactNumber;
    orderObj['pickup_address'] = this.pickupAddress;
    orderObj['pickup_postal'] = this.pickupPostal;

    orderObj['consignee_name'] = this.consigneeName;
    orderObj['consignee_number'] = this.consigneeNumber;
    orderObj['consignee_address'] = this.consigneeAddress;
    orderObj['consignee_postal'] = this.consigneePostal;
    orderObj['consignee_state'] = this.consigneeState;

    var itemsObj = {};
    itemsObj['item_desc'] = this.itemDesc;
    itemsObj['item_quantity'] = 1;
    itemsObj['item_category'] = this.itemCategory;
    itemsObj['item_price_value'] = this.itemPriceValue;
    itemsObj['item_price_currency'] = this.itemPriceCurrency;

    var itemArray = [];
    itemArray.push(itemsObj);

    console.log(itemArray);
    for(var i = 0; i < this.fieldArray.length; i++){
      console.log(this.fieldArray[i]);
      var itemsObj = {};
      itemsObj['item_desc'] = this.fieldArray[i].desc;
      itemsObj['item_quantity'] = 1;
      itemsObj['item_category'] = this.fieldArray[i].category;
      itemsObj['item_price_value'] = this.fieldArray[i].priceValue;
      itemsObj['item_price_currency'] = this.itemPriceCurrency;

      itemArray.push(itemsObj);
      console.log(itemArray);
    }

    orderObj['items'] = itemArray;

    var arrayOrder = [];
    arrayOrder.push(orderObj);
    reqObj['orders'] = arrayOrder;

    console.log(reqObj);

    this._http.post('https://api.int.janio.asia/api/order/orders/', reqObj).pipe(
      catchError((err: HttpErrorResponse) => {
          return throwError(
              'Something bad happened; please try again later.');
      }) // then handle the error
    ).subscribe((respObj) => {
        if(respObj['upload_batch_no'] != null) {
          localStorage.setItem('upload_batch_no', respObj['upload_batch_no']);
          localStorage.setItem('service_id', orderObj['service_id']);
          this._router.navigate(['/main/order/checkout/']);
        }
    });
  }




  checkOrderPrice() {

    var boolSend: Boolean = false;
    if ( this.orderWeight != null  && this.orderLength != null && this.orderWeight != null && this.orderHeight != null && this.pickupPostal != "" && this.consigneePostal != "")
      {
        boolSend = true;
      }
      else
      {
        this.orderTotalPrice = 0;
      }


    if (boolSend == true) {


      var formData: any = new FormData();
      formData.append("weight", this.orderWeight);
      formData.append("distance", 20);
      formData.append("orderTransType", 6);
      //formData.append("type", this.items[num].field5);
      //formData.append("type", this.items[num].field5);
      //formData.append("lng", this.lng);
      //formData.append("lat", this.lat);
      formData.append("lng", 0);
      formData.append("lat", 0);
      formData.append("requiredTime", 0);
      formData.append("uuid", localStorage.getItem('uuid'));
      formData.append("zdtype", "web");
      formData.append("token", localStorage.getItem('token'));
      formData.append("sPostCode", this.pickupPostal);
      formData.append("rPostCode", this.consigneePostal);



        formData.append("l", this.orderLength);
        formData.append("w", this.orderWidth);
        formData.append("h", this.orderHeight);



      console.log(formData);




//      this._http
//        .post(
//          "http://uml.arriival.com/qmcs-u-api/order/orderNewFee ",
//          formData
//        )
//        .pipe(
//          catchError((err: HttpErrorResponse) => {
//            return throwError(
//              "Something bad happened; please try again later."
//            );
//          }) // then handle the error
//        )
//        .subscribe((respObj) => {
//          console.log(respObj);
//          if (respObj["code"] == 200) {
//            console.log(respObj["data"].totalPrice);
//            this.orderTotalPrice = respObj["data"].totalPrice;
//            alert(this.orderTotalPrice);
//          }
//          else
//          {
//            if (respObj["code"] == 401) {
//              alert(respObj["enMsg"]);
//              localStorage.removeItem('uuid');
//              localStorage.removeItem('token');
//              localStorage.removeItem('username');
//              localStorage.removeItem('usertel');
//              this._router.navigate(['/login']);
//            }
//            else
//            {
//              alert(respObj["enMsg"]);
//            }
//          }
//        });
    }
  }

  submitOrder() {




      var dateRequiredTime = new Date();


      var formData: any = new FormData();

      formData.append("orderSenderName", this.pickupContactName);
      formData.append("orderSenderTel", this.pickupContactNumber);
      formData.append("orderSendAreaId", this.pickupPostal);
      formData.append("orderSendDoor", "");
      formData.append("orderSendAddr", this.pickupAddress);
      formData.append("orderAnalysisSendAddr", this.pickupAddress);
      formData.append("orderSendLng", 101.6326142);
      formData.append("orderSendLat", 3.1954160);

      formData.append("orderReceiverName", this.consigneeName);
      formData.append("orderReceiverTel", this.consigneeNumber);
      formData.append("orderReceiveAreaId", this.consigneePostal);
      formData.append("orderReceiveDoor", "");
      formData.append("orderReceiveAddr", this.consigneeAddress);
      formData.append("orderAnalysisReceiveAddr", this.consigneeAddress);
      formData.append("orderReceiveLng", 0.1);
      formData.append("orderReceiveLat", 0.1);


      formData.append("orderGoodsWeight", this.orderWeight);
      //formData.append("orderGoodsType", this.items[i].field5);
      formData.append("orderGoodsDistance", 20);
      formData.append("orderRequiredTime", dateRequiredTime.valueOf());
      formData.append("orderTotalPrice", this.orderTotalPrice);
      formData.append("orderTransType", 6);
      formData.append("orderRemark", "");
      formData.append("totalPrice", this.orderTotalPrice);


        formData.append("orderLength", this.orderLength);
        formData.append("orderWidth", this.orderWidth);
        formData.append("orderHeight", this.orderHeight);

        formData.append("orderPickupCountry", this.pickupCountry);
        formData.append("orderPickupState", this.pickupState);
        formData.append("orderDestinationCountry", this.consigneeCountry);
        formData.append("orderDestinationState", this.consigneeState);





        var itemsObj: string = "";

        itemsObj = itemsObj + "[{'itemDesc':'"+this.itemDesc+"','itemQuantity':1,'itemCategory':'"+this.itemCategory+"','itemPrice':"+this.itemPriceValue+",'itemPriceCurrency':'"+this.itemPriceCurrency+"'}";


        for(var i = 0; i < this.fieldArray.length; i++){
          console.log(this.fieldArray[i]);

          itemsObj = itemsObj + ",{'itemDesc':'"+this.fieldArray[i].desc+"','itemQuantity':1,'itemCategory':'"+this.fieldArray[i].category+"','itemPrice':"+this.fieldArray[i].priceValue+",'itemPriceCurrency':'"+this.fieldArray[i].priceValue+"'}";
        }

        itemsObj = itemsObj + "]";

        formData.append("orderItems", itemsObj);





        formData.append("uuid", localStorage.getItem('uuid'));
        formData.append("zdtype", "web");
        formData.append("token", localStorage.getItem('token'));


        formData.append("payType", "ACCOUNT");
        formData.append("payPassword", "740725");





        console.log(formData);





//      this._http
//        .post(
//          `http://uml.arriival.com/qmcs-u-api/order/generatingOrderNew`,
//          formData
//        )
//        .pipe(
//          catchError((err: HttpErrorResponse) => {
//            return throwError("Something bad happened; please try again later.");
//          }) // then handle the error
//        )
//        .subscribe((respObj) => {
//          console.log(respObj);
//          if (respObj["code"] == 200) {
//            alert("SUCCESS");
//            // localStorage.setItem('uuid', respObj["data"]["user"]["userId"]);
//            // localStorage.setItem('token', respObj["data"]["token"]);
//            // localStorage.setItem('username', respObj["data"]["user"]["userLoginName"]);
//            // localStorage.setItem('usertel', respObj["data"]["user"]["userTelphone"]);
//            // this.router.navigate(['/main/']);
//
//
//            //$location.url('/foo2?name=bunny2&age=12#myhash2');
//            //alert(respObj["data"]["user"]["userId"]);
//            // proceed to register
//          } else {
//            alert(respObj["enMsg"]);
//          }
//        });

  }




}
