<!doctype html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <title>Sample layout for redeem item </title>
    <link rel="shortcut icon" href="assets/favicon.ico">

    <!-- <link href="css/bootstrap-min.css" type="text/css" rel="stylesheet"> -->
    <!-- <link href='https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'> -->
    <!-- <script src="https://kit.fontawesome.com/938193368b.js" crossorigin="anonymous"></script> -->
    <meta name="viewport" content="width=device-width, user-scalable=no">

    <!-- <link rel="stylesheet" href="/assets/css/addon.css"> -->
    <!-- <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script> -->
</head>

<body style="margin-top:20px;">
<div id="hidden_div" style="display: none;">Hello hidden content</div>

        <div class="container">
            <div class="row">
                <div class="col-md-12 col-xs-12" >
                    <h2>Buy Credit</h2>
                    <span>Credits are in MYR (Malaysian Ringgit), are non-refundable and will expire one year from your last purchase. For larger amounts, please contact the Support Team.</span>

                    <h4 class="mt30">How much credits do you want to purchase ?</h4>
                </div>

                 <div class="col-md-1 col-xs-2" >
                        <div style="position: absolute">
                        <img src="assets/img/images/img-credit.png" style="width:40px; height:auto;">
                        </div>
                 </div>

                 <div class="col-md-11 col-xs-12 iconspace">
                            <div class="col-md-4">
                                <select class="mb20" (change)="setCredit($event)">
                                    <option value="50"> RM50 Credit </option>
                                    <option value="100"> RM100 Credit</option>
                                    <option value="300"> RM300 Credit</option>
                                    <option value="600"> RM600 Credit</option>
                                    <option value="1000"> RM1,000 Credit</option>
                                    <option value="2000"> RM2,000 Credit</option>
                                    <option value="4000" selected> RM4,000 Credit</option>
                                </select>
                            </div>
                            <div class="col-md-8 col-xs-12 emptyicon" id="offterDiv">
                                <span class="optiontxt-lg">Offer: &nbsp;&nbsp;<br class="br"> </span>
                                <label class="optiontxt-lg"><input type="radio" id="offterGiftItems" name="offterType" value="giftItems" checked (change)="setView()">
                                    Gift Items
                                    </label>
                                    &nbsp;&nbsp;
                                <label class="optiontxt-lg" id="offterPointDiv">
                                <input type="radio" id="offterPoint" name="offterType" value="arriival-point" (change)="setView()"> Rebate <span id="arriivalPoint" >60</span> Arriival Point
                                </label>
                            </div>
                            <div>&nbsp;</div>
                 </div>
            </div>
        </div>



        <!-- redeem item -->
        <div class="container sectionitem " id="redeem_div">
            <div  class="row">
                <div class="col-md-12 mb10" >
                    <h4>Gift items as below
                        <br class="br"><span class="shipingmsg"> Shipping fee : RM<span id="arriivalShippingFee" >36.80</span></span>
                    </h4>

                </div>
            </div>
            <div  class="row" id="giftItemsDiv">
                <div class="col-md-3 rowclearpd"  id="printerDiv">
                    <label class="itemname font-weight-bold">
                    <div class="col-md-10 col-xs-5"><img src="assets/img/images/hp-thumbnail-printer.jpg" class="itemimg"></div>
                    <div class="col-md-12 col-xs-7"><input type="checkbox" id="printer" name="printer" value="Printer"  (change)="setView()"> Waybill Printer</div>
                </label>
                </div>

                 <div class="col-md-3 rowclearpd"  id="stickerDiv">
                    <label class="itemname font-weight-bold">
                    <div class="col-md-10 col-xs-5"><img src="assets/img/images/hp-thumbnail-waybill-sticker.jpg" class="itemimg"></div>
                    <div class="col-md-12 col-xs-7"><input type="checkbox" id="sticker" name="sticker" value="Sticker"  checked (change)="setView()"> Waybill Sticker
                        (max :<span id="arriivalWaybillSticker" >600</span> pcs)</div>
                </label>
                </div>

                <div class="col-md-3 rowclearpd" >
                    <label class="itemname font-weight-bold">
                    <div class="col-md-10 col-xs-5">
                    <img src="assets/img/images/hp-thumbnail-courier-bag.jpg" class="itemimg">
                    </div>
                    <div class="col-md-12 col-xs-7">
                    <input type="checkbox" id="courierbag" name="courierbag" value="courier_bag"  checked (change)="setView()"> Courier Bag + Fragile Sticker <br>&nbsp;&nbsp;(max : <span id="arriivalWaybillBag" >600</span> pcs)
                    </div>
                </label>
                    <div class="courier_bag box mt10 " >
                        <div class="row ">
                            <div class="col-md-12">
                                <span> Courier Bag size &amp; Pcs: </span>
                            </div>

                            <div class="col-md-4 col-xs-4">
                                <label>S (A4)<br>
                                <input type="text" name="spcs" [(ngModel)]="spcs" value="0" class="itemqty"> pcs
                            <br>
                            </label>
                            </div>

                            <div class="col-md-4 col-xs-4">
                                <label>M (A3)<br>
                                <input type="text" name="mpcs" [(ngModel)]="mpcs" value="0 " class="itemqty"> pcs
                            <br>
                            </label>
                            </div>

                            <div class="col-md-4 col-xs-4">
                                <label>L (A2)<br>
                                <input type="text" name="lpcs" [(ngModel)]="lpcs" value="0" class="itemqty"> pcs
                            <br>
                            </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        <!-- redeem item -->


    <div class="sectionitem">
            <div class="row">
                <div class="col-md-5 mb20" >
                    <h4>Receiver info:</h4>
                    <div class="form-group">
                        <input type="text" required placeholder="Email" class="form-control" name="ReceiverEmail" [(ngModel)]="ReceiverEmail">
                    </div>
                    <div id="receiverDiv">
                        
                        <div class="form-group">
                            <input type="text" required placeholder="Receiver Name" class="form-control" name="ReceiverName" [(ngModel)]="ReceiverName">
                        </div>

                        <div class="form-group">
                            <input type="text" required placeholder="Contact Number" class="form-control" name="ContactNumber" [(ngModel)]="ContactNumber">
                        </div>
                        <div class="form-group">
                            <textarea required placeholder="Full Address" rows="4" class="form-control" name="Address" [(ngModel)]="Address"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 mb20" >
                    <div class="mb20">
                        <h3 class="priceamt">Total amount: RM<span id="arriivalTotalAmount" >4140.80</span></h3>
                        

                     <button class="btn btn-primary" (click)="submitOrder()">  Process Payment </button>
                    </div>
                    <span align="justiy" class="smalltext"> <b style="text-transform: uppercase;">*important note:</b> <br>text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a </span>
                </div>
            </div>

    </div><p>&nbsp;</p>

 </div>


    <!-- <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js"></script> -->
    <!-- <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"></script> -->



</body>

</html>
