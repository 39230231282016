import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-draft',
  templateUrl: './draft.component.html',
  styleUrls: ['./draft.component.css']
})
export class DraftComponent implements OnInit {

  cardDataset:any[] = [{
    "date": "10th Nov 2020",
    "time": "14:30",
    "address": "SS2, Petaling Jaya, Selangor, Malaysia",
    "name": "Colin",
    "mobile": "017-372 4857",
    "email": "colin@email.com"
  }, {
    "date": "13th Nov 2020",
    "time": "15:00",
    "address": "SS5, Petaling Jaya, Selangor, Malaysia",
    "name": "Mary",
    "mobile": "018-947 3083",
    "email": "mary@email.com"
  }, {
    "date": "22th Nov 2020",
    "time": "17:00",
    "address": "SS1, Petaling Jaya, Selangor, Malaysia",
    "name": "Connie",
    "mobile": "016-372 4728",
    "email": "connie@email.com"
  }];

  constructor() { }

  ngOnInit(): void {
  }

}
