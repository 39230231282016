<div class="content col-md-9 col-lg-12 pt-3 mb-5">
  <div class="row">

    <!-- Form -->
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-12">
          <h2>Place Order</h2>
          <p>Enter your booking details and let us know if you have any special arrangements needed.</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <p>
            <span class="mr-3 step-span">Step 1</span>
            <span class="text-muted">Select your delivery country</span>
          </p>
        </div>
        <div class="col-md-4 text-right">
          <button type="button" class="btn btn-custom-hover btn-info" name="button" [routerLink]="['/main/delivery/international/mode/csv/']">Submit Bulk Orders</button>
        </div>
      </div>
      <div class="" style="border-bottom: 1px solid rgba(0, 0, 0, .1);"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row mt-3 international-form-alignment">
            <div class="col-md-4">
              <label class="label-control" for="">Pickup Country <span class="text-danger">*</span></label>
            </div>
            <div class="col-md-8">
              <select class="form-control" name="pickupCountry" [(ngModel)]="pickupCountry">
                <option *ngFor="let country of pickupCountries">{{ country.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row international-form-alignment">
            <div class="col-md-4">
              <label class="label-control" for="">Destination Country <span class="text-danger">*</span></label>
            </div>
            <div class="col-md-8">
              <select class="form-control" name="consigneeCountry" [(ngModel)]="consigneeCountry">
                <option *ngFor="let country of destinationCountries">{{ country.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="alert bg-green text-light">
            <p>Singapore Delivery was start operating. 5 - 7 working days for completed delivery to Singapore.</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <span class="mr-3 step-span">Step 2</span>
          <span class="text-muted">Tell us where to go</span>
        </div>
      </div>
      <div class="" style="border-bottom: 1px solid rgba(0, 0, 0, .1);"></div>

      <div class="row">
        <div class="col-md-12">
          <i class="fas fa-dot-circle point-circle pick-up"></i>
          <div class="delivery-form-decoration">
            <h3>Pick Up Point</h3>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Pick up state <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-8 input-group">
                <select class="form-control" name="pickupState" [(ngModel)]="pickupState">
                  <option *ngFor="let state of states">{{ state.name }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Pick up address <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-8 input-group">
                <input type="text" class="form-control" name="pickupAddress" [(ngModel)]="pickupAddress" value="" placeholder="Address Details" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary form-control-button" type="button">
                    <i class="fa fa-heart text-danger"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Postcode <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="pickupPostal" [(ngModel)]="pickupPostal" placeholder="" (change)="checkOrderPrice()"/>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Sender Mobile Number <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-8">
                <!-- <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                  </div>
                  <input type="text" class="form-control" name="pickupContactNumber" [(ngModel)]="pickupContactNumber" value="" />
                </div> -->
                <div class="input-prefix">
    							<span class="prefix text-muted">601</span>
                    <input type="text" class="form-control input-field mb-3" name="" value="" [(ngModel)]="pickupContactNumber" />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Sender Name</label>
              </div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="pickupContactName" [(ngModel)]="pickupContactName" placeholder="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <i class="fas fa-dot-circle point-circle deliver"></i>
          <div class="delivery-form-decoration">
            <h3>Delivery Point</h3>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Delivery state <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-8 input-group">
                <select class="form-control" name="consigneeState" [(ngModel)]="consigneeState">
                  <option *ngFor="let destinationState of destinationStates">{{ destinationState.name }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Delivery address <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-8 input-group">
                <input type="text" class="form-control" name="consigneeAddress" [(ngModel)]="consigneeAddress" value="" placeholder="Address Details" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary form-control-button" type="button">
                    <i class="fa fa-heart text-danger"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Postcode <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="consigneePostal" [(ngModel)]="consigneePostal" value="" placeholder="" (change)="checkOrderPrice()"/>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Recipient Mobile Number <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-8">
                <!-- <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">+60</span>
                  </div>
                  <input type="text" class="form-control" name="consigneeNumber" [(ngModel)]="consigneeNumber" value="" />
                </div> -->
                <div class="input-prefix">
    							<span class="prefix text-muted">601</span>
                    <input type="text" class="form-control input-field mb-3" name="" value="" [(ngModel)]="consigneeNumber" />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Recipient Name</label>
              </div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="consigneeName" [(ngModel)]="consigneeName" value="" placeholder="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="delivery-stock-decoration">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Package Details <span class="text-danger">*</span></label>
                <img src="/assets/img/box.jpg" alt="" />
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <label for="">Length</label>
                    <input type="text" class="form-control" name="orderLength" [(ngModel)]="orderLength" placeholder="cm" (change)="checkOrderPrice()"/>
                  </div>
                  <div class="col-md-6">
                    <label for="">Width</label>
                    <input type="text" class="form-control" name="orderWidth" [(ngModel)]="orderWidth" placeholder="cm" (change)="checkOrderPrice()"/>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <label for="">Height</label>
                    <input type="text" class="form-control" name="orderHeight" [(ngModel)]="orderHeight" placeholder="cm" (change)="checkOrderPrice()"/>
                  </div>
                  <div class="col-md-6">
                    <label for="">Weight</label>
                    <input type="text" class="form-control" name="orderWeight" [(ngModel)]="orderWeight" placeholder="kg" (change)="checkOrderPrice()"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="delivery-stock-decoration">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Parcel Type <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-4">
                <select class="form-control" name="itemCategory" [(ngModel)]="itemCategory">
                  <option value="Electronics">Electronics</option>
                  <option value="Fashion Apparel">Fashion &amp; Apparel</option>
                  <option value="Lifestyle Products">Lifestyle &amp; Products</option>
                  <option value="" selected>Other..</option>
                </select>
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" name="itemCategory" [(ngModel)]="itemCategory" placeholder="Specific your category" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="delivery-stock-decoration">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="label-control" for="">Item price value (RM) <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" name="itemPriceValue" [(ngModel)]="itemPriceValue" placeholder="Per Parcel" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div *ngIf="fieldArray.length > 0">
            <div *ngFor="let item of fieldArray; index as i">
              <hr />
              <div class="offset-md-8 col-md-4 text-right mb-3">
                <button type="button" class="btn btn-sm btn-default text-danger" name="button" (click)="removeItem(i)">
                  <i class="fa fa-times"></i> Remove Item
                </button>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div id="divItemArray{{i}}">
                    <div class="delivery-stock-decoration">
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label class="label-control" for="">Parcel Type <span class="text-danger">*</span></label>
                        </div>
                        <div class="col-md-4">
                          <select class="form-control" name="{{item.category}}" [(ngModel)]="item.category">
                            <option value="Electronics">Electronics</option>
                            <option value="Fashion Apparel">Fashion &amp; Apparel</option>
                            <option value="Lifestyle Products">Lifestyle &amp; Products</option>
                            <option value="" selected>Other..</option>
                          </select>
                        </div>
                        <div class="col-md-4">
                          <input type="text" class="form-control" name="{{item.category}}" [(ngModel)]="item.category" placeholder="Specific your category" />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label class="label-control" for="">Item price value (RM) <span class="text-danger">*</span></label>
                        </div>
                        <div class="col-md-4">
                          <input type="text" class="form-control" name="{{item.priceValue}}" [(ngModel)]="item.priceValue" placeholder="Per Parcel" />
                        </div>
                      </div>
                      <!-- <div class="form-group row">
                        <div class="col-md-4">
                          <label class="label-control" for="">Description</label>
                        </div>
                        <div class="col-md-8">
                          <textarea name="{{item.desc}}" class="form-control" rows="8" cols="80" [(ngModel)]="item.desc"></textarea>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3" style="border-bottom: 1px solid rgba(0, 0, 0, .1);"></div>
      <div class="row" style="padding-left: 18px;">
        <div class="col-md-12">
          <button type="button" class="btn btn-sm btn-primary" name="button" (click)="addNewItem()">
            <i class="fa fa-plus"></i> Add Item
          </button>
        </div>
      </div>
      <span style="padding-left: 18px;" class="text-danger">(*) indicate Mandatory field</span>

      <div class="row">
        <div class="col-md-12">
          <div class="delivery-stock-decoration">
            <div class="row">
              <div class="col-md-6">
                <label class="label-control" for="">Notes to Your Driver:</label>
                <textarea name="itemDesc" class="form-control" rows="4" cols="80" [(ngModel)]="itemDesc" placeholder="e.g. Call me before reach to avoid waiting time. Thank you"></textarea>
              </div>
              <div class="col-md-6">
                <label class="label-control" for="">Order Value Declaration</label>
                  <label for="agreement">
                    <input type="checkbox" id="agreement" name="" value="" /> I confirmed that the declared order value given in this form is correct and accurate. &nbsp;
                  </label>
                  <ng-template #tipContent>This information will be used to generate custom declaration for duties and taxes
                    purposes.</ng-template>
                  <a style="border-bottom: 1px dotted;" [ngbTooltip]="tipContent" placement="right" href="javascript:void(0);">Why is this needed?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Cart -->
    <div class="col-md-4 position-relative">
      <div class="h-100">
        <div class="cart-outer-box p-3"
          style="border: 1px solid var(--primary-color);border-radius: 5px;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);z-index: 1;background-color: white;position: sticky;top: 75px;">
          <div class="cart-inner-box">
            <div class="cart-header mb-3">
              <span class="text-uppercase font-weight-bold text-primary" style="font-size: 20px;">your order:</span>
            </div>
            <div class="cart-body">
              <div class="cart-body-header d-flex justify-content-between align-items-end">
                <span style="font-size: 16px;">Price Details</span>
                <i *ngIf="!isCollapsed" style="font-size: 16px;" (click)="isCollapsed = true"
                  class="fa fa-times text-danger"></i>
                <i *ngIf="isCollapsed" style="font-size: 16px;" (click)="isCollapsed = false" class="fas fa-bars"></i>
              </div>
              <div *ngIf="isCollapsed" class="row py-2">
                <div class="col-md-8">
                  <!-- <span>{{ cartItems[0].items.length }} Parcels Delivery Charges</span> -->
                  <span>Parcels Delivery Charges</span>
                </div>
                <div class="col-md-4 text-right">
                  <!-- <span>RM {{ cartItems[0].total }}</span> -->
                  <span>RM {{ totalPriceToPay }}</span>
                </div>
              </div>
              <div *ngIf="!isCollapsed" style="font-size: 12px;">
                <!-- [ngClass]="{'collapse-animate': isCollapsed, 'cart-body-content': !isCollapsed}" -->
                <!-- <div class="row py-2" *ngFor="let item of cartItems[0].items"> -->
                <div class="row py-2" *ngFor="let item of cartDetails">
                  <div class="col-md-4">
                    <!-- <span>{{ item.qty }} x {{ item.name }}</span> -->
                    <span>1 x parcel</span>
                  </div>
                  <div class="col-md-4">
                    <!-- <span>(RM{{ item.price }} x {{ item.qty }})</span> -->
                    <span>(RM{{ item }} x 1)</span>
                  </div>
                  <div class="col-md-4 text-right">
                    <!-- <span>RM {{ item.sub_total }}</span> -->
                    <span>RM {{ item }}</span>
                  </div>
                </div>
              </div>
              <div class="cart-body-footer mb-3">
                <div class="row mx-0">
                  <div class="col-md-12 text-right" style="border-top: 1px solid black;border-bottom: double black;">
                    <!-- <span class="font-weight-bold" style="font-size: 16px;">Total: </span><span class="font-weight-bold" style="font-size: 20px;">RM {{ cartItems[0].total }}</span> -->
                    <span class="font-weight-bold" style="font-size: 16px;">Total: </span><span
                      class="font-weight-bold" style="font-size: 20px;">RM {{ totalPriceToPay }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <span style="font-weight: 600;" class="mr-2 text-primary">Corporate Price RM{{ totalPriceToPay }}</span><i
                      class="far fa-question-circle"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="cart-footer">
              <div class="mb-2">
                <span *ngIf="!activateCouponField">Click <a (click)="showCouponField()"
                    href="javascript:void(0);">here</a> to enter discount coupon</span>
                <div class="d-flex flex-row nowrap" style="align-items:flex-center;align-content:flex-center;"
                  *ngIf="activateCouponField">
                  <div class="pr-2" style="height: 28px;">
                    <label style="font-size: 14px;" for="">Coupon code</label>
                  </div>
                  <input class="mr-2" type="text" name="" value="" style="width: 40%;height: 28px;" />
                  <button type="button" class="btn btn-sm btn-secondary mr-2" name="button"
                    style="height: 28px;padding-top: 0;">Apply</button>
                  <div (click)="hideCouponField()" style="height: 28px;"><i class="fa fa-times text-danger"></i></div>
                </div>
              </div>
              <div class="mb-2">
                <button type="button" class="btn btn-primary btn-block" name="button" data-target="#internationalSubmitOrderModal" data-toggle="modal">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="flex-fill">Buy Credit & Proceed with RM{{ totalPriceToPay }}</span>
                    <i class="fa fa-long-arrow-right"></i>
                  </div>
                </button>
              </div>
              <div>
                <button type="button" class="btn btn-secondary btn-block" data-target="#internationalSubmitOrderModal" data-toggle="modal" name="button">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="flex-fill">Proceed Instant pay as RM{{ totalPriceToPay }}</span>
                    <i class="fa fa-long-arrow-right"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-5">
      <h2>Place Order</h2>
    </div>
  </div> -->

  <!-- <div class="row">
    <div class="col-md-9">
      <p>Enter your booking details and let us know if you have any special arrangements needed.</p>
    </div>
  </div> -->

  <!-- <form class="mb-5 pb-5" action="index.html" method="post"> -->
    <!-- <p>
      <span class="mr-3 step-span">Step 1</span>
      <span class="text-muted">Select your delivery country</span>
    </p>
    <hr /> -->

    <!-- <div class="row">
      <div class="col-md-8">
        <small class="text-danger font-italic">* Malaysia to Singapore / China to Malaysia</small>
        <div class="form-group row mt-3 international-form-alignment">
          <div class="col-md-4">
            <label class="label-control" for="">Pickup Country</label>
          </div>
          <div class="col-md-6">
            <select class="form-control" name="pickupCountry" [(ngModel)]="pickupCountry">
              <option *ngFor="let country of pickupCountries">{{ country.name }}</option>
            </select>
          </div>
        </div>
        <div class="form-group row international-form-alignment">
          <div class="col-md-4">
            <label class="label-control" for="">Destination Country</label>
          </div>
          <div class="col-md-6">
            <select class="form-control" name="consigneeCountry" [(ngModel)]="consigneeCountry">
              <option *ngFor="let country of destinationCountries">{{ country.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col-md-7 pr-5">
        <div class="alert bg-green text-light">
          <p>Singapore Delivery was start operating.5-7 working days for completed delivery to Singapore.</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col-md-3">
        <span class="mr-3 step-span">Step 2</span>
        <span class="text-muted">Tell us where to go</span>
      </div>
      <div class="col-md-4 text-right pr-5">
        <button type="button" class="btn btn-custom-hover btn-success" name="button" [routerLink]="['/main/delivery/international/mode/csv/']">Submit Bulk Orders</button>
      </div>
    </div> -->

    <!-- <hr /> -->
    <!-- <div class="row">
      <div class="col-md-8">
        <i class="fas fa-dot-circle point-circle pick-up"></i>
        <div class="delivery-form-decoration">
          <h3>Pick Up Point</h3>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Pick up state</label>
            </div>
            <div class="col-md-6 input-group">
              <select class="form-control" name="pickupState" [(ngModel)]="pickupState">
                <option *ngFor="let state of states">{{ state.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Pick up address</label>
            </div>
            <div class="col-md-6 input-group">
              <input type="text" class="form-control" name="pickupAddress" [(ngModel)]="pickupAddress" value="" placeholder="Street / Postcode / Landmark" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary form-control-button" type="button">
                  <i class="fa fa-heart text-danger"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Postcode</label>
            </div>
            <div class="col-md-6">
              <input type="text" class="form-control" name="pickupPostal" [(ngModel)]="pickupPostal" placeholder="Postal" (change)="checkOrderPrice()"/>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Sender Name</label>
            </div>
            <div class="col-md-6">
              <input type="text" class="form-control" name="pickupContactName" [(ngModel)]="pickupContactName" placeholder="Sender Name" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Sender Mobile Number</label>
            </div>
            <div class="col-md-6">
              <input type="text" class="form-control" name="pickupContactNumber" [(ngModel)]="pickupContactNumber" placeholder="Sender Mobile Number" />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col-md-8">
        <i class="fas fa-dot-circle point-circle deliver"></i>
        <div class="delivery-form-decoration">
          <h3>Delivery Point</h3>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Delivery country</label>
            </div>
            <div class="col-md-6 input-group">

              <select class="form-control" name="consigneeState" [(ngModel)]="consigneeState">
                <option *ngFor="let destinationState of destinationStates">{{ destinationState.name }}</option>
              </select>


            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Delivery address</label>
            </div>
            <div class="col-md-6 input-group">
              <input type="text" class="form-control" name="consigneeAddress" [(ngModel)]="consigneeAddress" value="" placeholder="Street / Postcode / Landmark" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary form-control-button" type="button">
                  <i class="fa fa-heart text-danger"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Postcode</label>
            </div>
            <div class="col-md-6">
              <input type="text" class="form-control" name="consigneePostal" [(ngModel)]="consigneePostal" value="" placeholder="Postcode" (change)="checkOrderPrice()"/>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Recipient Name</label>
            </div>
            <div class="col-md-6">
              <input type="text" class="form-control" name="consigneeName" [(ngModel)]="consigneeName" value="" placeholder="Recipient Name" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Recipient Mobile Number</label>
            </div>
            <div class="col-md-6">
              <input type="text" class="form-control" name="consigneeNumber" [(ngModel)]="consigneeNumber" value="" placeholder="Recipient Mobile Number" />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="row"> -->
      <!-- <div class="col-md-8"> -->
        <!-- Stock Information -->
        <!-- <div class="delivery-stock-decoration">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="label-control" for="">Package Details</label>
              <img src="/assets/img/box.jpg" alt="" />
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <label for="">Length</label>
                  <input type="text" class="form-control" name="orderLength" [(ngModel)]="orderLength" placeholder="cm" (change)="checkOrderPrice()"/>
                </div>
                <div class="col-md-6">
                  <label for="">Width</label>
                  <input type="text" class="form-control" name="orderWidth" [(ngModel)]="orderWidth" placeholder="cm" (change)="checkOrderPrice()"/>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-6">
                  <label for="">Height</label>
                  <input type="text" class="form-control" name="orderHeight" [(ngModel)]="orderHeight" placeholder="cm" (change)="checkOrderPrice()"/>
                </div>
                <div class="col-md-6">
                  <label for="">Weight</label>
                  <input type="text" class="form-control" name="orderWeight" [(ngModel)]="orderWeight" placeholder="kg" (change)="checkOrderPrice()"/>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="form-group row">
          <div class="col-md-4">
            <label class="label-control" for="">Parcel Type</label>
          </div>
          <div class="col-md-6">
            <select class="form-control" name="itemCategory" [(ngModel)]="itemCategory">
              <option value="Electronics">Electronics</option>
              <option value="Fashion Apparel">Fashion &amp; Apparel</option>
              <option value="Lifestyle Products">Lifestyle &amp; Products</option>
              <option value="" selected>Other..</option>
            </select>
          </div>
          <div class="offset-md-4 col-md-6">
            <input type="text" class="form-control" name="itemCategory" [(ngModel)]="itemCategory" placeholder="Specific your category" />
          </div>
        </div> -->
        <!-- <div class="form-group row">
          <div class="col-md-4">
            <label class="label-control" for="">Price</label>
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" name="itemPriceValue" [(ngModel)]="itemPriceValue" placeholder="Price" />
          </div>
        </div> -->
        <!-- <div class="form-group row">
          <div class="col-md-4">
            <label class="label-control" for="">Description</label>
          </div>
          <div class="col-md-6">
            <textarea name="itemDesc" class="form-control" rows="8" cols="80" [(ngModel)]="itemDesc"></textarea>
          </div>
        </div> -->
      <!-- </div> -->

      <!-- <div *ngIf="fieldArray.length > 0">
        <div *ngFor="let item of fieldArray; index as i">
          <div class="col-md-12">
            <hr />
          </div>

          <div class="row">
            <div class="col-md-8">
              <div id="divItemArray{{i}}">
                <div class="delivery-stock-decoration">
                  <div class="form-group row">
                    <div class="col-md-4">
                      <label class="label-control" for="">Parcel Type</label>
                    </div>
                    <div class="col-md-6">
                      <select class="form-control" name="{{item.category}}" [(ngModel)]="item.category">
                        <option value="Electronics">Electronics</option>
                        <option value="Fashion Apparel">Fashion &amp; Apparel</option>
                        <option value="Lifestyle Products">Lifestyle &amp; Products</option>
                        <option value="" selected>Other..</option>
                      </select>
                    </div>
                    <div class="offset-md-4 col-md-6">
                      <input type="text" class="form-control" name="{{item.category}}" [(ngModel)]="item.category" placeholder="Specific your category" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-4">
                      <label class="label-control" for="">Price</label>
                    </div>
                    <div class="col-md-6">
                      <input type="text" class="form-control" name="{{item.priceValue}}" [(ngModel)]="item.priceValue" placeholder="Price" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-4">
                      <label class="label-control" for="">Description</label>
                    </div>
                    <div class="col-md-6">
                      <textarea name="{{item.desc}}" class="form-control" rows="8" cols="80" [(ngModel)]="item.desc"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 text-right">
              <button type="button" class="btn btn-sm btn-default text-danger" name="button" (click)="removeItem(i)">
                <i class="fa fa-times"></i> Remove Item
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-md-12">
        <hr />
      </div> -->

      <!-- <div class="col-md-6 mb-3">
        <button type="button" class="btn btn-sm btn-primary" name="button" (click)="addNewItem()">
          <i class="fa fa-plus"></i> Add Item
        </button>
      </div>
      <div class="col-md-6 text-right">
        <button type="button" class="btn btn-sm btn-default text-danger" name="button">
          <i class="fa fa-times"></i> Remove Item
        </button>
      </div> -->
      <!-- <div class="col-md-12 mb-2">
        <span>Order Value Declaration</span>
      </div> -->
      <!-- <div class="col-md-12 mb-5">
        <label for="agreement">
          <input type="checkbox" id="agreement" name="" value="" /> I confirmed that the declared order value given in this form is correct and accurate. &nbsp;
        </label>
        <a style="border-bottom: 1px dotted;" href="#">Why is this needed?</a>
      </div> -->

      <!-- <div class="col-md-12 text-left"> -->
        <!-- <button type="button" data-target="#internationalSubmitOrderModal" data-toggle="modal" class="btn btn-primary btn-round mr-3" name="button">Submit Order</button> -->
        <!-- <button type="button" (click)="submitOrder()" class="btn btn-primary btn-round mr-3" name="button">Preview Order</button> -->
        <!-- <button type="button" (click)="sendOrder()" class="btn btn-success btn-round mr-3" name="button">Manage Orders</button> -->
        <!-- <button type="button" (click)="sendOrder()" class="btn btn-warning btn-round" name="button">Pickup Request</button> -->
      <!-- </div> -->
    <!-- </div> -->
  <!-- </form> -->
</div>

<div class="modal fade" id="internationalSubmitOrderModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content" >
      <!-- <div class="modal-header justify-content-start" style="background-color: #0089CF;color: white;">
        <button type="button" class="btn btn-default" style="color: white;" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">
          <span>international Delivery</span>
        </h4>
      </div> -->
      <div class="modal-body" style=" padding-left:25px; padding-right:25px; padding-bottom:20px;">
        <div>
          <img src="assets/img/3popimg-parcel-international.jpg" alt="parcel-express" />
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary mr-2" name="button">Cancel</button>
        <button type="button" class="btn btn-primary mr-2" [routerLink]="['/main/order/list/']" name="button">Manage order</button>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-round mr-2" name="button">Back to order</button>
        <button type="button" (click)="submitOrder()" class="btn btn-primary btn-round mr-2" name="button">Submit order</button>
      </div> -->
    </div>
  </div>
</div>
