import { Component, OnInit } from "@angular/core";
import * as XLSX from "xlsx";
import { Router } from "@angular/router";

@Component({
  selector: "app-csv",
  templateUrl: "./csv.component.html",
  styleUrls: ["./csv.component.css"],
})
export class CSVComponent implements OnInit {
  public filename: string;
  willDownload = false;
  public dataString: string;

  constructor(private router: Router) { }

  ngOnInit(): void { }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: "binary" });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.dataString = JSON.stringify(jsonData);

      console.log(this.dataString);
    };
    reader.readAsBinaryString(file);
  }

  setDownload(data) {
    this.willDownload = true;
    setTimeout(() => {
      const el = document.querySelector("#download");
      el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
      el.setAttribute("download", 'xlsxtojson.json');
    }, 1000)
  }

  convertFile() {
    localStorage.setItem('bulkUploadJson', this.dataString);

    this.router.navigate(['/main/delivery/domestic/'], { queryParams: { m: localStorage.getItem("saveQueryParamFromDomestic") } });
  }
}
