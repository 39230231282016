import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest
} from "@angular/common/http";
import { catchError, retry, first } from "rxjs/operators";
import { throwError } from "rxjs";
import { MapsAPILoader } from '@agm/core';

import $ from 'jquery'
import { element } from 'protractor';
declare var $: $

//import * as rxjsNumeric from "rxjs/util/isNumeric";

@Component({
  selector: "app-domestic",
  templateUrl: "./domestic.component.html",
  styleUrls: ["./domestic.component.css"],
})

export class DomesticComponent implements OnInit {
  @ViewChild('name', { static: false }) nameInput: ElementRef;
  @ViewChild('address', { static: false }) addressInput: ElementRef;
  @ViewChild('mobile', { static: false }) mobileInput: ElementRef;
  @ViewChild('areaCode', { static: false }) areaCodeInput: ElementRef;
  @ViewChild('addressDoor', { static: false }) addressDoorInput: ElementRef;

  public lat;
  public lng;
  public chosenAddress: any[];
  public recordDeliveryPointIndex: any;
  public totalPriceToPay = 0;
  public isCollapsed = false;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  public displayAddress;

  public address_name;
  public address_address;
  public address_mobile;
  public address_area_code;
  public address_door;

  parcelSize: any[] = [
    {
      size: "120(L) X 70(H) X 70(W)",
      value: "123",
    },
    {
      size: "150(L) X 150(H) X 150(W)",
      value: "124",
    },
  ];

  weight: any[] = [
    {
      size: "< 20kg",
      value: "1",
    },
    {
      size: "< 30kg",
      value: "2",
    },
    {
      size: "< 70kg",
      value: "3",
    }
  ];

  public setAddressAction;

  public my_address: any[];
  public sendAddress: string;
  public sendPostcode: string;
  public sendName: string;
  public sendPhone: string;
  public sendRemark: string;


  activateNotAirFreight: Boolean = false;
  activateCourier: Boolean = true;
  activateCargo: Boolean = false;
  activateAirFreight: Boolean = false;

  doorToDoor: Boolean = true;
  airOrSea: Boolean = true;
  activateCouponField: Boolean = false;

  cartItems: any = [{
    "total": "48.40",
    "items": [{
      "qty": "2",
      "name": "Parcels",
      "price": "8.30",
      "sub_total": "16.60"
    }, {
      "qty": "1",
      "name": "Parcel",
      "price": "6.80",
      "sub_total": "6.80"
    }, {
      "qty": "1",
      "name": "Oversized",
      "price": "10.00",
      "sub_total": "10.00"
    }, {
      "qty": "1",
      "name": "Overweight",
      "price": "15.00",
      "sub_total": "15.00"
    }]
  }];

  items: any = [
    {
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "123",
      field6: "",
      field7: "",
      field8: "",
      field9: "",
      field10: "",
      field11: "",
    },
    {
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "123",
      field6: "",
      field7: "",
      field8: "",
      field9: "",
      field10: "",
      field11: "",
    },
  ];

  cartDetails: any[];

  data: number[] = [];
  dummyArr: number[] = [];

  orderitemnum: number = 2;

  orderTransType: number = 0;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    public _http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) { }

  ngOnInit(): void {
    this.getLocation();
    this.getAllAddress();

    // $(document).ready(function () {
    //     $('[data-toggle="tooltip"]').tooltip();
    // });

    this.data.length = 2;
    this.dummyArr.length = 2;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let m = this.activatedRoute.snapshot.queryParams["m"];

    if (m === "courier") this.activateCourierButton();
    else if (m === "cargo") this.activateCargoButton();
    else if (m === "air-freight") this.activateAirFreightButton();
    else this.activateCourierButton();

    if (localStorage.getItem("bulkUploadJson") != "") {
      this.items = [];
      console.log(this.items);

      var bulkUploadJson = JSON.parse(localStorage.getItem("bulkUploadJson"));

      bulkUploadJson["Sheet1"].forEach(element => {

        this.items.push({
          field1: element["Address Details"],
          field2: element["Postcode"],
          field3: element["Recipient Name"],
          field4: element["Mobile Number"],
          field5: "123",
          field6: "",
          field7: "",
          field8: "",
          field9: "",
          field10: "",
          field11: "",
        });
      });
      console.log(this.items);
    }
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude, '');
      });
    }
  }


  markerDragEnd($event: google.maps.MouseEvent, $action: any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude, $action);
  }

  getAddress(latitude, longitude, action) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;

          this.displayAddress = results[0].formatted_address;

          var splitted = this.displayAddress.split(",");
          var getPostalCodePortion = splitted[splitted.length - 3];

          var postalCodePortionSplitted = getPostalCodePortion.trim().split(" ");
          if (postalCodePortionSplitted.length == 2) {
            this.address_area_code = postalCodePortionSplitted[0];
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  showCouponField(): void {
    this.activateCouponField = true;
  }
  hideCouponField(): void {
    this.activateCouponField = false;
  }

  activateCourierButton(): void {
    this.items.forEach(element => {
      element['field1'] = '';
      element['field2'] = '';
      element['field3'] = '';
      element['field4'] = '';
      element['field5'] = '123';
      element['field6'] = '';
      element['field7'] = '';
      element['field8'] = '';
      element['field9'] = '';
      element['field10'] = '';
      element['field11'] = '';
    });

    this.totalPriceToPay = 0;
    this.cartDetails = [];
    this.orderTransType = 0;
    this.activateCourier = true;
    this.activateCargo = false;
    this.activateAirFreight = false;
    this.activateNotAirFreight = true;
  }
  activateCargoButton(): void {
    this.items.forEach(element => {
      element['field1'] = '';
      element['field2'] = '';
      element['field3'] = '';
      element['field4'] = '';
      element['field5'] = '123';
      element['field6'] = '';
      element['field7'] = '';
      element['field8'] = '';
      element['field9'] = '';
      element['field10'] = '';
      element['field11'] = '';
    });

    this.totalPriceToPay = 0;
    this.cartDetails = [];
    this.orderTransType = 4;
    this.activateCourier = false;
    this.activateCargo = true;
    this.activateAirFreight = false;
    this.activateNotAirFreight = true;
  }
  activateAirFreightButton(): void {
    this.items.forEach(element => {
      element['field7'] = '';
    });

    this.totalPriceToPay = 0;
    this.orderTransType = 5;
    this.activateCourier = false;
    this.activateCargo = false;
    this.activateAirFreight = true;
    this.activateNotAirFreight = false;
  }
  toggleDoorToDoorButton(): void {
    this.doorToDoor = !this.doorToDoor;
  }
  toggleAirOrSeaButton(): void {
    this.airOrSea = !this.airOrSea;
  }

  setAddress(action: any, index: any) {
    this.setAddressAction = action;
    this.recordDeliveryPointIndex = index;
  }

  clearModal(): void {
    $('#myFavouriteModal').modal('hide');

    this.nameInput.nativeElement.value = "";
    this.mobileInput.nativeElement.value = "";
    this.areaCodeInput.nativeElement.value = "";
    this.addressDoorInput.nativeElement.value = "";

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 15;
        });
      });

      $(".pac-container").css("z-index", 9999);
    });
  }

  getAllAddress() {
    var formData: any = new FormData();

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/address/selectAddress`,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        console.log(respObj);
//        if (respObj["code"] == 200) {
//          this.my_address = respObj['data'];
//          // localStorage.setItem('uuid', respObj["data"]["user"]["userId"]);
//          // localStorage.setItem('token', respObj["data"]["token"]);
//          // localStorage.setItem('username', respObj["data"]["user"]["userLoginName"]);
//          // localStorage.setItem('usertel', respObj["data"]["user"]["userTelphone"]);
//          // this.router.navigate(['/main/']);
//
//
//          //$location.url('/foo2?name=bunny2&age=12#myhash2');
//          //alert(respObj["data"]["user"]["userId"]);
//          // proceed to register
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  getLocation() {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    function success(pos) {
      var crd = pos.coords;

      console.log('Your current position is:');
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);

      localStorage.setItem("lat", crd.latitude);
      localStorage.setItem("lng", crd.longitude);
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  addNewDeliveryPoint() {
    console.log('add new point');
    this.items.push({
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
      field6: "",
      field7: "",
      field8: "",
      field9: "",
      field10: "",
      field11: "",
    });

    this.orderitemnum++;
  }

  deleteDeliveryPoint(index) {
    this.items.splice(index, 1);

    this.orderitemnum--;
  }

  submitOrder() {

    if (this.totalPriceToPay == 0) {
      alert("Please place at least one order");
    } else {
      // this.orderitemnum = 1;

      //var totalPrice: number = 0;




      // for(var i=0;i<this.orderitemnum;i++){
      //   totalPrice = totalPrice + this.items[i].field11;
      // }

      for (var i = 0; i < this.orderitemnum; i++) {


        var dateRequiredTime = new Date();


        var formData: any = new FormData();

        formData.append("orderSenderName", this.sendName);
        formData.append("orderSenderTel", this.sendPhone);
        formData.append("orderSendAreaId", this.sendPostcode);
        formData.append("orderSendDoor", "");
        formData.append("orderSendAddr", this.sendAddress);
        formData.append("orderAnalysisSendAddr", this.sendAddress);
        formData.append("orderSendLng", localStorage.getItem("sendLng"));
        formData.append("orderSendLat", localStorage.getItem("sendLat"));

        formData.append("orderReceiverName", this.items[i].field3);
        formData.append("orderReceiverTel", this.items[i].field4);
        formData.append("orderReceiveAreaId", this.items[i].field2);
        formData.append("orderReceiveDoor", "");
        formData.append("orderReceiveAddr", this.items[i].field1);
        formData.append("orderAnalysisReceiveAddr", this.items[i].field1);
        formData.append("orderReceiveLng", localStorage.getItem("receiveLng"));
        formData.append("orderReceiveLat", localStorage.getItem("receiveLat"));


        formData.append("orderGoodsWeight", this.items[i].field6);
        formData.append("orderGoodsType", this.items[i].field5);
        formData.append("orderGoodsDistance", 20);
        formData.append("orderRequiredTime", dateRequiredTime.valueOf());
        formData.append("orderTotalPrice", this.totalPriceToPay);
        formData.append("orderTransType", this.orderTransType);
        formData.append("orderRemark", this.sendRemark);
        formData.append("totalPrice", this.totalPriceToPay);

        if (this.orderTransType == 4) {
          formData.append("orderQuantity", this.items[i].field8);
        }
        if (this.orderTransType == 5) {
          formData.append("orderLength", this.items[i].field6);
          formData.append("orderWidth", this.items[i].field7);
          formData.append("orderHeight", this.items[i].field8);
        }

        formData.append("uuid", localStorage.getItem('uuid'));
        formData.append("zdtype", "web");
        formData.append("token", localStorage.getItem('token'));


        formData.append("payType", "ACCOUNT");
        formData.append("payPassword", "740725");
        // formData.append("payPassword", localStorage.getItem("payPassword"));





        console.log(formData);





//        this._http
//          .post(
//            `http://uml.arriival.com/qmcs-u-api/order/generatingOrderNew`,
//            formData
//          )
//          .pipe(
//            catchError((err: HttpErrorResponse) => {
//              return throwError("Something bad happened; please try again later.");
//            }) // then handle the error
//          )
//          .subscribe((respObj) => {
//            console.log(respObj);
//            if (respObj["code"] == 200) {
//              alert("SUCCESS");
//              localStorage.setItem("bulkUploadJson", "");
//              $("#domesticSubmitOrderModal").show();
//              // localStorage.setItem('uuid', respObj["data"]["user"]["userId"]);
//              // localStorage.setItem('token', respObj["data"]["token"]);
//              // localStorage.setItem('username', respObj["data"]["user"]["userLoginName"]);
//              // localStorage.setItem('usertel', respObj["data"]["user"]["userTelphone"]);
//              // this.router.navigate(['/main/']);
//
//
//              //$location.url('/foo2?name=bunny2&age=12#myhash2');
//              //alert(respObj["data"]["user"]["userId"]);
//              // proceed to register
//            } else {
//              alert(respObj["enMsg"]);
//            }
//          });

      }
    }
  }



  checkOrderPrice(num: number) {

    console.log("num=" + num);
    var boolSend: Boolean = false;
    if (this.orderTransType == 0) {
      if (this.items[num].field1 != "" && this.items[num].field2 != "" && this.items[num].field3 != "" && this.items[num].field4 != "" && this.items[num].field5 != "" && this.items[num].field6 != "") {
        boolSend = true;
        console.log("boolsend si true");
      }
      else {
        console.log("boolsend si false");
        this.items[num].field7 = "";
      }
    }
    if (this.orderTransType == 4) {
      if (this.items[num].field1 != "" && this.items[num].field2 != "" && this.items[num].field3 != "" && this.items[num].field4 != "" && this.items[num].field5 != "" && this.items[num].field6 != "" && this.items[num].field8 != "") {
        boolSend = true;
        console.log("boolsend 4 si true");
      }
      else {
        this.items[num].field7 = "";
        console.log("boolsend 4 si false");
      }
    }
    if (this.orderTransType == 5) {
      if (this.items[num].field1 != "" && this.items[num].field2 != "" && this.items[num].field3 != "" && this.items[num].field4 != "" && this.items[num].field6 != "" && this.items[num].field7 != "" && this.items[num].field8 != "" && this.items[num].field10 != "") {
        boolSend = true;
      }
      else {
        this.items[num].field11 = "";
      }
    }


    if (boolSend == true) {
      // let headers = new HttpHeaders({
      //   // uuid: "1a0018970a14615f0d8",
      //   'Content-Type': "application/x-www-form-urlencoded",
      //   'uuid': "28772",
      //   'zdtype': "web",
      // });


      let httpOptions = {
        headers: new HttpHeaders({
          //'Content-Type': 'application/x-www-form-urlencoded'
          //'uuid': "28772"
          //'zdtype': "web"
        })
      };



      console.log(this.items);

      var formData: any = new FormData();
      formData.append("weight", this.items[num].field6);
      formData.append("distance", 20);
      formData.append("orderTransType", this.orderTransType);
      //formData.append("type", this.items[num].field5);
      formData.append("type", this.items[num].field5);
      formData.append("lng", localStorage.getItem("lng"));
      formData.append("lat", localStorage.getItem("lat"));
      formData.append("requiredTime", 0);
      formData.append("uuid", localStorage.getItem('uuid'));
      formData.append("zdtype", "web");
      formData.append("token", localStorage.getItem('token'));
      formData.append("sPostCode", this.sendPostcode);
      formData.append("rPostCode", this.items[num].field2);


      if (this.orderTransType == 5) {
        formData.append("l", this.items[num].field6);
        formData.append("w", this.items[num].field7);
        formData.append("h", this.items[num].field8);
      }



      if (this.orderTransType == 4) {
        formData.append("quantity", this.items[num].field8);
      }




      console.log(formData);




//      this._http
//        .post(
//          "http://uml.arriival.com/qmcs-u-api/order/orderNewFee ",
//          formData,
//          httpOptions
//        )
//        .pipe(
//          catchError((err: HttpErrorResponse) => {
//            return throwError(
//              "Something bad happened; please try again later."
//            );
//          }) // then handle the error
//        )
//        .subscribe((respObj) => {
//          console.log(respObj);
//          this.totalPriceToPay = 0;
//
//          if (respObj["code"] == 200) {
//            console.log(respObj["data"].totalPrice);
//            this.items[num].field7 = respObj["data"].totalPrice;
//
//            // set the cart detail
//            this.cartDetails = [];
//
//            this.items.forEach(element => {
//              console.log(element);
//              if (element['field7'] != null && element['field7'] != 0 && element['field7'] != "") {
//                console.log("push to cart details");
//                this.cartDetails.push(element['field7']);
//              }
//            });
//          }
//          else {
//            if (respObj["code"] == 401) {
//              alert(respObj["enMsg"]);
//              localStorage.removeItem('uuid');
//              localStorage.removeItem('token');
//              localStorage.removeItem('username');
//              localStorage.removeItem('usertel');
//              this.router.navigate(['/login']);
//            }
//            else {
//              alert(respObj["enMsg"]);
//            }
//          }
//
//          this.items.forEach(element => {
//            this.totalPriceToPay += element['field7'];
//          });
//        });
    }
  }

  changeAddress(e) {
    this.chosenAddress = e;
  }

  chooseAddress() {
    if (this.setAddressAction == "pick_up") {
      this.sendAddress = this.chosenAddress['addressAddress'];
      this.sendPostcode = this.chosenAddress['addressAreaCode'];
      this.sendPhone = this.chosenAddress['addressPhone'];
      localStorage.setItem("sendLng", this.chosenAddress["addressLng"]);
      localStorage.setItem("sendLat", this.chosenAddress["addressLat"]);
    } else if (this.setAddressAction == "delivery") {
      this.items[this.recordDeliveryPointIndex].field1 = this.chosenAddress['addressAddress'];
      this.items[this.recordDeliveryPointIndex].field2 = this.chosenAddress['addressAreaCode'];
      this.items[this.recordDeliveryPointIndex].field4 = this.chosenAddress['addressPhone'];
      localStorage.setItem("receiveLng", this.chosenAddress["addressLng"]);
      localStorage.setItem("receiveLat", this.chosenAddress["addressLat"]);
    }
  }

  addNewAddress() {
    var formData: any = new FormData();

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));

    formData.append("addressName", this.address_name);
    formData.append("addressPhone", this.address_mobile);
    formData.append("addressAddress", this.displayAddress);
    // formData.append("addressAddress", this.address_address);
    // formData.append("addressLng", localStorage.getItem("lng"));
    // formData.append("addressLat", localStorage.getItem("lat"));
    formData.append("addressLng", this.longitude);
    formData.append("addressLat", this.latitude);
    formData.append("addressAreaCode", this.address_area_code);
    formData.append("addressAddressdoor", this.address_door);

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/address/addAddress`,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          this.getAllAddress();
//          // localStorage.setItem('uuid', respObj["data"]["user"]["userId"]);
//          // localStorage.setItem('token', respObj["data"]["token"]);
//          // localStorage.setItem('username', respObj["data"]["user"]["userLoginName"]);
//          // localStorage.setItem('usertel', respObj["data"]["user"]["userTelphone"]);
//          // this.router.navigate(['/main/']);
//
//
//          //$location.url('/foo2?name=bunny2&age=12#myhash2');
//          //alert(respObj["data"]["user"]["userId"]);
//          // proceed to register
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  submitBulkOrder() {
    localStorage.setItem("bulkUploadJson", "");

    if (this.activateCourier == true)
      localStorage.setItem("saveQueryParamFromDomestic", "courier");
    if (this.activateCargo == true)
      localStorage.setItem("saveQueryParamFromDomestic", "cargo");
    if (this.activateAirFreight == true)
      localStorage.setItem("saveQueryParamFromDomestic", "air-freight");

    this.router.navigate(['/main/delivery/domestic/mode/csv/']);
  }
}
