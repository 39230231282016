import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ap-transaction',
  templateUrl: './ap-transaction.component.html',
  styleUrls: ['./ap-transaction.component.css']
})
export class ApTransactionComponent implements OnInit {

  transactionData:any[] = [{
    "dateRange": "01/02/2020 ~ 29/02/2020",
    "currency": "RM",
    "total": "500.00",
    "transactions": [
      {
        "date_time": "2020-02-26 09:31:33",
        "order_num": "CR-200009",
        "user": "Ap Sdn Bhd",
        "user_type": "company",
        "type": "Withdraw to Cash",
        "spend_amount": "RM 30.00",
        "before_point": "52.73",
        "earn_point": "0.60",
        "balance_point": "53.33"
      },
      {
        "date_time": "2020-02-26 09:31:33",
        "order_num": "CR-200009",
        "user": "Ap Sdn Bhd",
        "user_type": "personal",
        "type": "Withdraw to Cash",
        "spend_amount": "RM 30.00",
        "before_point": "52.73",
        "earn_point": "-60.00",
        "balance_point": "53.33"
      }
    ]
  }];
  public page:number = 1;
  public pageSize:number = 20;
  public showDate:Boolean = false;
  public showType:Boolean = false;
  public showText:Boolean = true;
  public sltFilter:String = "";

  constructor() { }

  ngOnInit(): void {
  }

  filterOnChange():void {

  }

}
