<div class="content pt-3">

  <div class="row">
    <div class="col-md-6">
      <h2>Arriival Point's Transaction Record</h2>
      <span class="font-weight-bold">Total Earn Arriival Point: 1020.80</span><br />
      <small class="font-italic">Current earn transaction rate: 0.5%</small>
    </div>
    <div class="col-md-6 text-right pt-2">
      <button type="button" class="btn btn-primary btn-sm" style="padding: 5px 25px;border-radius: 20px;" name="button">Convert to Credit</button>&nbsp;&nbsp;
      <button type="button" class="btn btn-primary btn-sm" style="padding: 5px 25px;border-radius: 20px;" name="button">Withdraw to Cash</button>
      <h4 class="mt-4 font-weight-bold">Available Point: 3.33</h4>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <div class="d-flex flex-row align-items-start">

        <div class="d-flex flex-row mr-2" style="max-width: 55%;">
          <div class="">
            <select class="form-control" (change)="filterOnChange()" [(ngModel)]="sltFilter" name="">
              <option value="" hidden selected>Seach by:-</option>
              <option value="date">By Date</option>
              <option value="type">Transmission Type</option>
              <option value="ordernum">Order Number</option>
              <option value="user">User Invited Name</option>
            </select>
          </div>
          <!-- Filter by date -->
          <div class="pl-3 d-flex flex-row" *ngIf="sltFilter == 'date'">
            <div class="d-flex flex-row mr-1">
              <label class="mr-1" style="white-space: nowrap;line-height: 2.5" for="">Date Start</label>
              <input type="date" name="" class="form-control" value="" style="width: 166px;" />
            </div>
            <div class="d-flex flex-row ml-1">
              <label class="mr-1" style="white-space: nowrap;line-height: 2.5;" for="">Date End</label>
              <input type="date" name="" class="form-control" value="" style="width: 166px;" />
            </div>
          </div>
          <!-- Filter by type -->
          <div class="pl-3" *ngIf="sltFilter == 'type'">
            <select class="form-control" name="">
              <option value="express" selected>Express Delivery</option>
              <option value="multistop">MultiStop Delivery</option>
              <option value="courier">Courier Service</option>
              <option value="cargo">Cargo Service</option>
              <option value="airfreight">Air Freight Service</option>
              <option value="international">International Delivery</option>
            </select>
          </div>
          <!-- Default filter -->
          <div class="pl-3" *ngIf="sltFilter != 'date' && sltFilter != 'type'">
            <input type="text" class="form-control" name="" value="" />
          </div>
        </div>

        <div style="line-height: 2;">
          <button type="button" class="btn btn-primary btn-sm" style="padding: 5px 25px;border-radius: 20px;" name="button">Search</button>
        </div>

        <div class="d-flex flex-row flex-fill ml-4 justify-content-end">
          <span style="line-height: 2.5;">show</span>
          <select class="form-control mx-1" name="" style="width: 5vw;">
            <option value="">10</option>
          </select>
          <span style="line-height: 2.5;">entries</span>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row bg-invoice mt-3 py-3 px-2">
    <span class="t-titl flex-fill" style="font-weight: 600;font-size: 13px;max-width: 17%;">Transaction Date/Time</span>
    <span class="t-titl flex-fill" style="font-weight: 600;font-size: 13px;max-width: 13%;">Order Number</span>
    <span class="t-titl flex-fill" style="font-weight: 600;font-size: 13px;max-width: 15%;">User Invited</span>
    <span class="t-titl flex-fill" style="font-weight: 600;font-size: 13px;max-width: 12.5%;">Transmission Type</span>
    <span class="t-titl flex-fill" style="font-weight: 600;font-size: 13px;max-width: 12.5%;">Spend Amount</span>
    <span class="t-titl flex-fill" style="font-weight: 600;font-size: 13px;max-width: 10%;">Before Point</span>
    <span class="t-titl flex-fill" style="font-weight: 600;font-size: 13px;max-width: 10%;">Earn Point</span>
    <span class="t-titl flex-fill" style="font-weight: 600;font-size: 13px;max-width: 10%;">Balance Point</span>
  </div>

  <!-- <div class="row mt-2 py-3 px-2 bg-invoice">
    <div class="col-md-2">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Transaction Date/Time</span>
    </div>
    <div class="col-md-2">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Order Number</span>
    </div>
    <div class="col-md-1">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">User Invited</span>
    </div>
    <div class="col-md-2 text-right">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Transmission Type</span>
    </div>
    <div class="col-md-2 text-right">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Spend Amount</span>
    </div>
    <div class="col-md-1 text-right">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Before Point</span>
    </div>
    <div class="col-md-1 text-right">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Earn Point</span>
    </div>
    <div class="col-md-1 text-right">
      <span class="t-titl" style="font-weight: 600;font-size: 13px;">Balance Point</span>
    </div>
  </div> -->
  <div class="row px-3">
    <div class="col-md-12">
      <div style="font-size: 13px;" [ngClass]="{'font-weight-bold': transaction.earn_point < 0}" *ngFor="let transaction of transactionData[0].transactions | slice: (page-1) * pageSize : page * pageSize; let i = index" class="row py-2 px-2 invoice-border-bottom striped">
        <div class="flex-fill" style="max-width: 17%;">
          <span>{{ transaction.date_time }}</span>
        </div>
        <div class="flex-fill" style="max-width: 13%;">
          <span>{{ transaction.order_num }}</span>
        </div>
        <div class="flex-fill" style="max-width: 15%;">
          <i style="color: #a1a1a1;" [ngClass]="{'fa-briefcase': transaction.user_type == 'company', 'fa-user': transaction.user_type == 'personal'}" class="fas pr-2"></i>
          <span>{{ transaction.user }}</span>
        </div>
        <div class="flex-fill" style="max-width: 12.5%;">
          <span>{{ transaction.type }}</span>
        </div>
        <div class="flex-fill" style="max-width: 12.5%;">
          <span>{{ transaction.spend_amount }}</span>
        </div>
        <div class="flex-fill" style="max-width: 10%;">
          <span>{{ transaction.before_point }}</span>
        </div>
        <div class="flex-fill" [ngClass]="{'text-danger': transaction.earn_point < 0, 'text-success': transaction.earn_point > 0}" style="max-width: 10%;">
          <span>{{ transaction.earn_point }}</span>
        </div>
        <div class="flex-fill" style="max-width: 10%;">
          <span>{{ transaction.balance_point }}</span>
        </div>
        <!-- <div class="col-md-2">
          <span>{{ invoice.dateTime }}</span>
        </div>
        <div class="col-md-2">
          <span>{{ invoice.invoiceNo }}</span>
        </div>
        <div class="col-md-4">
          <span>{{ invoice.description }}</span>
        </div>
        <div class="col-md-2 text-right">
          <span>{{ invoiceData[0].currency }} {{ invoice.amount }}</span>
        </div>
        <div class="col-md-2 text-right" #noprint>
          <button type="button" class="btn btn-primary btn-sm" (click)="generatePDF()" name="button">Download Invoice</button>
        </div> -->
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-6 text-left">
      <span style="font-weight: 500;">Showing 1 to 6 of 6 entries</span>
    </div>
    <div class="col-md-6 d-flex flex-row justify-content-end">
      <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="transactionData[0].transactions.length"></ngb-pagination>
    </div>
  </div>

  <!-- Print Out Section -->
  <!-- <div id="contentToConvert" style="position: absolute;width: 100vw;left: -5000px;top: 0;">
    <div class="logo-overlay"></div>
    <div class="row">
      <div class="col-md-5">
        <img src="/assets/img/logo2.png" width="120" alt="" /><br />
        <span class="font-weight-bold pl-2">Invoice to</span><br />
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Company Name</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="text-uppercase invoice-to-info">sun ceramic sdn bhd</span>
        </div>
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Contact Name</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="invoice-to-info">Contact Person Name</span>
        </div>
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Register ID HP</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="invoice-to-info">60123380984</span>
        </div>
        <div class="d-flex pl-2">
          <span class="invoice-to-info invoice-to-label">Address</span>
          <span class="invoice-to-info double-column">:</span>
          <span class="invoice-to-info"></span>
        </div>
      </div>
      <div class="col-md-7 text-right">
        <div>
          <span class="font-weight-bold company-name-size">ARRIIVAL TECHNOLOGIES SDN BHD</span><span> (1294634-V)</span>
        </div>
        <div>
          <span>No.9, Jalan YTN 3, Industri Desa Aman, 52200 Kuala Lumpur.</span>
        </div>
        <div>
          <span class="mr-3">Tel: +603-2107-0187</span>
          <span class="mr-3">Email: support@arriival.com</span>
          <span>www.arriival.com</span>
        </div>
        <div class="mt-4">
          <span class="font-weight-bold text-uppercase invoice-label">invoice</span><br />
          <span class="font-weight-bold text-uppercase font-size-20">inv20201028-007</span><br />
          <span class="font-size-20">Date: 28/10/2020</span>
        </div>
      </div>
    </div>
  <div class="row mt-5 py-3 px-2 bg-invoice">
    <div class="col-md-1">
      <span class="t-title">Item</span>
    </div>
    <div class="col-md-6">
      <span class="t-title">Description</span>
    </div>
    <div class="col-md-1 text-center">
      <span class="t-title">Qty</span>
    </div>
    <div class="col-md-2 text-right">
      <span class="t-title">Unt Price (RM)</span>
    </div>
    <div class="col-md-2 text-right">
      <span class="t-title">Amount (RM)</span>
    </div>
  </div>
  <div class="row body-min-height">
    <div class="col-md-12">
      <div class="row py-2 px-2 invoice-border-bottom">
        <div class="col-md-1">
          <span>1</span>
        </div>
        <div class="col-md-6">
          <span>ARRIIVAL TOP UP CREDIT</span>
        </div>
        <div class="col-md-1 text-center">
          <span>1</span>
        </div>
        <div class="col-md-2 text-right">
          <span>500.00</span>
        </div>
        <div class="col-md-2 text-right">
          <span>500.00</span>
        </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="row mt-1">
    <div class="col-md-2 offset-md-9 text-left">
      <div class="d-flex">
        <span class="text-left price-label">Sub total</span>
        <span class="double-column">:</span>
        <span class="text-uppercase">RM500.00</span>
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-md-2 offset-md-9 text-left">
      <div class="d-flex">
        <span class="text-left price-label">Tax</span>
        <span class="double-column">:</span>
        <span class="text-uppercase">&#8208;&#8208;</span>
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-md-2 offset-md-9 text-left">
      <div class="d-flex price-large">
        <span class="text-left price-label">Total</span>
        <span class="double-column">:</span>
        <span class="text-uppercase">RM500.00</span>
      </div>
    </div>
  </div>

    <div class="row">
      <div class="col-md-6">
        <span>Notes :</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span>1. All prices are in Ringgit Malaysia and not refundable.</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span>2. This is a computer generated invoice and do not require any stamp or signature.</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <span class="font-weight-bold font-size-20">Thank you for your support.</span>
      </div>
    </div>
  </div> -->

</div>
