<div class="content col-md-9 col-lg-12 pt-3">
  <h2>Import / Export CSV</h2>
  <hr />

  <form class="" action="index.html" method="post">
    <p>
      <span class="mr-3 step-span">Step 1</span>
      <span class="text-muted">Upload your CSV file</span>
    </p>
    <hr />
    <div class="form-group row">
      <div class="col-md-3">
        <input type="file" class="form-control" id="fileInput" name="filename" [(ngModel)]="filename" (change)='onFileChange($event)' />
      </div>
    </div>
    <div class="form-group row">
      <button type="button" class="btn btn-primary mr-1" name="button">Download Bulk Template</button>
      <button type="button" class="btn btn-primary ml-1" name="button" (click)='convertFile()'>Import Order</button>
    </div>
    <div *ngIf="willDownload">
      <a id="download"> Download JSON File </a>
    </div>
  </form>
</div>
