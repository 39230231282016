import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest
} from "@angular/common/http";
import { catchError, retry, first } from "rxjs/operators";
import { throwError } from "rxjs";
import { MapsAPILoader } from '@agm/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsComponent } from '../google-maps/google-maps.component';

import $ from 'jquery'
declare var $: $

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit {

  @ViewChild('name', { static: false }) nameInput: ElementRef;
  @ViewChild('address', { static: false }) addressInput: ElementRef;
  @ViewChild('mobile', { static: false }) mobileInput: ElementRef;
  @ViewChild('areaCode', { static: false }) areaCodeInput: ElementRef;
  @ViewChild('addressDoor', { static: false }) addressDoorInput: ElementRef;

  coordinates: Coordinates;

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  public displayAddress;
  public editDisplayAddress;

  public record_address_id;
  public record_address_lat;
  public record_address_lng;

  public address_name;
  public address_address;
  public address_mobile;
  public address_area_code;
  public address_door;

  public my_address: any[];

  cardDataset: any[] = [{
    "name": "Alice",
    "address": "SS2, Petaling Jaya, Selangor, Malaysia",
    "mobile": "012-356 8978",
    "email": "alice@email.com"
  }, {
    "name": "Amy",
    "address": "SS6, Petaling Jaya, Selangor, Malaysia",
    "mobile": "013-927 4658",
    "email": "amy@email.com"
  }, {
    "name": "John Doe",
    "address": "SS4, Petaling Jaya, Selangor, Malaysia",
    "mobile": "016-784 8948",
    "email": "johndoe@email.com"
  }];

  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('editsearch')
  public editSearchElementRef: ElementRef;


  constructor(
    public _http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    //load Places Autocomplete
    // this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder;

    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       console.log("run this");

    //       //set latitude, longitude and zoom
    //       this.latitude = place.geometry.location.lat();
    //       this.longitude = place.geometry.location.lng();
    //       this.zoom = 15;
    //     });
    //   });
    // });

    // this.setCurrentLocation();
    // this.getLocation();
    this.getAllAddress();
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude, '');
      });
    }
  }


  markerDragEnd($event: google.maps.MouseEvent, $action: any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude, $action);
  }

  getAddress(latitude, longitude, action) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;

          if (action == 'edit') {
            this.editDisplayAddress = results[0].formatted_address;
          } else {
            this.displayAddress = results[0].formatted_address;
          }

          var splitted = this.displayAddress.split(",");
          var getPostalCodePortion = splitted[splitted.length - 3];

          var postalCodePortionSplitted = getPostalCodePortion.trim().split(" ");
          if (postalCodePortionSplitted.length == 2) {
            this.address_area_code = postalCodePortionSplitted[0];
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  getLocation() {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    function success(pos) {
      var crd = pos.coords;

      localStorage.setItem("lat", crd.latitude);
      localStorage.setItem("lng", crd.longitude);
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  showDeleteModal(event: any): void {
    this.record_address_id = event.target.dataset.id;
  }

  showEditModal(event: any): void {
    let name: String;
    let address: String;
    let mobile: any;
    let areaCode: String;
    let addressDoor: String;

    name = event.target.dataset.name;
    address = event.target.dataset.address;
    mobile = event.target.dataset.mobile;
    areaCode = event.target.dataset.areacode;
    addressDoor = event.target.dataset.addressdoor;

    // this.nameInput.nativeElement.value = name;
    // this.addressInput.nativeElement.value = address;
    // this.mobileInput.nativeElement.value = mobile;
    // this.areaCodeInput.nativeElement.value = areaCode;
    // this.addressDoorInput.nativeElement.value = addressDoor;

    this.address_name = name;
    // this.address_address = address;
    this.editDisplayAddress = address;
    this.address_mobile = mobile;
    this.address_area_code = areaCode;
    this.address_door = addressDoor

    this.record_address_id = event.target.dataset.id;
    this.record_address_lat = event.target.dataset.lat;
    this.record_address_lng = event.target.dataset.lng;

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.editSearchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = this.record_address_lat;
          this.longitude = this.record_address_lng;
          this.zoom = 15;
        });
      });

      $(".pac-container").css("z-index", 9999);
    });
  }

  clearModal(): void {
    this.nameInput.nativeElement.value = "";
    // this.addressInput.nativeElement.value = "";
    this.mobileInput.nativeElement.value = "";
    this.areaCodeInput.nativeElement.value = "";
    this.addressDoorInput.nativeElement.value = "";

    // const modalRef = this.modalService.open(GoogleMapsComponent,
    //   {
    //     scrollable: true,
    //     // windowClass: 'myCustomModalClass',
    //     // keyboard: false,
    //     // backdrop: 'static'
    //   });
    // let data = {
    //   prop1: 'Some Data',
    //   prop2: 'From Parent Component',
    //   prop3: 'This Can be anything'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   this.coordinates = result;
    // }, (reason) => {
    // });

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 15;
        });
      });

      $(".pac-container").css("z-index", 9999);
    });
  }

  getAllAddress() {
    var formData: any = new FormData();

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/address/selectAddress`,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          this.my_address = respObj['data'];
//          // localStorage.setItem('uuid', respObj["data"]["user"]["userId"]);
//          // localStorage.setItem('token', respObj["data"]["token"]);
//          // localStorage.setItem('username', respObj["data"]["user"]["userLoginName"]);
//          // localStorage.setItem('usertel', respObj["data"]["user"]["userTelphone"]);
//          // this.router.navigate(['/main/']);
//
//
//          //$location.url('/foo2?name=bunny2&age=12#myhash2');
//          //alert(respObj["data"]["user"]["userId"]);
//          // proceed to register
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  addNewAddress() {
    var formData: any = new FormData();

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));

    formData.append("addressName", this.address_name);
    formData.append("addressPhone", this.address_mobile);
    formData.append("addressAddress", this.displayAddress);
    // formData.append("addressAddress", this.address_address);
    // formData.append("addressLng", localStorage.getItem("lng"));
    // formData.append("addressLat", localStorage.getItem("lat"));
    formData.append("addressLng", this.longitude);
    formData.append("addressLat", this.latitude);
    formData.append("addressAreaCode", this.address_area_code);
    formData.append("addressAddressdoor", this.address_door);

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/address/addAddress`,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          this.getAllAddress();
//          // localStorage.setItem('uuid', respObj["data"]["user"]["userId"]);
//          // localStorage.setItem('token', respObj["data"]["token"]);
//          // localStorage.setItem('username', respObj["data"]["user"]["userLoginName"]);
//          // localStorage.setItem('usertel', respObj["data"]["user"]["userTelphone"]);
//          // this.router.navigate(['/main/']);
//
//
//          //$location.url('/foo2?name=bunny2&age=12#myhash2');
//          //alert(respObj["data"]["user"]["userId"]);
//          // proceed to register
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  updateAddress() {
    var formData: any = new FormData();

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));

    formData.append("addressId", this.record_address_id);
    formData.append("addressName", this.address_name);
    formData.append("addressPhone", this.address_mobile);
    formData.append("addressAddress", this.address_address);
    formData.append("addressLng", this.record_address_lng);
    formData.append("addressLat", this.record_address_lat);
    formData.append("addressAreaCode", this.address_area_code);
    formData.append("addressAddressdoor", this.address_door);

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/address/updateAddress`,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          this.getAllAddress();
//          // localStorage.setItem('uuid', respObj["data"]["user"]["userId"]);
//          // localStorage.setItem('token', respObj["data"]["token"]);
//          // localStorage.setItem('username', respObj["data"]["user"]["userLoginName"]);
//          // localStorage.setItem('usertel', respObj["data"]["user"]["userTelphone"]);
//          // this.router.navigate(['/main/']);
//
//
//          //$location.url('/foo2?name=bunny2&age=12#myhash2');
//          //alert(respObj["data"]["user"]["userId"]);
//          // proceed to register
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

  deleteAddress() {
    var formData: any = new FormData();

    formData.append("uuid", localStorage.getItem('uuid'));
    formData.append("zdtype", "web");
    formData.append("token", localStorage.getItem('token'));

    formData.append("ids", this.record_address_id);

//    this._http
//      .post(
//        `http://uml.arriival.com/qmcs-u-api/address/deleteAddressIsDels`,
//        formData
//      )
//      .pipe(
//        catchError((err: HttpErrorResponse) => {
//          return throwError("Something bad happened; please try again later.");
//        }) // then handle the error
//      )
//      .subscribe((respObj) => {
//        if (respObj["code"] == 200) {
//          this.getAllAddress();
//          // localStorage.setItem('uuid', respObj["data"]["user"]["userId"]);
//          // localStorage.setItem('token', respObj["data"]["token"]);
//          // localStorage.setItem('username', respObj["data"]["user"]["userLoginName"]);
//          // localStorage.setItem('usertel', respObj["data"]["user"]["userTelphone"]);
//          // this.router.navigate(['/main/']);
//
//
//          //$location.url('/foo2?name=bunny2&age=12#myhash2');
//          //alert(respObj["data"]["user"]["userId"]);
//          // proceed to register
//        } else {
//          alert(respObj["enMsg"]);
//        }
//      });
  }

}
