import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { SingleComponent } from './services/single/single.component';
import { DomesticComponent } from './services/domestic/domestic.component';
import { InternationalComponent } from './services/international/international.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './index/login/login.component';
import { RegisterComponent } from './index/register/register.component';
import { MainComponent } from './main/main.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { CSVComponent } from './services/csv/csv.component';
import { OrderTrackComponent } from './order/order-track/order-track.component';
import { CheckoutComponent } from './services/checkout/checkout.component';
import { RedeemComponent } from './redeem/redeem.component';
import { StoreComponent } from './store/store.component';
import { ReferralComponent } from './referral/referral.component';
import { DraftComponent } from './draft/draft.component';
import { FavouriteComponent } from './favourite/favourite.component';
import { ConstructionComponent } from './construction/construction.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { BuyComponent } from './credit/buy/buy.component';
import { QuoteComponent } from './quote/quote.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { StatementComponent } from './statement/statement.component';
import { RewardComponent } from './reward/reward.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsComponent } from './google-maps/google-maps.component';

import { AgmCoreModule } from '@agm/core';
import { ApTransactionComponent } from './referral/ap-transaction/ap-transaction.component';
import { AboutComponent } from './about/about.component';






@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    SingleComponent,
    DomesticComponent,
    InternationalComponent,
    DashboardComponent,
    LoginComponent,
    RegisterComponent,
    MainComponent,
    UserProfileComponent,
    CSVComponent,
    OrderTrackComponent,
    CheckoutComponent,
    RedeemComponent,
    StoreComponent,
    ReferralComponent,
    DraftComponent,
    FavouriteComponent,
    ConstructionComponent,
    OrderListComponent,
    BuyComponent,
    QuoteComponent,
    InvoiceComponent,
    StatementComponent,
    RewardComponent,
    GoogleMapsComponent,
    ApTransactionComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAAmRoGVrIyrvsktxSZomI4XDSZE7_AOaU',
      libraries: ['places']
    }),
    NgbModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
