import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component'

import { MainComponent } from './main/main.component'

// Services
import { SingleComponent } from './services/single/single.component'
import { DomesticComponent } from './services/domestic/domestic.component'
import { InternationalComponent } from './services/international/international.component'
import { CSVComponent } from './services/csv/csv.component'
import { CheckoutComponent } from './services/checkout/checkout.component'

// Login & Register
import { LoginComponent } from './index/login/login.component'
import { RegisterComponent } from './index/register/register.component'

// User Profile
import { UserProfileComponent } from './user-profile/user-profile.component'

// Order
import { OrderTrackComponent } from './order/order-track/order-track.component'
import { OrderListComponent } from "./order/order-list/order-list.component";

// Redeem
import { RedeemComponent } from './redeem/redeem.component'

// Arriival store
import { StoreComponent } from './store/store.component'

// Referral
import { ReferralComponent } from "./referral/referral.component";
import { RewardComponent } from "./reward/reward.component";
import { ApTransactionComponent } from "./referral/ap-transaction/ap-transaction.component";

// Favourite
import { FavouriteComponent } from "./favourite/favourite.component";

// Draft
import { DraftComponent } from "./draft/draft.component";

// Construction
import { ConstructionComponent } from "./construction/construction.component";

// Credit
import { BuyComponent } from "./credit/buy/buy.component";

// Quote
import { QuoteComponent } from "./quote/quote.component";

// Invoice
import { InvoiceComponent } from "./invoice/invoice.component";

// Statement
import { StatementComponent } from "./statement/statement.component";

// About
import { AboutComponent } from "./about/about.component";


const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    children: [{
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full'
    }, {
      path: 'dashboard',
      component: DashboardComponent
    }, {
      path: 'quote',
      component: QuoteComponent
    }, {
      path: 'delivery/single',
      component: SingleComponent
    }, {
      path: 'delivery/domestic',
      component: DomesticComponent
    }, {
      path: 'delivery/international',
      component: InternationalComponent
    }, {
      path: 'user/profile',
      component: UserProfileComponent
    }, {
      path: 'delivery/:type/mode/csv',
      component: CSVComponent
    }, {
      path: 'order/track',
      component: OrderTrackComponent
    }, {
      path: 'order/checkout',
      component: CheckoutComponent
    }, {
      path: 'order/list',
      component: OrderListComponent
    }, {
      path: 'redeem',
      component: RedeemComponent
    }, {
      path: 'store',
      component: StoreComponent
    }, {
      path: 'referral',
      component: ReferralComponent
    }, {
      path: 'transaction/ap',
      component: ApTransactionComponent
    }, {
      path: 'reward',
      component: RewardComponent
    }, {
      path: 'favourite',
      component: FavouriteComponent
    }, {
      path: 'draft',
      component: DraftComponent
    }, {
      path: 'construction',
      component: ConstructionComponent
    }, {
      path: 'credit/buy',
      component: BuyComponent
    }, {
      path: 'invoice',
      component: InvoiceComponent
    }, {
      path: 'statement',
      component: StatementComponent
    }, {
      path: 'about',
      component: AboutComponent
    }]
  }, {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  }, {
    path: 'login',
    component: LoginComponent
  }, {
    path: 'register',
    component: RegisterComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
